@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap-sprockets";
@import "bootstrap";
@import "moykassir";
@import "auth.adaptive";

.app {
  display: flex;
}

.app {
  flex-flow: row wrap;
}

.app {
  align-items: stretch;
}

.app {
  justify-content: center;
}

.main-panel {
  margin: 0 auto;
}

.qpos-login-info {
  background-color: $color-success;
  color: $color-snow;
  margin-left: -15px;
  margin-right: -15px;
  padding: 14px;
  cursor: pointer;
}

.qpos-login-alert {
  background-color: $color-primary;
  color: $color-snow;
  padding: 14px;
}

.qpos-input-error {
  float: left;
  font-size: 0.9em;
  color: $color-danger;
}

.checkbox-lg label {
  line-height: 24px;
}

button.btn-default .disabled .login-disabled {
  cursor: not-allowed;
  pointer-events: none;
  background-color: $color-mercury;
}

.page {
  display: table;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
}

.page-content {
  display: table-cell;
  vertical-align: middle;
}

.panel {
  margin-left: auto;
  margin-right: auto;
}

// New blue design
.page-login-v4 {
  font: 400 14px/20px "Open Sans", Arial, sans-serif;
  color: rgb(141, 153, 166);
  background-color: rgb(235, 240, 242);

  .panel-body {
    width: 100%;
    max-width: 360px;
    padding: 28px 24px;
    background-color: white;
    border-radius: 8px;
    margin: 0 auto;

    @media (min-width: 319px) and (max-width: 374px) {
      max-width: calc(100vw - 20px);
    }

    &-lg {
      width: 720px;
    }
  }

  .recover-link,
  .change-button,
  .register-link,
  .login-link,
  .register-partner-link,
  .offer-link {
    font-weight: 600;
    color: rgb(0, 172, 230);
    text-decoration: none;
    background: none;
    outline: none;
    border: none;

    &:hover,
    &:focus,
    &:active {
      color: rgb(0, 172, 230);
      text-decoration: underline;
    }
  }

  .hidden-link {
    color: #8d99a6;
    cursor: default;
    text-decoration: none;
    &:hover {
      color: #8d99a6;
      cursor: default;
      text-decoration: none;
    }

    &:active {
      color: #8d99a6;
      cursor: default;
      text-decoration: none;
    }

    &:visited {
      color: #8d99a6;
      cursor: default;
      text-decoration: none;
    }
  }

  .radio-primary input[type="radio"] {
    & + label {
      color: rgb(0, 172, 230);
    }

    &:checked + label::before {
      border-color: rgb(0, 172, 230);
    }
  }

  .brand-btn {
    display: inline-block;
    vertical-align: middle;
    padding: 10px 24px;
    text-align: center;
    font: inherit;
    font-weight: 600;
    border: 0;
    border-radius: 24px;
    user-select: none;
    cursor: pointer;
    box-shadow: none;

    &:disabled,
    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    &:focus {
      outline: 0;
    }

    &-primary {
      color: white;
      background-color: rgb(0, 172, 230);
    }

    &.login-button {
      width: 100%;
    }
  }

  .login-form {
    margin-bottom: 25px;
  }

  .login-form .form-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-bottom: 24px;

    &:nth-child(2) {
      margin-bottom: 27px;
    }

    &.has-error {
      input[type="text"],
      input[type="email"],
      input[type="password"] {
        border-color: rgb(255, 0, 0);
      }
    }

    label {
      margin: 0;
      margin-bottom: 5px;
      font: inherit;
      font-size: 12px;
      line-height: 18px;
      cursor: pointer;
      user-select: none;
      width: 100%;
      text-align: left;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"] {
      font: inherit;
      width: 100%;
      max-width: 312px;
      height: 40px;
      padding: 10px 15px;
      background-color: white;
      border: 1px solid #ced4d9;
      border-radius: 4px;

      &:focus {
        outline: 0;
      }
    }

    .password-input-wrapper {
      position: relative;
      width: 100%;
      max-width: 312px;
      height: 40px;

      &.active {
        svg {
          fill: black;
        }
      }

      svg {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        cursor: pointer;
      }

      input {
        padding-right: 30px;
      }
    }

    .brand-checkbox {
      text-align: left;

      input:checked + label::after {
        content: "";
      }

      label {
        position: relative;
        padding-left: 33px;
        font-size: 14px;
        line-height: 20px;
        color: rgb(42, 53, 64);

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 18px;
          height: 18px;
          border: 2px solid #8d99a6;
          border-radius: 2px;
          transform: translateY(-50%);
        }

        &::after {
          position: absolute;
          top: 7px;
          left: 4px;
          width: 10px;
          height: 5px;
          border: 2px solid #8d99a6;
          border-top: 0;
          border-right: 0;
          transform: rotate(-45deg);
        }
      }
    }
  }
}

.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;

  white-space: nowrap;

  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
