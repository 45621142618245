/* Ноутбуки */
@media (min-width: 780px) and (max-width: 1200px) {
  .app {
    flex-flow: row wrap !important;
    justify-content: space-around !important;
    align-items: center !important;
  }

  .max-w-600 {
    margin: 0 auto 20px !important;
  }
}

/* Планшеты */
@media (min-width: 580px) and (max-width: 779px) {
  .app {
    flex-flow: row wrap !important;
  }
  
  .max-w-600 {
    margin: 20px !important;
  }
}

/* Телефоны */
@media (min-width: 319px) and (max-width: 579px) {
  .app {
    flex-flow: column !important;
    padding-top: 10px;
  }

  .max-w-600 {
    margin: 0 auto 20px !important;
  }
}