@import "site";
@import "colors";
@import "fonts";

.font-bold {
  font-weight: 600 !important;
}

.font-bigger {
  font-size: 1.2em;
}

html, body, header, footer {
  font-family: $font-family-primary;
  font-weight: $font-weight-primary;
}

.page {
  min-height: 100%;
}

.panel {
  border-radius: 2px;
}

.btn {
  font-weight: $font-weight-primary;
}

input[type=checkbox] {
  cursor: pointer;
}

.chart-container {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px 10px 10px;
  width: 100%;
  height: 100%;
  background-color: $color-white;
}

body {
  color: $color-gray;
  background-color: $color-white;
}

.pricing-currency {
  font-size: medium;
}

.color-primary {
  color: $color-primary !important;
}

.bg-primary {
  background-color: $color-primary !important;
}

a {
  color: $color-primary-dark;
}

a:hover,
a:focus {
  color: $color-primary;
}

img {
  vertical-align: middle;
}

.img-thumbnail {
  background-color: $color-white;
  border: 1px solid $color-mercury;
}

hr {
  border-top: 1px solid $color-mercury;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $color-base-text;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  color: $color-base-small;
}

mark,
.mark {
  background-color: $color-warning;
}

.text-muted {
  color: $color-base-small;
}

.text-primary {
  color: $color-primary;
}

a.text-primary:hover,
a.text-primary:focus {
  color: $color-primary-dark;
}

.text-success {
  color: $color-white;
}

a.text-success:hover,
a.text-success:focus {
  color: #e6e6e6;
}

.text-info {
  color: $color-white;
}

a.text-info:hover,
a.text-info:focus {
  color: #e6e6e6;
}

.text-warning {
  color: $color-white;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #e6e6e6;
}

.text-danger {
  color: $color-white;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #e6e6e6;
}

.bg-primary {
  color: $color-white;
  background-color: $color-primary;
}

a.bg-primary:hover,
a.bg-primary:focus {
  background-color: $color-primary-dark;
}

.bg-success {
  background-color: $color-success;
}

a.bg-success:hover,
a.bg-success:focus {
  background-color: $color-success;
}

.bg-info {
  background-color: $color-info;
}

a.bg-info:hover,
a.bg-info:focus {
  background-color: $color-info;
}

.bg-warning {
  background-color: $color-warning;
}

a.bg-warning:hover,
a.bg-warning:focus {
  background-color: $color-warning;
}

.bg-danger {
  background-color: $color-danger;
}

a.bg-danger:hover,
a.bg-danger:focus {
  background-color: $color-danger;
}

.page-header {
  border-bottom: 1px solid transparent;
}

abbr[title],
abbr[data-original-title] {
  border-bottom: 1px dotted #e0e0e0;
}

blockquote {
  border-left: 5px solid #e0e0e0;
}

blockquote footer,
blockquote small,
blockquote .small {
  color: #9e9e9e;
}

.blockquote-reverse,
blockquote.pull-right {
  border-right: 5px solid #e0e0e0;
}

code {
  color: #914566;
  background-color: rgba(248, 187, 208, .1);
}

kbd {
  color: $color-white;
  background-color: $color-primary;
}

pre {
  color: inherit;
  background-color: $color-white;
  border: 1px solid #fce4ec;
}

table {
  background-color: transparent;
}

caption {
  color: #616161;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-top: 1px solid #e0e0e0;
}

.table > thead > tr > th {
  border-bottom: 2px solid #e0e0e0;
}

.table > tbody + tbody {
  border-top: 2px solid #e0e0e0;
}

.table .table {
  background-color: $color-white;
}

.table-bordered {
  border: 1px solid #e0e0e0;
}

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #e0e0e0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: rgba(238, 238, 238, .3);
}

.table-hover > tbody > tr:hover {
  background-color: #eee;
}

.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #eee;
}

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #e1e1e1;
}

.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: #1B998B;
}

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #449d48;
}

.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background-color: #00bcd4;
}

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #00a5bb;
}

.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  background-color: #FFA630;
}

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #e68900;
}

.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: #E3170A;
}

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #f32c1e;
}

@media screen and (max-width: 767px) {
  .table-responsive {
    border: 1px solid #e0e0e0;
  }
}

legend {
  color: inherit;
  border-bottom: 1px solid transparent;
}

output {
  color: #757575;
}

.form-control {
  color: #757575;
  background-color: $color-white;
  border: 1px solid #e0e0e0;
}

.form-control:focus {
  border-color: $color-primary;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(233, 30, 99, .6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(233, 30, 99, .6);
}

.form-control.focus,
.form-control:focus {
  border-color: $color-primary;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eee;
}

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: $color-white;
}

.has-success .form-control {
  border-color: $color-white;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.has-success .form-control:focus {
  border-color: #e6e6e6;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px $color-white;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px $color-white;
}

.has-success .input-group-addon {
  color: $color-white;
  background-color: #1B998B;
  border-color: $color-white;
}

.has-success .form-control-feedback {
  color: $color-white;
}

.has-success .form-control {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}

.has-success .form-control:focus {
  border-color: $color-white;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 255, 255, .6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 255, 255, .6);
}

.has-success .form-control.focus,
.has-success .form-control:focus {
  border-color: $color-white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: $color-white;
}

.has-warning .form-control {
  border-color: $color-white;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.has-warning .form-control:focus {
  border-color: #e6e6e6;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px $color-white;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px $color-white;
}

.has-warning .input-group-addon {
  color: $color-white;
  background-color: #FFA630;
  border-color: $color-white;
}

.has-warning .form-control-feedback {
  color: $color-white;
}

.has-warning .form-control {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}

.has-warning .form-control:focus {
  border-color: $color-white;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 255, 255, .6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 255, 255, .6);
}

.has-warning .form-control.focus,
.has-warning .form-control:focus {
  border-color: $color-white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: $color-white;
}

.has-error .form-control {
  border-color: $color-white;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.has-error .form-control:focus {
  border-color: #e6e6e6;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px $color-white;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px $color-white;
}

.has-error .input-group-addon {
  color: $color-white;
  background-color: #E3170A;
  border-color: $color-white;
}

.has-error .form-control-feedback {
  color: $color-white;
}

.has-error .form-control {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}

.has-error .form-control:focus {
  border-color: $color-white;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 255, 255, .6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 255, 255, .6);
}

.has-error .form-control.focus,
.has-error .form-control:focus {
  border-color: $color-white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.help-block {
  color: #b5b5b5;
}

.btn:hover,
.btn:focus,
.btn.focus {
  color: #757575;
}

.btn-default {
  color: #757575;
  background-color: #eee;
  border-color: #eee;
}

.btn-default:focus,
.btn-default.focus {
  color: #757575;
  background-color: #d5d5d5;
  border-color: #aeaeae;
}

.btn-default:hover {
  color: #757575;
  background-color: #d5d5d5;
  border-color: #cfcfcf;
}

.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #757575;
  background-color: #d5d5d5;
  border-color: #cfcfcf;
}

.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
  color: #757575;
  background-color: #c3c3c3;
  border-color: #aeaeae;
}

.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background-image: none;
}

.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus {
  background-color: #eee;
  border-color: #eee;
}

.btn-default .badge {
  color: #eee;
  background-color: #757575;
}

.btn-primary {
  color: $color-white;
  background-color: $color-primary;
  border-color: $color-primary;
}

.btn-primary:focus,
.btn-primary.focus {
  color: $color-white;
  background-color: $color-primary;
  border-color: $color-primary-dark;
}

.btn-primary:hover {
  color: $color-white;
  background-color: $color-primary;
  border-color: $color-primary-dark;
}

.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: $color-white;
  background-color: $color-primary;
  border-color: $color-primary-dark;
}

.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
  color: $color-white;
  background-color: $color-primary;
  border-color: $color-primary-dark;
}

.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus {
  background-color: $color-primary;
  border-color: $color-primary;
}

.btn-primary .badge {
  color: $color-primary;
  background-color: $color-white;
}

.btn-success {
  color: $color-white;
  background-color: #1B998B;
  border-color: #1B998B;
}

.btn-success:focus,
.btn-success.focus {
  color: $color-white;
  background-color: #3d8b40;
  border-color: #255627;
}

.btn-success:hover {
  color: $color-white;
  background-color: #3d8b40;
  border-color: #39843c;
}

.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  color: $color-white;
  background-color: #3d8b40;
  border-color: #39843c;
}

.btn-success:active:hover,
.btn-success.active:hover,
.open > .dropdown-toggle.btn-success:hover,
.btn-success:active:focus,
.btn-success.active:focus,
.open > .dropdown-toggle.btn-success:focus,
.btn-success:active.focus,
.btn-success.active.focus,
.open > .dropdown-toggle.btn-success.focus {
  color: $color-white;
  background-color: #327334;
  border-color: #255627;
}

.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  background-image: none;
}

.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus {
  background-color: #1B998B;
  border-color: #1B998B;
}

.btn-success .badge {
  color: #1B998B;
  background-color: $color-white;
}

.btn-info {
  color: $color-white;
  background-color: #00bcd4;
  border-color: #00bcd4;
}

.btn-info:focus,
.btn-info.focus {
  color: $color-white;
  background-color: #008fa1;
  border-color: #004b55;
}

.btn-info:hover {
  color: $color-white;
  background-color: #008fa1;
  border-color: #008697;
}

.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  color: $color-white;
  background-color: #008fa1;
  border-color: #008697;
}

.btn-info:active:hover,
.btn-info.active:hover,
.open > .dropdown-toggle.btn-info:hover,
.btn-info:active:focus,
.btn-info.active:focus,
.open > .dropdown-toggle.btn-info:focus,
.btn-info:active.focus,
.btn-info.active.focus,
.open > .dropdown-toggle.btn-info.focus {
  color: $color-white;
  background-color: #006f7d;
  border-color: #004b55;
}

.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  background-image: none;
}

.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus {
  background-color: #00bcd4;
  border-color: #00bcd4;
}

.btn-info .badge {
  color: #00bcd4;
  background-color: $color-white;
}

.btn-warning {
  color: $color-white;
  background-color: #FFA630;
  border-color: #FFA630;
}

.btn-warning:focus,
.btn-warning.focus {
  color: $color-white;
  background-color: #cc7a00;
  border-color: #804c00;
}

.btn-warning:hover {
  color: $color-white;
  background-color: #cc7a00;
  border-color: #c27400;
}

.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  color: $color-white;
  background-color: #cc7a00;
  border-color: #c27400;
}

.btn-warning:active:hover,
.btn-warning.active:hover,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning:active:focus,
.btn-warning.active:focus,
.open > .dropdown-toggle.btn-warning:focus,
.btn-warning:active.focus,
.btn-warning.active.focus,
.open > .dropdown-toggle.btn-warning.focus {
  color: $color-white;
  background-color: #a86400;
  border-color: #804c00;
}

.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  background-image: none;
}

.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning.focus {
  background-color: #FFA630;
  border-color: #FFA630;
}

.btn-warning .badge {
  color: #FFA630;
  background-color: $color-white;
}

.btn-danger {
  color: $color-white;
  background-color: #E3170A;
  border-color: #E3170A;
}

.btn-danger:focus,
.btn-danger.focus {
  color: $color-white;
  background-color: #ea1c0d;
  border-color: #a21309;
}

.btn-danger:hover {
  color: $color-white;
  background-color: #ea1c0d;
  border-color: #e11b0c;
}

.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  color: $color-white;
  background-color: #ea1c0d;
  border-color: #e11b0c;
}

.btn-danger:active:hover,
.btn-danger.active:hover,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger:active:focus,
.btn-danger.active:focus,
.open > .dropdown-toggle.btn-danger:focus,
.btn-danger:active.focus,
.btn-danger.active.focus,
.open > .dropdown-toggle.btn-danger.focus {
  color: $color-white;
  background-color: #c8180b;
  border-color: #a21309;
}

.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  background-image: none;
}

.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus {
  background-color: #E3170A;
  border-color: #E3170A;
}

.btn-danger .badge {
  color: #E3170A;
  background-color: $color-white;
}

.btn-link {
  color: #e14365;
}

.btn-link:hover,
.btn-link:focus {
  color: $color-primary;
  text-decoration: underline;
}

.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
  color: #9e9e9e;
}

.dropdown-menu {
  background-color: $color-white;
  border: 1px solid #ccc;
  border: 1px solid #e0e0e0;
}

.dropdown-menu .divider {
  height: 1px;
  margin: 10px 0;
  overflow: hidden;
  background-color: #e0e0e0;
}

.dropdown-menu > li > a {
  color: #757575;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  color: #757575;
  background-color: #eee;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #757575;
  background-color: #eee;
}

.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  color: #bdbdbd;
}

.dropdown-header {
  color: #424242;
}

.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  clip: rect(0, 0, 0, 0);
}

.input-group-addon {
  color: #757575;
  background-color: #eee;
  border: 1px solid #e0e0e0;
}

.nav > li > a:hover,
.nav > li > a:focus {
  background-color: #eee;
}

.nav > li.disabled > a {
  color: #9e9e9e;
}

.nav > li.disabled > a:hover,
.nav > li.disabled > a:focus {
  color: #9e9e9e;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: #eee;
  border-color: #e14365;
}

.nav-tabs {
  border-bottom: 1px solid #e0e0e0;
}

.nav-tabs > li > a:hover {
  border-color: transparent transparent #e0e0e0;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #c2185b;
  background-color: $color-white;
  border: 1px solid #e0e0e0;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  color: $color-white;
  background-color: $color-primary;
}

.nav-tabs-justified > .active > a,
.nav-tabs-justified > .active > a:hover,
.nav-tabs-justified > .active > a:focus {
  border: 1px solid #e0e0e0;
}

@media (min-width: 768px) {
  .nav-tabs-justified > li > a {
    border-bottom: 1px solid #e0e0e0;
  }

  .nav-tabs-justified > .active > a,
  .nav-tabs-justified > .active > a:hover,
  .nav-tabs-justified > .active > a:focus {
    border-bottom-color: $color-white;
  }
}

.navbar-default {
  background-color: $color-white;
  border-color: #e0e0e0;
}

.navbar-default .navbar-brand {
  color: #424242;
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  color: #424242;
  background-color: none;
}

.navbar-default .navbar-text {
  color: #757575;
}

.navbar-default .navbar-nav > li > a {
  color: #757575;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #616161;
  background-color: rgba(238, 238, 238, .3);
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #616161;
  background-color: rgba(238, 238, 238, .6);
}

.navbar-default .navbar-nav > .disabled > a,
.navbar-default .navbar-nav > .disabled > a:hover,
.navbar-default .navbar-nav > .disabled > a:focus {
  color: #bdbdbd;
  background-color: transparent;
}

.navbar-default .navbar-toggle {
  border-color: transparent;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: rgba(238, 238, 238, .3);
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #757575;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: #e0e0e0;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  color: #616161;
  background-color: rgba(238, 238, 238, .6);
}

@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #757575;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #616161;
    background-color: rgba(238, 238, 238, .3);
  }

  .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #616161;
    background-color: rgba(238, 238, 238, .6);
  }

  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #bdbdbd;
    background-color: transparent;
  }
}

.navbar-default .navbar-link {
  color: #757575;
}

.navbar-default .navbar-link:hover {
  color: #616161;
}

.navbar-default .btn-link {
  color: #757575;
}

.navbar-default .btn-link:hover,
.navbar-default .btn-link:focus {
  color: #616161;
}

.navbar-default .btn-link[disabled]:hover,
fieldset[disabled] .navbar-default .btn-link:hover,
.navbar-default .btn-link[disabled]:focus,
fieldset[disabled] .navbar-default .btn-link:focus {
  color: #bdbdbd;
}

.navbar-inverse {
  background-color: $color-primary;
  border-color: rgba(0, 0, 0, .1);
}

.navbar-inverse .navbar-brand {
  color: $color-white;
}

.navbar-inverse .navbar-brand:hover,
.navbar-inverse .navbar-brand:focus {
  color: $color-white;
  background-color: none;
}

.navbar-inverse .navbar-text {
  color: $color-white;
}

.navbar-inverse .navbar-nav > li > a {
  color: $color-white;
}

.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li > a:focus {
  color: $color-white;
  background-color: rgba(0, 0, 0, .1);
}

.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus {
  color: $color-white;
  background-color: rgba(0, 0, 0, .1);
}

.navbar-inverse .navbar-nav > .disabled > a,
.navbar-inverse .navbar-nav > .disabled > a:hover,
.navbar-inverse .navbar-nav > .disabled > a:focus {
  color: $color-white;
  background-color: transparent;
}

.navbar-inverse .navbar-toggle {
  border-color: transparent;
}

.navbar-inverse .navbar-toggle:hover,
.navbar-inverse .navbar-toggle:focus {
  background-color: rgba(0, 0, 0, .1);
}

.navbar-inverse .navbar-toggle .icon-bar {
  background-color: $color-white;
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border-color: #cf1454;
}

.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus {
  color: $color-white;
  background-color: rgba(0, 0, 0, .1);
}

@media (max-width: 767px) {
  .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
    border-color: rgba(0, 0, 0, .1);
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
    background-color: rgba(0, 0, 0, .1);
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: $color-white;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: $color-white;
    background-color: rgba(0, 0, 0, .1);
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: $color-white;
    background-color: rgba(0, 0, 0, .1);
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: $color-white;
    background-color: transparent;
  }
}

.navbar-inverse .navbar-link {
  color: $color-white;
}

.navbar-inverse .navbar-link:hover {
  color: $color-white;
}

.navbar-inverse .btn-link {
  color: $color-white;
}

.navbar-inverse .btn-link:hover,
.navbar-inverse .btn-link:focus {
  color: $color-white;
}

.navbar-inverse .btn-link[disabled]:hover,
fieldset[disabled] .navbar-inverse .btn-link:hover,
.navbar-inverse .btn-link[disabled]:focus,
fieldset[disabled] .navbar-inverse .btn-link:focus {
  color: $color-white;
}

.breadcrumb {
  background-color: transparent;
}

.breadcrumb > li + li:before {
  color: #e14365;
}

.breadcrumb > .active {
  color: #757575;
}

.pagination > li > a,
.pagination > li > span {
  color: #757575;
  background-color: transparent;
  border: 1px solid #e0e0e0;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  color: $color-primary;
  background-color: #eee;
  border-color: #e0e0e0;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  color: $color-white;
  background-color: $color-primary;
  border-color: $color-primary;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #bdbdbd;
  background-color: transparent;
  border-color: #e0e0e0;
}

.pager li > a,
.pager li > span {
  background-color: transparent;
  border: 1px solid #e0e0e0;
}

.pager li > a:hover,
.pager li > a:focus {
  background-color: $color-white;
}

.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span {
  color: #bdbdbd;
  background-color: transparent;
}

.label {
  color: $color-white;
}

a.label:hover,
a.label:focus {
  color: $color-white;
}

.label-default {
  background-color: #e0e0e0;
}

.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #c7c7c7;
}

.label-primary {
  background-color: $color-primary;
}

.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #c1134e;
}

.label-success {
  background-color: #1B998B;
}

.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #3d8b40;
}

.label-info {
  background-color: #00bcd4;
}

.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #008fa1;
}

.label-warning {
  background-color: #FFA630;
}

.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #cc7a00;
}

.label-danger {
  background-color: #E3170A;
}

.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #ea1c0d;
}

.badge {
  color: #757575;
  background-color: #e0e0e0;
}

a.badge:hover,
a.badge:focus {
  color: #9e9e9e;
}

.list-group-item.active > .badge,
.nav-pills > .active > a > .badge {
  color: #616161;
  background-color: #e0e0e0;
}

.jumbotron {
  color: inherit;
  background-color: #e0e0e0;
}

.jumbotron h1,
.jumbotron .h1 {
  color: inherit;
}

.jumbotron > hr {
  border-top-color: #c7c7c7;
}

.thumbnail {
  background-color: $color-white;
  border: 1px solid #e0e0e0;
}

a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
  border-color: #e14365;
}

.thumbnail .caption {
  color: #757575;
}

.alert-success {
  color: #1B998B;
  background-color: rgba(200, 230, 201, .8);
  border-color: #c8e6c9;
}

.alert-success hr {
  border-top-color: #b6deb8;
}

.alert-success .alert-link {
  color: #3d8b40;
}

.alert-success .close {
  color: #1B998B;
}

.alert-success .close:hover,
.alert-success .close:focus {
  color: #1B998B;
}

.alert-info {
  color: #00bcd4;
  background-color: rgba(178, 235, 242, .8);
  border-color: #b2ebf2;
}

.alert-info hr {
  border-top-color: #9ce5ee;
}

.alert-info .alert-link {
  color: #008fa1;
}

.alert-info .close {
  color: #00bcd4;
}

.alert-info .close:hover,
.alert-info .close:focus {
  color: #00bcd4;
}

.alert-warning {
  color: #FFA630;
  background-color: rgba(255, 224, 178, .8);
  border-color: #ffe0b2;
}

.alert-warning hr {
  border-top-color: #ffd699;
}

.alert-warning .alert-link {
  color: #cc7a00;
}

.alert-warning .close {
  color: #FFA630;
}

.alert-warning .close:hover,
.alert-warning .close:focus {
  color: #FFA630;
}

.alert-danger {
  color: #E3170A;
  background-color: rgba(255, 205, 210, .8);
  border-color: #ffcdd2;
}

.alert-danger hr {
  border-top-color: #ffb3bb;
}

.alert-danger .alert-link {
  color: #ea1c0d;
}

.alert-danger .close {
  color: #E3170A;
}

.alert-danger .close:hover,
.alert-danger .close:focus {
  color: #E3170A;
}

.progress {
  background-color: #e0e0e0;
}

.progress-bar {
  color: $color-white;
  background-color: $color-primary;
}

.progress-bar-success {
  background-color: #1B998B;
}

.progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
}

.progress-bar-info {
  background-color: #00bcd4;
}

.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
}

.progress-bar-warning {
  background-color: #FFA630;
}

.progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
}

.progress-bar-danger {
  background-color: #E3170A;
}

.progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
}

.list-group-item {
  background-color: $color-white;
  border: 1px solid transparent;
}

a.list-group-item,
button.list-group-item {
  color: #757575;
}

a.list-group-item .list-group-item-heading,
button.list-group-item .list-group-item-heading {
  color: #424242;
}

a.list-group-item:hover,
button.list-group-item:hover,
a.list-group-item:focus,
button.list-group-item:focus {
  color: #757575;
  background-color: #eee;
}

.list-group-item.disabled,
.list-group-item.disabled:hover,
.list-group-item.disabled:focus {
  color: #bdbdbd;
  background-color: transparent;
}

.list-group-item.disabled .list-group-item-text,
.list-group-item.disabled:hover .list-group-item-text,
.list-group-item.disabled:focus .list-group-item-text {
  color: #bdbdbd;
}

.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  color: $color-primary;
  background-color: transparent;
  border-color: transparent;
}

.list-group-item.active .list-group-item-text,
.list-group-item.active:hover .list-group-item-text,
.list-group-item.active:focus .list-group-item-text {
  color: #fbd8e4;
}

.list-group-item-success {
  color: $color-white;
  background-color: #1B998B;
}

a.list-group-item-success,
button.list-group-item-success {
  color: $color-white;
}

a.list-group-item-success .list-group-item-heading,
button.list-group-item-success .list-group-item-heading {
  color: inherit;
}

a.list-group-item-success:hover,
button.list-group-item-success:hover,
a.list-group-item-success:focus,
button.list-group-item-success:focus {
  color: $color-white;
  background-color: #449d48;
}

a.list-group-item-success.active,
button.list-group-item-success.active,
a.list-group-item-success.active:hover,
button.list-group-item-success.active:hover,
a.list-group-item-success.active:focus,
button.list-group-item-success.active:focus {
  color: $color-white;
  background-color: $color-white;
  border-color: $color-white;
}

.list-group-item-info {
  color: $color-white;
  background-color: #00bcd4;
}

a.list-group-item-info,
button.list-group-item-info {
  color: $color-white;
}

a.list-group-item-info .list-group-item-heading,
button.list-group-item-info .list-group-item-heading {
  color: inherit;
}

a.list-group-item-info:hover,
button.list-group-item-info:hover,
a.list-group-item-info:focus,
button.list-group-item-info:focus {
  color: $color-white;
  background-color: #00a5bb;
}

a.list-group-item-info.active,
button.list-group-item-info.active,
a.list-group-item-info.active:hover,
button.list-group-item-info.active:hover,
a.list-group-item-info.active:focus,
button.list-group-item-info.active:focus {
  color: $color-white;
  background-color: $color-white;
  border-color: $color-white;
}

.list-group-item-warning {
  color: $color-white;
  background-color: #FFA630;
}

a.list-group-item-warning,
button.list-group-item-warning {
  color: $color-white;
}

a.list-group-item-warning .list-group-item-heading,
button.list-group-item-warning .list-group-item-heading {
  color: inherit;
}

a.list-group-item-warning:hover,
button.list-group-item-warning:hover,
a.list-group-item-warning:focus,
button.list-group-item-warning:focus {
  color: $color-white;
  background-color: #e68900;
}

a.list-group-item-warning.active,
button.list-group-item-warning.active,
a.list-group-item-warning.active:hover,
button.list-group-item-warning.active:hover,
a.list-group-item-warning.active:focus,
button.list-group-item-warning.active:focus {
  color: $color-white;
  background-color: $color-white;
  border-color: $color-white;
}

.list-group-item-danger {
  color: $color-white;
  background-color: #E3170A;
}

a.list-group-item-danger,
button.list-group-item-danger {
  color: $color-white;
}

a.list-group-item-danger .list-group-item-heading,
button.list-group-item-danger .list-group-item-heading {
  color: inherit;
}

a.list-group-item-danger:hover,
button.list-group-item-danger:hover,
a.list-group-item-danger:focus,
button.list-group-item-danger:focus {
  color: $color-white;
  background-color: #f32c1e;
}

a.list-group-item-danger.active,
button.list-group-item-danger.active,
a.list-group-item-danger.active:hover,
button.list-group-item-danger.active:hover,
a.list-group-item-danger.active:focus,
button.list-group-item-danger.active:focus {
  color: $color-white;
  background-color: $color-white;
  border-color: $color-white;
}

.panel {
  background-color: $color-white;
}

.panel-footer {
  background-color: transparent;
  border-top: 1px solid #e0e0e0;
}

.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive,
.panel > .table + .panel-body,
.panel > .table-responsive + .panel-body {
  border-top: 1px solid #e0e0e0;
}

.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #e0e0e0;
}

.panel-default {
  border-color: #e0e0e0;
}

.panel-default > .panel-heading {
  color: #757575;
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #e0e0e0;
}

.panel-default > .panel-heading .badge {
  color: #e0e0e0;
  background-color: #757575;
}

.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #e0e0e0;
}

.panel-primary {
  border-color: $color-primary;
}

.panel-primary > .panel-heading {
  color: $color-white;
  background-color: $color-primary;
  border-color: $color-primary;
}

.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: $color-primary;
}

.panel-primary > .panel-heading .badge {
  color: $color-primary;
  background-color: $color-white;
}

.panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: $color-primary;
}

.panel-success {
  border-color: #509d44;
}

.panel-success > .panel-heading {
  color: $color-white;
  background-color: #1B998B;
  border-color: #509d44;
}

.panel-success > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #509d44;
}

.panel-success > .panel-heading .badge {
  color: #1B998B;
  background-color: $color-white;
}

.panel-success > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #509d44;
}

.panel-info {
  border-color: #00b0a7;
}

.panel-info > .panel-heading {
  color: $color-white;
  background-color: #00bcd4;
  border-color: #00b0a7;
}

.panel-info > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #00b0a7;
}

.panel-info > .panel-heading .badge {
  color: #00bcd4;
  background-color: $color-white;
}

.panel-info > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #00b0a7;
}

.panel-warning {
  border-color: #f06700;
}

.panel-warning > .panel-heading {
  color: $color-white;
  background-color: #FFA630;
  border-color: #f06700;
}

.panel-warning > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #f06700;
}

.panel-warning > .panel-heading .badge {
  color: #FFA630;
  background-color: $color-white;
}

.panel-warning > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #f06700;
}

.panel-danger {
  border-color: #f3273c;
}

.panel-danger > .panel-heading {
  color: $color-white;
  background-color: #E3170A;
  border-color: #f3273c;
}

.panel-danger > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #f3273c;
}

.panel-danger > .panel-heading .badge {
  color: #E3170A;
  background-color: $color-white;
}

.panel-danger > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #f3273c;
}

.well {
  background-color: #eee;
  border: 1px solid #e0e0e0;
}

.close {
  color: #000;
  text-shadow: none;
}

.close:hover,
.close:focus {
  color: #000;
}

.modal-content {
  background-color: $color-white;
  border: 1px solid #999;
  border: 1px solid transparent;
}

.modal-backdrop {
  background-color: #000;
}

.modal-header {
  border-bottom: 1px solid #e0e0e0;
}

.modal-footer {
  border-top: 1px solid #e0e0e0;
}

.tooltip-inner {
  color: $color-white;
  background-color: rgba(0, 0, 0, .8);
}

.tooltip.top .tooltip-arrow {
  border-top-color: rgba(0, 0, 0, .8);
}

.tooltip.top-left .tooltip-arrow {
  border-top-color: rgba(0, 0, 0, .8);
}

.tooltip.top-right .tooltip-arrow {
  border-top-color: rgba(0, 0, 0, .8);
}

.tooltip.right .tooltip-arrow {
  border-right-color: rgba(0, 0, 0, .8);
}

.tooltip.left .tooltip-arrow {
  border-left-color: rgba(0, 0, 0, .8);
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: rgba(0, 0, 0, .8);
}

.tooltip.bottom-left .tooltip-arrow {
  border-bottom-color: rgba(0, 0, 0, .8);
}

.tooltip.bottom-right .tooltip-arrow {
  border-bottom-color: rgba(0, 0, 0, .8);
}

.popover {
  background-color: $color-white;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #e0e0e0;
  border: 1px solid rgba(189, 189, 189, .8);
}

.popover-title {
  background-color: #eee;
  border-bottom: 1px solid #e1e1e1;
}

.popover.top > .arrow {
  border-top-color: #adadad;
  border-top-color: rgba(189, 189, 189, .85);
}

.popover.top > .arrow:after {
  border-top-color: $color-white;
}

.popover.right > .arrow {
  border-right-color: #adadad;
  border-right-color: rgba(189, 189, 189, .85);
}

.popover.right > .arrow:after {
  border-right-color: $color-white;
}

.popover.bottom > .arrow {
  border-bottom-color: #adadad;
  border-bottom-color: rgba(189, 189, 189, .85);
}

.popover.bottom > .arrow:after {
  border-bottom-color: $color-white;
}

.popover.left > .arrow {
  border-left-color: #adadad;
  border-left-color: rgba(189, 189, 189, .85);
}

.popover.left > .arrow:after {
  border-left-color: $color-white;
}

.carousel-control {
  color: $color-white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
}

.carousel-control:hover,
.carousel-control:focus {
  color: $color-white;
}

.carousel-indicators li {
  border: 1px solid $color-white;
}

.carousel-indicators .active {
  background-color: $color-white;
}

.carousel-caption {
  color: $color-white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
}

a.text-action {
  color: #9e9e9e;
}

a.text-action:hover,
a.text-action:focus {
  color: #bdbdbd;
}

a.text-like {
  color: #9e9e9e !important;
}

a.text-like.active,
a.text-like:hover,
a.text-like:focus {
  color: #e53935 !important;
}

.img-bordered {
  border: 1px solid #e0e0e0;
}

.img-bordered-primary {
  border-color: $color-primary !important;
}

.img-bordered-purple {
  border-color: #8e24aa !important;
}

.img-bordered-red {
  border-color: #e53935 !important;
}

.img-bordered-green {
  border-color: $color-success !important;
}

.img-bordered-orange {
  border-color: $color-warning !important;
}

mark,
.mark {
  color: $color-white;
}

.drop-cap {
  color: #212121;
}

.drop-cap-reversed {
  color: $color-white;
  background-color: #212121;
}

.text-primary {
  color: $color-primary;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #c1134e;
}

.text-success {
  color: #1B998B;
}

a.text-success:hover,
a.text-success:focus {
  color: #3d8b40;
}

.text-info {
  color: #00bcd4;
}

a.text-info:hover,
a.text-info:focus {
  color: #008fa1;
}

.text-warning {
  color: #FFA630;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #cc7a00;
}

.text-danger {
  color: #E3170A;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #ea1c0d;
}

blockquote {
  color: #616161;
}

.blockquote-success {
  background-color: rgba(76, 175, 80, .1);
  border-color: #1B998B;
}

.blockquote-info {
  background-color: rgba(0, 188, 212, .1);
  border-color: #00bcd4;
}

.blockquote-warning {
  background-color: rgba(255, 152, 0, .1);
  border-color: #FFA630;
}

.blockquote-danger {
  background-color: rgba(244, 67, 54, .1);
  border-color: #E3170A;
}

code {
  border: 1px solid #f8bbd0;
}

.table {
  color: #757575;
}

.table > thead > tr > th,
.table > tfoot > tr > th {
  color: #616161;
}

.table > thead > tr > th {
  border-bottom: 1px solid #e0e0e0;
}

.table > tbody + tbody {
  border-top: 1px solid #e0e0e0;
}

.table .success,
.table .warning,
.table .danger,
.table .info {
  color: $color-white;
}

.table .success a,
.table .warning a,
.table .danger a,
.table .info a {
  color: $color-white;
}

.table-primary thead tr,
.table-success thead tr,
.table-info thead tr,
.table-warning thead tr,
.table-danger thead tr,
.table-dark thead tr {
  color: $color-white;
}

.table-default thead tr {
  background: #eee;
}

.table-primary thead tr {
  background: $color-primary;
}

.table-success thead tr {
  background: #1B998B;
}

.table-info thead tr {
  background: #00bcd4;
}

.table-warning thead tr {
  background: #FFA630;
}

.table-danger thead tr {
  background: #E3170A;
}

.table-dark thead tr {
  background: #616161;
}

.table-gray thead tr {
  color: #616161;
  background: #bdbdbd;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 1px;
}

.table-bordered > thead:first-child > tr:first-child > th {
  border: 1px solid #e0e0e0;
}

.table-section.active tr {
  background-color: #eee;
}

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #1B998B;
}

.has-success .form-control {
  border-color: #1B998B;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.has-success .form-control:focus {
  border-color: #3d8b40;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #92cf94;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #92cf94;
}

.has-success .input-group-addon {
  color: #1B998B;
  background-color: $color-white;
  border-color: #1B998B;
}

.has-success .form-control-feedback {
  color: #1B998B;
}

.has-success .form-control {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}

.has-success .form-control:focus {
  border-color: #1B998B;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(76, 175, 80, .6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(76, 175, 80, .6);
}

.has-success .form-control.focus,
.has-success .form-control:focus {
  border-color: #1B998B;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #FFA630;
}

.has-warning .form-control {
  border-color: #FFA630;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.has-warning .form-control:focus {
  border-color: #cc7a00;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ffc166;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ffc166;
}

.has-warning .input-group-addon {
  color: #FFA630;
  background-color: $color-white;
  border-color: #FFA630;
}

.has-warning .form-control-feedback {
  color: #FFA630;
}

.has-warning .form-control {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}

.has-warning .form-control:focus {
  border-color: #FFA630;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 152, 0, .6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 152, 0, .6);
}

.has-warning .form-control.focus,
.has-warning .form-control:focus {
  border-color: #FFA630;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #E3170A;
}

.has-error .form-control {
  border-color: #E3170A;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.has-error .form-control:focus {
  border-color: #ea1c0d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #f99d97;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #f99d97;
}

.has-error .input-group-addon {
  color: #E3170A;
  background-color: $color-white;
  border-color: #E3170A;
}

.has-error .form-control-feedback {
  color: #E3170A;
}

.has-error .form-control {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}

.has-error .form-control:focus {
  border-color: #E3170A;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(244, 67, 54, .6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(244, 67, 54, .6);
}

.has-error .form-control.focus,
.has-error .form-control:focus {
  border-color: #E3170A;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.input-group-file input[type="text"] {
  background-color: $color-white;
}

.input-search-close {
  color: #000;
}

.input-search-close:hover,
.input-search-close:focus {
  color: #000;
}

button.input-search-close {
  background: transparent;
  border: 0;
}

.input-search .input-search-icon {
  color: #9e9e9e;
}

.input-search-btn {
  background: transparent;
  border: none;
}

.input-search-dark .input-search-icon {
  color: #757575;
}

.input-search-dark .form-control {
  background: #eee;
}

.input-search-dark .form-control:focus {
  background-color: transparent;
}

.btn-default:hover,
.btn-default:focus,
.btn-default.focus {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}

.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}

.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  color: #757575;
  background-color: #eee;
  border-color: #eee;
}

.btn-default.btn-up:before {
  border-bottom-color: #eee;
}

.btn-default.btn-up:hover:before,
.btn-default.btn-up:focus:before {
  border-bottom-color: #f5f5f5;
}

.btn-default.btn-up:active:before,
.btn-default.btn-up.active:before,
.open > .dropdown-toggle.btn-default.btn-up:before {
  border-bottom-color: #e0e0e0;
}

.btn-default.btn-right:before {
  border-left-color: #eee;
}

.btn-default.btn-right:hover:before,
.btn-default.btn-right:focus:before {
  border-left-color: #f5f5f5;
}

.btn-default.btn-right:active:before,
.btn-default.btn-right.active:before,
.open > .dropdown-toggle.btn-default.btn-right:before {
  border-left-color: #e0e0e0;
}

.btn-default.btn-bottom:before {
  border-top-color: #eee;
}

.btn-default.btn-bottom:hover:before,
.btn-default.btn-bottom:focus:before {
  border-top-color: #f5f5f5;
}

.btn-default.btn-bottom:active:before,
.btn-default.btn-bottom.active:before,
.open > .dropdown-toggle.btn-default.btn-bottom:before {
  border-top-color: #e0e0e0;
}

.btn-default.btn-left:before {
  border-right-color: #eee;
}

.btn-default.btn-left:hover:before,
.btn-default.btn-left:focus:before {
  border-right-color: #f5f5f5;
}

.btn-default.btn-left:active:before,
.btn-default.btn-left.active:before,
.open > .dropdown-toggle.btn-default.btn-left:before {
  border-right-color: #e0e0e0;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus {
  background-color: #ec407a;
  border-color: #ec407a;
}

.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-color: #e14365;
  border-color: #e14365;
}

.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
  background-color: #e14365;
  border-color: #e14365;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  color: $color-white;
  background-color: #ec407a;
  border-color: #ec407a;
}

.btn-primary.btn-up:before {
  border-bottom-color: $color-primary;
}

.btn-primary.btn-up:hover:before,
.btn-primary.btn-up:focus:before {
  border-bottom-color: #ec407a;
}

.btn-primary.btn-up:active:before,
.btn-primary.btn-up.active:before,
.open > .dropdown-toggle.btn-primary.btn-up:before {
  border-bottom-color: #e14365;
}

.btn-primary.btn-right:before {
  border-left-color: $color-primary;
}

.btn-primary.btn-right:hover:before,
.btn-primary.btn-right:focus:before {
  border-left-color: #ec407a;
}

.btn-primary.btn-right:active:before,
.btn-primary.btn-right.active:before,
.open > .dropdown-toggle.btn-primary.btn-right:before {
  border-left-color: #e14365;
}

.btn-primary.btn-bottom:before {
  border-top-color: $color-primary;
}

.btn-primary.btn-bottom:hover:before,
.btn-primary.btn-bottom:focus:before {
  border-top-color: #ec407a;
}

.btn-primary.btn-bottom:active:before,
.btn-primary.btn-bottom.active:before,
.open > .dropdown-toggle.btn-primary.btn-bottom:before {
  border-top-color: #e14365;
}

.btn-primary.btn-left:before {
  border-right-color: $color-primary;
}

.btn-primary.btn-left:hover:before,
.btn-primary.btn-left:focus:before {
  border-right-color: #ec407a;
}

.btn-primary.btn-left:active:before,
.btn-primary.btn-left.active:before,
.open > .dropdown-toggle.btn-primary.btn-left:before {
  border-right-color: #e14365;
}

.btn-success:hover,
.btn-success:focus,
.btn-success.focus {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  background-color: #43a047;
  border-color: #43a047;
}

.btn-success:active:hover,
.btn-success.active:hover,
.open > .dropdown-toggle.btn-success:hover,
.btn-success:active:focus,
.btn-success.active:focus,
.open > .dropdown-toggle.btn-success:focus,
.btn-success:active.focus,
.btn-success.active.focus,
.open > .dropdown-toggle.btn-success.focus {
  background-color: #43a047;
  border-color: #43a047;
}

.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
  color: $color-white;
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.btn-success.btn-up:before {
  border-bottom-color: #1B998B;
}

.btn-success.btn-up:hover:before,
.btn-success.btn-up:focus:before {
  border-bottom-color: #66bb6a;
}

.btn-success.btn-up:active:before,
.btn-success.btn-up.active:before,
.open > .dropdown-toggle.btn-success.btn-up:before {
  border-bottom-color: #43a047;
}

.btn-success.btn-right:before {
  border-left-color: #1B998B;
}

.btn-success.btn-right:hover:before,
.btn-success.btn-right:focus:before {
  border-left-color: #66bb6a;
}

.btn-success.btn-right:active:before,
.btn-success.btn-right.active:before,
.open > .dropdown-toggle.btn-success.btn-right:before {
  border-left-color: #43a047;
}

.btn-success.btn-bottom:before {
  border-top-color: #1B998B;
}

.btn-success.btn-bottom:hover:before,
.btn-success.btn-bottom:focus:before {
  border-top-color: #66bb6a;
}

.btn-success.btn-bottom:active:before,
.btn-success.btn-bottom.active:before,
.open > .dropdown-toggle.btn-success.btn-bottom:before {
  border-top-color: #43a047;
}

.btn-success.btn-left:before {
  border-right-color: #1B998B;
}

.btn-success.btn-left:hover:before,
.btn-success.btn-left:focus:before {
  border-right-color: #66bb6a;
}

.btn-success.btn-left:active:before,
.btn-success.btn-left.active:before,
.open > .dropdown-toggle.btn-success.btn-left:before {
  border-right-color: #43a047;
}

.btn-info:hover,
.btn-info:focus,
.btn-info.focus {
  background-color: #26c6da;
  border-color: #26c6da;
}

.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  background-color: #00acc1;
  border-color: #00acc1;
}

.btn-info:active:hover,
.btn-info.active:hover,
.open > .dropdown-toggle.btn-info:hover,
.btn-info:active:focus,
.btn-info.active:focus,
.open > .dropdown-toggle.btn-info:focus,
.btn-info:active.focus,
.btn-info.active.focus,
.open > .dropdown-toggle.btn-info.focus {
  background-color: #00acc1;
  border-color: #00acc1;
}

.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  color: $color-white;
  background-color: #26c6da;
  border-color: #26c6da;
}

.btn-info.btn-up:before {
  border-bottom-color: #00bcd4;
}

.btn-info.btn-up:hover:before,
.btn-info.btn-up:focus:before {
  border-bottom-color: #26c6da;
}

.btn-info.btn-up:active:before,
.btn-info.btn-up.active:before,
.open > .dropdown-toggle.btn-info.btn-up:before {
  border-bottom-color: #00acc1;
}

.btn-info.btn-right:before {
  border-left-color: #00bcd4;
}

.btn-info.btn-right:hover:before,
.btn-info.btn-right:focus:before {
  border-left-color: #26c6da;
}

.btn-info.btn-right:active:before,
.btn-info.btn-right.active:before,
.open > .dropdown-toggle.btn-info.btn-right:before {
  border-left-color: #00acc1;
}

.btn-info.btn-bottom:before {
  border-top-color: #00bcd4;
}

.btn-info.btn-bottom:hover:before,
.btn-info.btn-bottom:focus:before {
  border-top-color: #26c6da;
}

.btn-info.btn-bottom:active:before,
.btn-info.btn-bottom.active:before,
.open > .dropdown-toggle.btn-info.btn-bottom:before {
  border-top-color: #00acc1;
}

.btn-info.btn-left:before {
  border-right-color: #00bcd4;
}

.btn-info.btn-left:hover:before,
.btn-info.btn-left:focus:before {
  border-right-color: #26c6da;
}

.btn-info.btn-left:active:before,
.btn-info.btn-left.active:before,
.open > .dropdown-toggle.btn-info.btn-left:before {
  border-right-color: #00acc1;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus {
  background-color: #ffa726;
  border-color: #ffa726;
}

.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  background-color: #fb8c00;
  border-color: #fb8c00;
}

.btn-warning:active:hover,
.btn-warning.active:hover,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning:active:focus,
.btn-warning.active:focus,
.open > .dropdown-toggle.btn-warning:focus,
.btn-warning:active.focus,
.btn-warning.active.focus,
.open > .dropdown-toggle.btn-warning.focus {
  background-color: #fb8c00;
  border-color: #fb8c00;
}

.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning.focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
  color: $color-white;
  background-color: #ffa726;
  border-color: #ffa726;
}

.btn-warning.btn-up:before {
  border-bottom-color: #FFA630;
}

.btn-warning.btn-up:hover:before,
.btn-warning.btn-up:focus:before {
  border-bottom-color: #ffa726;
}

.btn-warning.btn-up:active:before,
.btn-warning.btn-up.active:before,
.open > .dropdown-toggle.btn-warning.btn-up:before {
  border-bottom-color: #fb8c00;
}

.btn-warning.btn-right:before {
  border-left-color: #FFA630;
}

.btn-warning.btn-right:hover:before,
.btn-warning.btn-right:focus:before {
  border-left-color: #ffa726;
}

.btn-warning.btn-right:active:before,
.btn-warning.btn-right.active:before,
.open > .dropdown-toggle.btn-warning.btn-right:before {
  border-left-color: #fb8c00;
}

.btn-warning.btn-bottom:before {
  border-top-color: #FFA630;
}

.btn-warning.btn-bottom:hover:before,
.btn-warning.btn-bottom:focus:before {
  border-top-color: #ffa726;
}

.btn-warning.btn-bottom:active:before,
.btn-warning.btn-bottom.active:before,
.open > .dropdown-toggle.btn-warning.btn-bottom:before {
  border-top-color: #fb8c00;
}

.btn-warning.btn-left:before {
  border-right-color: #FFA630;
}

.btn-warning.btn-left:hover:before,
.btn-warning.btn-left:focus:before {
  border-right-color: #ffa726;
}

.btn-warning.btn-left:active:before,
.btn-warning.btn-left.active:before,
.open > .dropdown-toggle.btn-warning.btn-left:before {
  border-right-color: #fb8c00;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus {
  background-color: #ef5350;
  border-color: #ef5350;
}

.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  background-color: #e53935;
  border-color: #e53935;
}

.btn-danger:active:hover,
.btn-danger.active:hover,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger:active:focus,
.btn-danger.active:focus,
.open > .dropdown-toggle.btn-danger:focus,
.btn-danger:active.focus,
.btn-danger.active.focus,
.open > .dropdown-toggle.btn-danger.focus {
  background-color: #e53935;
  border-color: #e53935;
}

.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
  color: $color-white;
  background-color: #ef5350;
  border-color: #ef5350;
}

.btn-danger.btn-up:before {
  border-bottom-color: #E3170A;
}

.btn-danger.btn-up:hover:before,
.btn-danger.btn-up:focus:before {
  border-bottom-color: #ef5350;
}

.btn-danger.btn-up:active:before,
.btn-danger.btn-up.active:before,
.open > .dropdown-toggle.btn-danger.btn-up:before {
  border-bottom-color: #e53935;
}

.btn-danger.btn-right:before {
  border-left-color: #E3170A;
}

.btn-danger.btn-right:hover:before,
.btn-danger.btn-right:focus:before {
  border-left-color: #ef5350;
}

.btn-danger.btn-right:active:before,
.btn-danger.btn-right.active:before,
.open > .dropdown-toggle.btn-danger.btn-right:before {
  border-left-color: #e53935;
}

.btn-danger.btn-bottom:before {
  border-top-color: #E3170A;
}

.btn-danger.btn-bottom:hover:before,
.btn-danger.btn-bottom:focus:before {
  border-top-color: #ef5350;
}

.btn-danger.btn-bottom:active:before,
.btn-danger.btn-bottom.active:before,
.open > .dropdown-toggle.btn-danger.btn-bottom:before {
  border-top-color: #e53935;
}

.btn-danger.btn-left:before {
  border-right-color: #E3170A;
}

.btn-danger.btn-left:hover:before,
.btn-danger.btn-left:focus:before {
  border-right-color: #ef5350;
}

.btn-danger.btn-left:active:before,
.btn-danger.btn-left.active:before,
.open > .dropdown-toggle.btn-danger.btn-left:before {
  border-right-color: #e53935;
}

.btn-inverse {
  color: #757575;
  background-color: $color-white;
  border-color: #eee;
}

.btn-inverse:focus,
.btn-inverse.focus {
  color: #757575;
  background-color: #e6e6e6;
  border-color: #aeaeae;
}

.btn-inverse:hover {
  color: #757575;
  background-color: #e6e6e6;
  border-color: #cfcfcf;
}

.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
  color: #757575;
  background-color: #e6e6e6;
  border-color: #cfcfcf;
}

.btn-inverse:active:hover,
.btn-inverse.active:hover,
.open > .dropdown-toggle.btn-inverse:hover,
.btn-inverse:active:focus,
.btn-inverse.active:focus,
.open > .dropdown-toggle.btn-inverse:focus,
.btn-inverse:active.focus,
.btn-inverse.active.focus,
.open > .dropdown-toggle.btn-inverse.focus {
  color: #757575;
  background-color: #d4d4d4;
  border-color: #aeaeae;
}

.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
  background-image: none;
}

.btn-inverse.disabled:hover,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse:hover,
.btn-inverse.disabled:focus,
.btn-inverse[disabled]:focus,
fieldset[disabled] .btn-inverse:focus,
.btn-inverse.disabled.focus,
.btn-inverse[disabled].focus,
fieldset[disabled] .btn-inverse.focus {
  background-color: $color-white;
  border-color: #eee;
}

.btn-inverse .badge {
  color: $color-white;
  background-color: #757575;
}

.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse.focus {
  background-color: $color-white;
  border-color: #f5f5f5;
}

.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
  background-color: $color-white;
  border-color: #e0e0e0;
}

.btn-inverse:active:hover,
.btn-inverse.active:hover,
.open > .dropdown-toggle.btn-inverse:hover,
.btn-inverse:active:focus,
.btn-inverse.active:focus,
.open > .dropdown-toggle.btn-inverse:focus,
.btn-inverse:active.focus,
.btn-inverse.active.focus,
.open > .dropdown-toggle.btn-inverse.focus {
  background-color: $color-white;
  border-color: #e0e0e0;
}

.btn-inverse.disabled,
.btn-inverse[disabled],
fieldset[disabled] .btn-inverse,
.btn-inverse.disabled:hover,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse:hover,
.btn-inverse.disabled:focus,
.btn-inverse[disabled]:focus,
fieldset[disabled] .btn-inverse:focus,
.btn-inverse.disabled.focus,
.btn-inverse[disabled].focus,
fieldset[disabled] .btn-inverse.focus,
.btn-inverse.disabled:active,
.btn-inverse[disabled]:active,
fieldset[disabled] .btn-inverse:active,
.btn-inverse.disabled.active,
.btn-inverse[disabled].active,
fieldset[disabled] .btn-inverse.active {
  color: #bdbdbd;
  background-color: $color-white;
  border-color: #9e9e9e;
}

.btn-inverse.btn-up:before {
  border-bottom-color: $color-white;
}

.btn-inverse.btn-up:hover:before,
.btn-inverse.btn-up:focus:before {
  border-bottom-color: $color-white;
}

.btn-inverse.btn-up:active:before,
.btn-inverse.btn-up.active:before,
.open > .dropdown-toggle.btn-inverse.btn-up:before {
  border-bottom-color: $color-white;
}

.btn-inverse.btn-right:before {
  border-left-color: $color-white;
}

.btn-inverse.btn-right:hover:before,
.btn-inverse.btn-right:focus:before {
  border-left-color: $color-white;
}

.btn-inverse.btn-right:active:before,
.btn-inverse.btn-right.active:before,
.open > .dropdown-toggle.btn-inverse.btn-right:before {
  border-left-color: $color-white;
}

.btn-inverse.btn-bottom:before {
  border-top-color: $color-white;
}

.btn-inverse.btn-bottom:hover:before,
.btn-inverse.btn-bottom:focus:before {
  border-top-color: $color-white;
}

.btn-inverse.btn-bottom:active:before,
.btn-inverse.btn-bottom.active:before,
.open > .dropdown-toggle.btn-inverse.btn-bottom:before {
  border-top-color: $color-white;
}

.btn-inverse.btn-left:before {
  border-right-color: $color-white;
}

.btn-inverse.btn-left:hover:before,
.btn-inverse.btn-left:focus:before {
  border-right-color: $color-white;
}

.btn-inverse.btn-left:active:before,
.btn-inverse.btn-left.active:before,
.open > .dropdown-toggle.btn-inverse.btn-left:before {
  border-right-color: $color-white;
}

.btn-dark {
  color: $color-white;
  background-color: #616161;
  border-color: #616161;
}

.btn-dark:focus,
.btn-dark.focus {
  color: $color-white;
  background-color: #484848;
  border-color: #212121;
}

.btn-dark:hover {
  color: $color-white;
  background-color: #484848;
  border-color: #424242;
}

.btn-dark:active,
.btn-dark.active,
.open > .dropdown-toggle.btn-dark {
  color: $color-white;
  background-color: #484848;
  border-color: #424242;
}

.btn-dark:active:hover,
.btn-dark.active:hover,
.open > .dropdown-toggle.btn-dark:hover,
.btn-dark:active:focus,
.btn-dark.active:focus,
.open > .dropdown-toggle.btn-dark:focus,
.btn-dark:active.focus,
.btn-dark.active.focus,
.open > .dropdown-toggle.btn-dark.focus {
  color: $color-white;
  background-color: #363636;
  border-color: #212121;
}

.btn-dark:active,
.btn-dark.active,
.open > .dropdown-toggle.btn-dark {
  background-image: none;
}

.btn-dark.disabled:hover,
.btn-dark[disabled]:hover,
fieldset[disabled] .btn-dark:hover,
.btn-dark.disabled:focus,
.btn-dark[disabled]:focus,
fieldset[disabled] .btn-dark:focus,
.btn-dark.disabled.focus,
.btn-dark[disabled].focus,
fieldset[disabled] .btn-dark.focus {
  background-color: #616161;
  border-color: #616161;
}

.btn-dark .badge {
  color: #616161;
  background-color: $color-white;
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark.focus {
  background-color: #757575;
  border-color: #757575;
}

.btn-dark:active,
.btn-dark.active,
.open > .dropdown-toggle.btn-dark {
  background-color: #424242;
  border-color: #424242;
}

.btn-dark:active:hover,
.btn-dark.active:hover,
.open > .dropdown-toggle.btn-dark:hover,
.btn-dark:active:focus,
.btn-dark.active:focus,
.open > .dropdown-toggle.btn-dark:focus,
.btn-dark:active.focus,
.btn-dark.active.focus,
.open > .dropdown-toggle.btn-dark.focus {
  background-color: #424242;
  border-color: #424242;
}

.btn-dark.disabled,
.btn-dark[disabled],
fieldset[disabled] .btn-dark,
.btn-dark.disabled:hover,
.btn-dark[disabled]:hover,
fieldset[disabled] .btn-dark:hover,
.btn-dark.disabled:focus,
.btn-dark[disabled]:focus,
fieldset[disabled] .btn-dark:focus,
.btn-dark.disabled.focus,
.btn-dark[disabled].focus,
fieldset[disabled] .btn-dark.focus,
.btn-dark.disabled:active,
.btn-dark[disabled]:active,
fieldset[disabled] .btn-dark:active,
.btn-dark.disabled.active,
.btn-dark[disabled].active,
fieldset[disabled] .btn-dark.active {
  color: $color-white;
  background-color: #9e9e9e;
  border-color: #9e9e9e;
}

.btn-dark.btn-up:before {
  border-bottom-color: #616161;
}

.btn-dark.btn-up:hover:before,
.btn-dark.btn-up:focus:before {
  border-bottom-color: #757575;
}

.btn-dark.btn-up:active:before,
.btn-dark.btn-up.active:before,
.open > .dropdown-toggle.btn-dark.btn-up:before {
  border-bottom-color: #424242;
}

.btn-dark.btn-right:before {
  border-left-color: #616161;
}

.btn-dark.btn-right:hover:before,
.btn-dark.btn-right:focus:before {
  border-left-color: #757575;
}

.btn-dark.btn-right:active:before,
.btn-dark.btn-right.active:before,
.open > .dropdown-toggle.btn-dark.btn-right:before {
  border-left-color: #424242;
}

.btn-dark.btn-bottom:before {
  border-top-color: #616161;
}

.btn-dark.btn-bottom:hover:before,
.btn-dark.btn-bottom:focus:before {
  border-top-color: #757575;
}

.btn-dark.btn-bottom:active:before,
.btn-dark.btn-bottom.active:before,
.open > .dropdown-toggle.btn-dark.btn-bottom:before {
  border-top-color: #424242;
}

.btn-dark.btn-left:before {
  border-right-color: #616161;
}

.btn-dark.btn-left:hover:before,
.btn-dark.btn-left:focus:before {
  border-right-color: #757575;
}

.btn-dark.btn-left:active:before,
.btn-dark.btn-left.active:before,
.open > .dropdown-toggle.btn-dark.btn-left:before {
  border-right-color: #424242;
}

.btn-dark:hover,
.btn-dark:focus {
  color: $color-white;
}

.btn-dark:active,
.btn-dark.active,
.open > .dropdown-toggle.btn-dark {
  color: $color-white;
}

.btn-dark.btn-flat {
  color: #616161;
}

.btn-flat {
  background: none;
  border: none;
}

.btn-flat.disabled {
  color: #9e9e9e;
}

.btn-icon.disabled,
.btn.icon.disabled {
  color: #9e9e9e;
}

.btn-label {
  background-color: rgba(0, 0, 0, .15);
}

.btn-direction:before {
  border: 8px solid transparent;
}

.btn-up:before {
  border-bottom-color: #e0e0e0;
}

.btn-right:before {
  border-left-color: #e0e0e0;
}

.btn-bottom:before {
  border-top-color: #e0e0e0;
}

.btn-left:before {
  border-right-color: #e0e0e0;
}

.btn-pure,
.btn-pure:hover,
.btn-pure:focus,
.btn-pure:active,
.btn-pure.active,
.open > .dropdown-toggle.btn-pure,
.btn-pure[disabled],
fieldset[disabled] .btn-pure {
  background-color: transparent;
  border-color: transparent;
}

.btn-pure:hover,
.btn-pure:hover:hover,
.btn-pure:focus:hover,
.btn-pure:active:hover,
.btn-pure.active:hover,
.open > .dropdown-toggle.btn-pure:hover,
.btn-pure[disabled]:hover,
fieldset[disabled] .btn-pure:hover,
.btn-pure:focus,
.btn-pure:hover:focus,
.btn-pure:focus:focus,
.btn-pure:active:focus,
.btn-pure.active:focus,
.open > .dropdown-toggle.btn-pure:focus,
.btn-pure[disabled]:focus,
fieldset[disabled] .btn-pure:focus,
.btn-pure.focus,
.btn-pure:hover.focus,
.btn-pure:focus.focus,
.btn-pure:active.focus,
.btn-pure.active.focus,
.open > .dropdown-toggle.btn-pure.focus,
.btn-pure[disabled].focus,
fieldset[disabled] .btn-pure.focus {
  background-color: transparent;
  border-color: transparent;
}

.btn-pure.btn-default {
  color: #9e9e9e;
}

.btn-pure.btn-default:hover,
.btn-pure.btn-default:focus,
.btn-pure.btn-default:active,
.btn-pure.btn-default.active,
.open > .dropdown-toggle.btn-pure.btn-default {
  color: #bdbdbd;
}

.btn-pure.btn-default:hover:hover,
.btn-pure.btn-default:focus:hover,
.btn-pure.btn-default:active:hover,
.btn-pure.btn-default.active:hover,
.open > .dropdown-toggle.btn-pure.btn-default:hover,
.btn-pure.btn-default:hover:focus,
.btn-pure.btn-default:focus:focus,
.btn-pure.btn-default:active:focus,
.btn-pure.btn-default.active:focus,
.open > .dropdown-toggle.btn-pure.btn-default:focus,
.btn-pure.btn-default:hover.focus,
.btn-pure.btn-default:focus.focus,
.btn-pure.btn-default:active.focus,
.btn-pure.btn-default.active.focus,
.open > .dropdown-toggle.btn-pure.btn-default.focus {
  color: #bdbdbd;
}

.btn-pure.btn-default:hover .badge,
.btn-pure.btn-default:focus .badge,
.btn-pure.btn-default:active .badge,
.btn-pure.btn-default.active .badge,
.open > .dropdown-toggle.btn-pure.btn-default .badge {
  color: #bdbdbd;
}

.btn-pure.btn-primary {
  color: $color-primary;
}

.btn-pure.btn-primary:hover,
.btn-pure.btn-primary:focus,
.btn-pure.btn-primary:active,
.btn-pure.btn-primary.active,
.open > .dropdown-toggle.btn-pure.btn-primary {
  color: #ec407a;
}

.btn-pure.btn-primary:hover:hover,
.btn-pure.btn-primary:focus:hover,
.btn-pure.btn-primary:active:hover,
.btn-pure.btn-primary.active:hover,
.open > .dropdown-toggle.btn-pure.btn-primary:hover,
.btn-pure.btn-primary:hover:focus,
.btn-pure.btn-primary:focus:focus,
.btn-pure.btn-primary:active:focus,
.btn-pure.btn-primary.active:focus,
.open > .dropdown-toggle.btn-pure.btn-primary:focus,
.btn-pure.btn-primary:hover.focus,
.btn-pure.btn-primary:focus.focus,
.btn-pure.btn-primary:active.focus,
.btn-pure.btn-primary.active.focus,
.open > .dropdown-toggle.btn-pure.btn-primary.focus {
  color: #ec407a;
}

.btn-pure.btn-primary:hover .badge,
.btn-pure.btn-primary:focus .badge,
.btn-pure.btn-primary:active .badge,
.btn-pure.btn-primary.active .badge,
.open > .dropdown-toggle.btn-pure.btn-primary .badge {
  color: #ec407a;
}

.btn-pure.btn-success {
  color: #1B998B;
}

.btn-pure.btn-success:hover,
.btn-pure.btn-success:focus,
.btn-pure.btn-success:active,
.btn-pure.btn-success.active,
.open > .dropdown-toggle.btn-pure.btn-success {
  color: #66bb6a;
}

.btn-pure.btn-success:hover:hover,
.btn-pure.btn-success:focus:hover,
.btn-pure.btn-success:active:hover,
.btn-pure.btn-success.active:hover,
.open > .dropdown-toggle.btn-pure.btn-success:hover,
.btn-pure.btn-success:hover:focus,
.btn-pure.btn-success:focus:focus,
.btn-pure.btn-success:active:focus,
.btn-pure.btn-success.active:focus,
.open > .dropdown-toggle.btn-pure.btn-success:focus,
.btn-pure.btn-success:hover.focus,
.btn-pure.btn-success:focus.focus,
.btn-pure.btn-success:active.focus,
.btn-pure.btn-success.active.focus,
.open > .dropdown-toggle.btn-pure.btn-success.focus {
  color: #66bb6a;
}

.btn-pure.btn-success:hover .badge,
.btn-pure.btn-success:focus .badge,
.btn-pure.btn-success:active .badge,
.btn-pure.btn-success.active .badge,
.open > .dropdown-toggle.btn-pure.btn-success .badge {
  color: #66bb6a;
}

.btn-pure.btn-info {
  color: #00bcd4;
}

.btn-pure.btn-info:hover,
.btn-pure.btn-info:focus,
.btn-pure.btn-info:active,
.btn-pure.btn-info.active,
.open > .dropdown-toggle.btn-pure.btn-info {
  color: #26c6da;
}

.btn-pure.btn-info:hover:hover,
.btn-pure.btn-info:focus:hover,
.btn-pure.btn-info:active:hover,
.btn-pure.btn-info.active:hover,
.open > .dropdown-toggle.btn-pure.btn-info:hover,
.btn-pure.btn-info:hover:focus,
.btn-pure.btn-info:focus:focus,
.btn-pure.btn-info:active:focus,
.btn-pure.btn-info.active:focus,
.open > .dropdown-toggle.btn-pure.btn-info:focus,
.btn-pure.btn-info:hover.focus,
.btn-pure.btn-info:focus.focus,
.btn-pure.btn-info:active.focus,
.btn-pure.btn-info.active.focus,
.open > .dropdown-toggle.btn-pure.btn-info.focus {
  color: #26c6da;
}

.btn-pure.btn-info:hover .badge,
.btn-pure.btn-info:focus .badge,
.btn-pure.btn-info:active .badge,
.btn-pure.btn-info.active .badge,
.open > .dropdown-toggle.btn-pure.btn-info .badge {
  color: #26c6da;
}

.btn-pure.btn-warning {
  color: #FFA630;
}

.btn-pure.btn-warning:hover,
.btn-pure.btn-warning:focus,
.btn-pure.btn-warning:active,
.btn-pure.btn-warning.active,
.open > .dropdown-toggle.btn-pure.btn-warning {
  color: #ffa726;
}

.btn-pure.btn-warning:hover:hover,
.btn-pure.btn-warning:focus:hover,
.btn-pure.btn-warning:active:hover,
.btn-pure.btn-warning.active:hover,
.open > .dropdown-toggle.btn-pure.btn-warning:hover,
.btn-pure.btn-warning:hover:focus,
.btn-pure.btn-warning:focus:focus,
.btn-pure.btn-warning:active:focus,
.btn-pure.btn-warning.active:focus,
.open > .dropdown-toggle.btn-pure.btn-warning:focus,
.btn-pure.btn-warning:hover.focus,
.btn-pure.btn-warning:focus.focus,
.btn-pure.btn-warning:active.focus,
.btn-pure.btn-warning.active.focus,
.open > .dropdown-toggle.btn-pure.btn-warning.focus {
  color: #ffa726;
}

.btn-pure.btn-warning:hover .badge,
.btn-pure.btn-warning:focus .badge,
.btn-pure.btn-warning:active .badge,
.btn-pure.btn-warning.active .badge,
.open > .dropdown-toggle.btn-pure.btn-warning .badge {
  color: #ffa726;
}

.btn-pure.btn-danger {
  color: #E3170A;
}

.btn-pure.btn-danger:hover,
.btn-pure.btn-danger:focus,
.btn-pure.btn-danger:active,
.btn-pure.btn-danger.active,
.open > .dropdown-toggle.btn-pure.btn-danger {
  color: #ef5350;
}

.btn-pure.btn-danger:hover:hover,
.btn-pure.btn-danger:focus:hover,
.btn-pure.btn-danger:active:hover,
.btn-pure.btn-danger.active:hover,
.open > .dropdown-toggle.btn-pure.btn-danger:hover,
.btn-pure.btn-danger:hover:focus,
.btn-pure.btn-danger:focus:focus,
.btn-pure.btn-danger:active:focus,
.btn-pure.btn-danger.active:focus,
.open > .dropdown-toggle.btn-pure.btn-danger:focus,
.btn-pure.btn-danger:hover.focus,
.btn-pure.btn-danger:focus.focus,
.btn-pure.btn-danger:active.focus,
.btn-pure.btn-danger.active.focus,
.open > .dropdown-toggle.btn-pure.btn-danger.focus {
  color: #ef5350;
}

.btn-pure.btn-danger:hover .badge,
.btn-pure.btn-danger:focus .badge,
.btn-pure.btn-danger:active .badge,
.btn-pure.btn-danger.active .badge,
.open > .dropdown-toggle.btn-pure.btn-danger .badge {
  color: #ef5350;
}

.btn-pure.btn-dark {
  color: #616161;
}

.btn-pure.btn-dark:hover,
.btn-pure.btn-dark:focus,
.btn-pure.btn-dark:active,
.btn-pure.btn-dark.active,
.open > .dropdown-toggle.btn-pure.btn-dark {
  color: #757575;
}

.btn-pure.btn-dark:hover:hover,
.btn-pure.btn-dark:focus:hover,
.btn-pure.btn-dark:active:hover,
.btn-pure.btn-dark.active:hover,
.open > .dropdown-toggle.btn-pure.btn-dark:hover,
.btn-pure.btn-dark:hover:focus,
.btn-pure.btn-dark:focus:focus,
.btn-pure.btn-dark:active:focus,
.btn-pure.btn-dark.active:focus,
.open > .dropdown-toggle.btn-pure.btn-dark:focus,
.btn-pure.btn-dark:hover.focus,
.btn-pure.btn-dark:focus.focus,
.btn-pure.btn-dark:active.focus,
.btn-pure.btn-dark.active.focus,
.open > .dropdown-toggle.btn-pure.btn-dark.focus {
  color: #757575;
}

.btn-pure.btn-dark:hover .badge,
.btn-pure.btn-dark:focus .badge,
.btn-pure.btn-dark:active .badge,
.btn-pure.btn-dark.active .badge,
.open > .dropdown-toggle.btn-pure.btn-dark .badge {
  color: #757575;
}

.btn-pure.btn-inverse {
  color: $color-white;
}

.btn-pure.btn-inverse:hover,
.btn-pure.btn-inverse:focus,
.btn-pure.btn-inverse:active,
.btn-pure.btn-inverse.active,
.open > .dropdown-toggle.btn-pure.btn-inverse {
  color: $color-white;
}

.btn-pure.btn-inverse:hover:hover,
.btn-pure.btn-inverse:focus:hover,
.btn-pure.btn-inverse:active:hover,
.btn-pure.btn-inverse.active:hover,
.open > .dropdown-toggle.btn-pure.btn-inverse:hover,
.btn-pure.btn-inverse:hover:focus,
.btn-pure.btn-inverse:focus:focus,
.btn-pure.btn-inverse:active:focus,
.btn-pure.btn-inverse.active:focus,
.open > .dropdown-toggle.btn-pure.btn-inverse:focus,
.btn-pure.btn-inverse:hover.focus,
.btn-pure.btn-inverse:focus.focus,
.btn-pure.btn-inverse:active.focus,
.btn-pure.btn-inverse.active.focus,
.open > .dropdown-toggle.btn-pure.btn-inverse.focus {
  color: $color-white;
}

.btn-pure.btn-inverse:hover .badge,
.btn-pure.btn-inverse:focus .badge,
.btn-pure.btn-inverse:active .badge,
.btn-pure.btn-inverse.active .badge,
.open > .dropdown-toggle.btn-pure.btn-inverse .badge {
  color: $color-white;
}

.caret {
  border-top: 4px solid;
}

.dropdown-menu.bullet:before,
.dropdown-menu.bullet:after {
  border: 7px solid transparent;
}

.dropdown-menu.bullet:before {
  border-bottom-color: #e0e0e0;
}

.dropdown-menu.bullet:after {
  border-bottom-color: $color-white;
}

.dropup .dropdown-menu.bullet:before,
.navbar-fixed-bottom .dropdown .dropdown-menu.bullet:before {
  border-top-color: #e0e0e0;
}

.dropup .dropdown-menu.bullet:after,
.navbar-fixed-bottom .dropdown .dropdown-menu.bullet:after {
  border-top-color: $color-white;
}

.dropdown-menu > .dropdown-submenu > a:after {
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
}

.dropdown-menu-media .dropdown-menu-header {
  background-color: $color-white;
  border-bottom: 1px solid #e0e0e0;
}

.dropdown-menu-media .list-group-item {
  border: none;
}

.dropdown-menu-media .list-group-item .media {
  border-top: 1px solid #e0e0e0;
}

.dropdown-menu-media .list-group-item:first-child .media {
  border-top: none;
}

.dropdown-menu-media > .dropdown-menu-footer {
  background-color: #eee;
  border-top: 1px solid #e0e0e0;
}

.dropdown-menu-media > .dropdown-menu-footer > a {
  color: #9e9e9e !important;
}

.dropdown-menu-media > .dropdown-menu-footer > a:hover {
  color: $color-primary !important;
  background-color: transparent !important;
}

.dropdown-menu-media > .dropdown-menu-footer > .dropdown-menu-footer-btn:hover {
  color: $color-primary !important;
  background-color: transparent !important;
}

.dropdown-menu-primary > li > a:hover,
.dropdown-menu-primary > li > a:focus {
  color: $color-white;
  background-color: $color-primary;
}

.dropdown-menu-primary > .active > a,
.dropdown-menu-primary > .active > a:hover,
.dropdown-menu-primary > .active > a:focus {
  color: $color-white;
  background-color: $color-primary;
}

.dropdown-menu-success > li > a:hover,
.dropdown-menu-success > li > a:focus {
  color: $color-white;
  background-color: #1B998B;
}

.dropdown-menu-success > .active > a,
.dropdown-menu-success > .active > a:hover,
.dropdown-menu-success > .active > a:focus {
  color: $color-white;
  background-color: #1B998B;
}

.dropdown-menu-info > li > a:hover,
.dropdown-menu-info > li > a:focus {
  color: $color-white;
  background-color: #00bcd4;
}

.dropdown-menu-info > .active > a,
.dropdown-menu-info > .active > a:hover,
.dropdown-menu-info > .active > a:focus {
  color: $color-white;
  background-color: #00bcd4;
}

.dropdown-menu-warning > li > a:hover,
.dropdown-menu-warning > li > a:focus {
  color: $color-white;
  background-color: #FFA630;
}

.dropdown-menu-warning > .active > a,
.dropdown-menu-warning > .active > a:hover,
.dropdown-menu-warning > .active > a:focus {
  color: $color-white;
  background-color: #FFA630;
}

.dropdown-menu-danger > li > a:hover,
.dropdown-menu-danger > li > a:focus {
  color: $color-white;
  background-color: #E3170A;
}

.dropdown-menu-danger > .active > a,
.dropdown-menu-danger > .active > a:hover,
.dropdown-menu-danger > .active > a:focus {
  color: $color-white;
  background-color: #E3170A;
}

.dropdown-menu-dark > li > a:hover,
.dropdown-menu-dark > li > a:focus {
  color: $color-white;
  background-color: #616161;
}

.dropdown-menu-dark > .active > a,
.dropdown-menu-dark > .active > a:hover,
.dropdown-menu-dark > .active > a:focus {
  color: $color-white;
  background-color: #616161;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  border-color: transparent;
}

.nav-quick {
  background-color: $color-white;
}

.nav-quick a {
  color: #757575;
}

.nav-quick a:hover {
  background-color: #eee;
}

.nav-quick-bordered {
  border-top: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}

.nav-quick-bordered li {
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.nav-tabs > li > a {
  color: #757575;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: $color-white;
  background-color: $color-primary;
  border-color: transparent;
  border-bottom-color: $color-primary;
}

.nav-tabs.nav-justified > li.active > a,
.nav-tabs.nav-justified > li.active > a:hover,
.nav-tabs.nav-justified > li.active > a:focus {
  border-color: transparent;
  border-bottom-color: $color-primary;
}

.nav-tabs.nav-tabs-bottom {
  border-top: 1px solid #e0e0e0;
  border-bottom: none;
}

.nav-tabs.nav-tabs-bottom > li > a:hover,
.nav-tabs.nav-tabs-bottom > li > a:focus {
  border-top-color: #e0e0e0;
  border-bottom-color: transparent;
}

.nav-tabs.nav-tabs-bottom.nav-justified {
  border-top: none;
}

.nav-tabs.nav-tabs-bottom.nav-justified > li > a {
  border-top-color: #e0e0e0;
  border-bottom-color: transparent;
}

.nav-tabs.nav-tabs-bottom.nav-justified > li.active > a,
.nav-tabs.nav-tabs-bottom.nav-justified > li.active > a:hover,
.nav-tabs.nav-tabs-bottom.nav-justified > li.active > a:focus {
  border-top: 1px solid $color-primary;
}

.nav-tabs-solid {
  border-bottom-color: #eee;
}

.nav-tabs-solid > li > a:hover {
  border-color: transparent;
}

.nav-tabs-solid > li.active > a,
.nav-tabs-solid > li.active > a:hover,
.nav-tabs-solid > li.active > a:focus {
  color: #757575;
  background-color: #eee;
  border-color: transparent;
}

.nav-tabs-solid ~ .tab-content {
  background-color: #eee;
}

.nav-tabs-solid.nav-justified > li > a {
  border: none;
}

.nav-tabs-solid.nav-justified > li.active > a,
.nav-tabs-solid.nav-justified > li.active > a:hover,
.nav-tabs-solid.nav-justified > li.active > a:focus {
  border: none;
}

.nav-tabs-solid.nav-tabs-bottom > li.active > a,
.nav-tabs-solid.nav-tabs-bottom > li.active > a:hover,
.nav-tabs-solid.nav-tabs-bottom > li.active > a:focus {
  border: none;
}

.nav-tabs-line > li > a {
  border-bottom: 2px solid transparent;
}

.nav-tabs-line > li > a:hover,
.nav-tabs-line > li > a:focus {
  background-color: transparent;
}

.nav-tabs-line > li > a:hover {
  border-bottom-color: #bdbdbd;
}

.nav-tabs-line > li.active > a,
.nav-tabs-line > li.active > a:hover,
.nav-tabs-line > li.active > a:focus {
  color: $color-primary;
  background-color: transparent;
  border-bottom: 2px solid transparent;
}

.nav-tabs-line .open > a,
.nav-tabs-line .open > a:hover,
.nav-tabs-line .open > a:focus {
  border-color: transparent;
  border-bottom-color: #bdbdbd;
}

.nav-tabs-line > .nav-tabs-autoline {
  background-color: $color-primary;
}

.nav-tabs-line.nav-tabs-bottom > li > a {
  border-top: 2px solid transparent;
  border-bottom: none;
}

.nav-tabs-line.nav-tabs-bottom > li > a:hover {
  border-top-color: #bdbdbd;
  border-bottom-color: transparent;
}

.nav-tabs-line.nav-tabs-bottom > li.active > a,
.nav-tabs-line.nav-tabs-bottom > li.active > a:hover,
.nav-tabs-line.nav-tabs-bottom > li.active > a:focus {
  border-top: 2px solid transparent;
  border-bottom: none;
}

.nav-tabs-line.nav-justified > li > a {
  margin-bottom: -1px;
  border-bottom: 2px solid #e0e0e0;
}

.nav-tabs-line.nav-justified > li > a:hover {
  border-bottom-color: #bdbdbd;
}

.nav-tabs-line.nav-justified > li.active > a,
.nav-tabs-line.nav-justified > li.active > a:hover,
.nav-tabs-line.nav-justified > li.active > a:focus {
  border-color: transparent;
  border-bottom: 2px solid #e0e0e0;
}

.nav-tabs-line.nav-justified.nav-tabs-bottom {
  border-top: none;
}

.nav-tabs-line.nav-justified.nav-tabs-bottom > li > a {
  border-top: 2px solid #e0e0e0;
  border-bottom: none;
}

.nav-tabs-line.nav-justified.nav-tabs-bottom > li > a:hover {
  border-top-color: #bdbdbd;
}

.nav-tabs-line.nav-justified.nav-tabs-bottom > li.active > a,
.nav-tabs-line.nav-justified.nav-tabs-bottom > li.active > a:hover,
.nav-tabs-line.nav-justified.nav-tabs-bottom > li.active > a:focus {
  border-top-color: #e0e0e0;
  border-bottom: none;
}

.nav-tabs-vertical:before,
.nav-tabs-vertical:after {
  display: table;
  content: " ";
}

.nav-tabs-vertical:after {
  clear: both;
}

.nav-tabs-vertical .nav-tabs {
  border-right: 1px solid #e0e0e0;
  border-bottom: none;
}

.nav-tabs-vertical .nav-tabs > li > a {
  border-radius: 4px 0 0 4px;
}

.nav-tabs-vertical .nav-tabs > li > a:hover {
  border-right-color: #e0e0e0;
  border-bottom-color: transparent;
}

.nav-tabs-vertical .nav-tabs > li.active > a,
.nav-tabs-vertical .nav-tabs > li.active > a:hover,
.nav-tabs-vertical .nav-tabs > li.active > a:focus {
  border-right-color: $color-primary;
}

.nav-tabs-vertical .nav-tabs-reverse {
  border-right: none;
  border-left: 1px solid #e0e0e0;
}

.nav-tabs-vertical .nav-tabs-reverse > li > a {
  border-radius: 0 4px 4px 0;
}

.nav-tabs-vertical .nav-tabs-reverse > li > a:hover {
  border-right-color: transparent;
  border-left-color: #e0e0e0;
}

.nav-tabs-vertical .nav-tabs-reverse > li.active > a,
.nav-tabs-vertical .nav-tabs-reverse > li.active > a:hover,
.nav-tabs-vertical .nav-tabs-reverse > li.active > a:focus {
  border-left-color: $color-primary;
}

.nav-tabs-vertical .nav-tabs-solid {
  border-right-color: #eee;
}

.nav-tabs-vertical .nav-tabs-solid > li > a:hover {
  border-color: transparent;
}

.nav-tabs-vertical .nav-tabs-solid > li.active > a,
.nav-tabs-vertical .nav-tabs-solid > li.active > a:hover,
.nav-tabs-vertical .nav-tabs-solid > li.active > a:focus {
  border-color: transparent;
}

.nav-tabs-vertical .nav-tabs-solid.nav-tabs-reverse {
  border-left-color: #eee;
}

.nav-tabs-vertical .nav-tabs-line > li > a {
  border-right: 2px solid transparent;
  border-bottom: none;
}

.nav-tabs-vertical .nav-tabs-line > li > a:hover {
  border-right-color: #bdbdbd;
}

.nav-tabs-vertical .nav-tabs-line > li.active > a,
.nav-tabs-vertical .nav-tabs-line > li.active > a:hover,
.nav-tabs-vertical .nav-tabs-line > li.active > a:focus {
  border-right: 2px solid transparent;
  border-bottom: none;
}

.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse > li > a {
  border-right-width: 1px;
  border-left: 2px solid transparent;
}

.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse > li > a:hover {
  border-color: transparent;
  border-left-color: #bdbdbd;
}

.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse > li.active > a,
.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse > li.active > a:hover,
.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse > li.active > a:focus {
  border-right: 1px solid transparent;
  border-left: 2px solid transparent;
}

.nav-tabs-inverse .nav-tabs-solid {
  border-bottom-color: $color-white;
}

.nav-tabs-inverse .nav-tabs-solid > li.active > a,
.nav-tabs-inverse .nav-tabs-solid > li.active > a:hover,
.nav-tabs-inverse .nav-tabs-solid > li.active > a:focus {
  color: #757575;
  background-color: $color-white;
}

.nav-tabs-inverse.nav-tabs-vertical .nav-tabs-solid {
  border-right-color: $color-white;
}

.nav-tabs-inverse.nav-tabs-vertical .nav-tabs-solid.nav-tabs-reverse {
  border-left-color: $color-white;
}

.nav-tabs-inverse .tab-content {
  background: $color-white;
}

.navbar-toggle {
  background: transparent !important;
}

.navbar-toggle:hover {
  background: transparent !important;
}

.navbar {
  border: none;
}

.navbar-form .icon {
  color: rgba(66, 66, 66, .4);
}

.navbar-form .form-control {
  background-color: #eee;
  border: none;
}

@media (max-width: 767px) {
  .navbar-search .navbar-form {
    border-bottom: none;
  }
}

.navbar-search-overlap {
  background-color: $color-white;
}

.navbar-search-overlap .form-control {
  background-color: transparent !important;
}

.navbar-search-overlap .form-control:focus {
  border-color: transparent;
}

.navbar-default .navbar-toolbar > li > a {
  color: #757575;
}

.navbar-default .navbar-toolbar > li > a:hover,
.navbar-default .navbar-toolbar > li > a:focus {
  color: #616161;
  background-color: rgba(238, 238, 238, .3);
}

.navbar-default .navbar-toolbar > .active > a,
.navbar-default .navbar-toolbar > .active > a:hover,
.navbar-default .navbar-toolbar > .active > a:focus {
  color: #616161;
  background-color: rgba(238, 238, 238, .6);
}

.navbar-default .navbar-toolbar > .disabled > a,
.navbar-default .navbar-toolbar > .disabled > a:hover,
.navbar-default .navbar-toolbar > .disabled > a:focus {
  color: #bdbdbd;
  background-color: transparent;
}

.navbar-default .navbar-toggle {
  color: #757575;
}

.navbar-default .navbar-toolbar > .open > a,
.navbar-default .navbar-toolbar > .open > a:hover,
.navbar-default .navbar-toolbar > .open > a:focus {
  color: #616161;
  background-color: rgba(238, 238, 238, .6);
}

.navbar-inverse .navbar-toolbar > li > a {
  color: $color-white;
}

.navbar-inverse .navbar-toolbar > li > a:hover,
.navbar-inverse .navbar-toolbar > li > a:focus {
  color: $color-white;
  background-color: rgba(0, 0, 0, .1);
}

.navbar-inverse .navbar-toolbar > .active > a,
.navbar-inverse .navbar-toolbar > .active > a:hover,
.navbar-inverse .navbar-toolbar > .active > a:focus {
  color: $color-white;
  background-color: rgba(0, 0, 0, .1);
}

.navbar-inverse .navbar-toolbar > .disabled > a,
.navbar-inverse .navbar-toolbar > .disabled > a:hover,
.navbar-inverse .navbar-toolbar > .disabled > a:focus {
  color: $color-white;
  background-color: transparent;
}

.navbar-inverse .navbar-toggle {
  color: $color-white;
}

.navbar-inverse .navbar-toolbar > .open > a,
.navbar-inverse .navbar-toolbar > .open > a:hover,
.navbar-inverse .navbar-toolbar > .open > a:focus {
  color: $color-white;
  background-color: rgba(0, 0, 0, .1);
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #bdbdbd;
  background-color: transparent;
  border-color: #e0e0e0;
}

.pagination-gap > li > a:hover {
  background-color: transparent;
  border-color: $color-primary;
}

.pagination-no-border > li > a {
  border: none;
}

.pager li > a,
.pager li > span {
  color: #757575;
}

.pager li > a:hover,
.pager li > a:focus {
  color: $color-primary;
}

.pager li > a:hover,
.pager li > a:focus {
  border-color: $color-primary;
}

.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span {
  border-color: #e0e0e0;
}

.label.label-outline {
  color: #eee;
  background-color: transparent;
  border-color: #eee;
}

.label-outline {
  border: 1px solid transparent;
}

.label-default {
  color: #757575;
  background-color: #e0e0e0;
}

.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #eee;
}

.label-default.label-outline {
  color: #e0e0e0;
  background-color: transparent;
  border-color: #e0e0e0;
}

.label-default[href]:hover,
.label-default[href]:focus {
  color: #9e9e9e;
}

.label-default.label-outline {
  color: #757575;
}

.label-primary {
  background-color: $color-primary;
}

.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #ec407a;
}

.label-primary.label-outline {
  color: $color-primary;
  background-color: transparent;
  border-color: $color-primary;
}

.label-success {
  background-color: #1B998B;
}

.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #66bb6a;
}

.label-success.label-outline {
  color: #1B998B;
  background-color: transparent;
  border-color: #1B998B;
}

.label-info {
  background-color: #00bcd4;
}

.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #26c6da;
}

.label-info.label-outline {
  color: #00bcd4;
  background-color: transparent;
  border-color: #00bcd4;
}

.label-warning {
  background-color: #FFA630;
}

.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #ffa726;
}

.label-warning.label-outline {
  color: #FFA630;
  background-color: transparent;
  border-color: #FFA630;
}

.label-danger {
  background-color: #E3170A;
}

.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #ef5350;
}

.label-danger.label-outline {
  color: #E3170A;
  background-color: transparent;
  border-color: #E3170A;
}

.label-dark {
  background-color: #616161;
}

.label-dark[href]:hover,
.label-dark[href]:focus {
  background-color: #757575;
}

.label-dark.label-outline {
  color: #616161;
  background-color: transparent;
  border-color: #616161;
}

.badge-primary {
  color: $color-white;
  background-color: $color-primary;
}

.badge-primary[href]:hover,
.badge-primary[href]:focus {
  color: $color-white;
  background-color: #c1134e;
}

.list-group-item.active > .badge-primary,
.nav-pills > .active > a > .badge-primary {
  color: $color-white;
  background-color: $color-primary;
}

.badge-success {
  color: $color-white;
  background-color: #1B998B;
}

.badge-success[href]:hover,
.badge-success[href]:focus {
  color: $color-white;
  background-color: #3d8b40;
}

.list-group-item.active > .badge-success,
.nav-pills > .active > a > .badge-success {
  color: $color-white;
  background-color: #1B998B;
}

.badge-info {
  color: $color-white;
  background-color: #00bcd4;
}

.badge-info[href]:hover,
.badge-info[href]:focus {
  color: $color-white;
  background-color: #008fa1;
}

.list-group-item.active > .badge-info,
.nav-pills > .active > a > .badge-info {
  color: $color-white;
  background-color: #00bcd4;
}

.badge-warning {
  color: $color-white;
  background-color: #FFA630;
}

.badge-warning[href]:hover,
.badge-warning[href]:focus {
  color: $color-white;
  background-color: #cc7a00;
}

.list-group-item.active > .badge-warning,
.nav-pills > .active > a > .badge-warning {
  color: $color-white;
  background-color: #FFA630;
}

.badge-danger {
  color: $color-white;
  background-color: #E3170A;
}

.badge-danger[href]:hover,
.badge-danger[href]:focus {
  color: $color-white;
  background-color: #ea1c0d;
}

.list-group-item.active > .badge-danger,
.nav-pills > .active > a > .badge-danger {
  color: $color-white;
  background-color: #E3170A;
}

.badge-dark {
  color: $color-white;
  background-color: #616161;
}

.badge-dark[href]:hover,
.badge-dark[href]:focus {
  color: $color-white;
  background-color: #484848;
}

.list-group-item.active > .badge-dark,
.nav-pills > .active > a > .badge-dark {
  color: $color-white;
  background-color: #616161;
}

.thumbnail {
  border: none;
}

.alert-alt {
  color: #757575;
  background-color: rgba(238, 238, 238, .8);
  border: none;
  border-left: 3px solid transparent;
}

.alert-dismissible.alert-alt .close {
  color: #9e9e9e;
}

.alert-dismissible.alert-alt .close:hover,
.alert-dismissible.alert-alt .close:focus {
  color: #9e9e9e;
}

.alert-primary {
  color: $color-primary;
  background-color: rgba(248, 187, 208, .8);
  border-color: #f8bbd0;
}

.alert-primary hr {
  border-top-color: #f6a4c0;
}

.alert-primary .alert-link {
  color: #c1134e;
}

.alert-primary .close {
  color: $color-primary;
}

.alert-primary .close:hover,
.alert-primary .close:focus {
  color: $color-primary;
}

.alert-primary .alert-link {
  color: #e14365;
}

.alert-alt.alert-primary {
  border-color: $color-primary;
}

.alert-alt.alert-primary a,
.alert-alt.alert-primary .alert-link {
  color: $color-primary;
}

.alert-success .alert-link {
  color: #43a047;
}

.alert-alt.alert-success {
  border-color: #1B998B;
}

.alert-alt.alert-success a,
.alert-alt.alert-success .alert-link {
  color: #1B998B;
}

.alert-info .alert-link {
  color: #00acc1;
}

.alert-alt.alert-info {
  border-color: #00bcd4;
}

.alert-alt.alert-info a,
.alert-alt.alert-info .alert-link {
  color: #00bcd4;
}

.alert-warning .alert-link {
  color: #fb8c00;
}

.alert-alt.alert-warning {
  border-color: #FFA630;
}

.alert-alt.alert-warning a,
.alert-alt.alert-warning .alert-link {
  color: #FFA630;
}

.alert-danger .alert-link {
  color: #e53935;
}

.alert-alt.alert-danger {
  border-color: #E3170A;
}

.alert-alt.alert-danger a,
.alert-alt.alert-danger .alert-link {
  color: #E3170A;
}

.alert-facebook {
  color: $color-white;
  background-color: #3b5998;
  border-color: #3b5998;
}

.alert-facebook hr {
  border-top-color: #344e86;
}

.alert-facebook .alert-link {
  color: #e6e6e6;
}

.alert-facebook .close {
  color: $color-white;
}

.alert-facebook .close:hover,
.alert-facebook .close:focus {
  color: $color-white;
}

.alert-facebook .alert-link {
  color: $color-white;
}

.alert-twitter {
  color: $color-white;
  background-color: #55acee;
  border-color: #55acee;
}

.alert-twitter hr {
  border-top-color: #3ea1ec;
}

.alert-twitter .alert-link {
  color: #e6e6e6;
}

.alert-twitter .close {
  color: $color-white;
}

.alert-twitter .close:hover,
.alert-twitter .close:focus {
  color: $color-white;
}

.alert-twitter .alert-link {
  color: $color-white;
}

.alert-google-plus {
  color: $color-white;
  background-color: #dd4b39;
  border-color: #dd4b39;
}

.alert-google-plus hr {
  border-top-color: #d73925;
}

.alert-google-plus .alert-link {
  color: #e6e6e6;
}

.alert-google-plus .close {
  color: $color-white;
}

.alert-google-plus .close:hover,
.alert-google-plus .close:focus {
  color: $color-white;
}

.alert-google-plus .alert-link {
  color: $color-white;
}

.alert-linkedin {
  color: $color-white;
  background-color: #0976b4;
  border-color: #0976b4;
}

.alert-linkedin hr {
  border-top-color: #08669c;
}

.alert-linkedin .alert-link {
  color: #e6e6e6;
}

.alert-linkedin .close {
  color: $color-white;
}

.alert-linkedin .close:hover,
.alert-linkedin .close:focus {
  color: $color-white;
}

.alert-linkedin .alert-link {
  color: $color-white;
}

.alert-flickr {
  color: $color-white;
  background-color: #ff0084;
  border-color: #ff0084;
}

.alert-flickr hr {
  border-top-color: #e60077;
}

.alert-flickr .alert-link {
  color: #e6e6e6;
}

.alert-flickr .close {
  color: $color-white;
}

.alert-flickr .close:hover,
.alert-flickr .close:focus {
  color: $color-white;
}

.alert-flickr .alert-link {
  color: $color-white;
}

.alert-tumblr {
  color: $color-white;
  background-color: #35465c;
  border-color: #35465c;
}

.alert-tumblr hr {
  border-top-color: #2c3a4c;
}

.alert-tumblr .alert-link {
  color: #e6e6e6;
}

.alert-tumblr .close {
  color: $color-white;
}

.alert-tumblr .close:hover,
.alert-tumblr .close:focus {
  color: $color-white;
}

.alert-tumblr .alert-link {
  color: $color-white;
}

.alert-github {
  color: $color-white;
  background-color: #4183c4;
  border-color: #4183c4;
}

.alert-github hr {
  border-top-color: #3876b4;
}

.alert-github .alert-link {
  color: #e6e6e6;
}

.alert-github .close {
  color: $color-white;
}

.alert-github .close:hover,
.alert-github .close:focus {
  color: $color-white;
}

.alert-github .alert-link {
  color: $color-white;
}

.alert-dribbble {
  color: $color-white;
  background-color: #c32361;
  border-color: #c32361;
}

.alert-dribbble hr {
  border-top-color: #ad1f56;
}

.alert-dribbble .alert-link {
  color: #e6e6e6;
}

.alert-dribbble .close {
  color: $color-white;
}

.alert-dribbble .close:hover,
.alert-dribbble .close:focus {
  color: $color-white;
}

.alert-dribbble .alert-link {
  color: $color-white;
}

.alert-youtube {
  color: $color-white;
  background-color: #b31217;
  border-color: #b31217;
}

.alert-youtube hr {
  border-top-color: #9c1014;
}

.alert-youtube .alert-link {
  color: #e6e6e6;
}

.alert-youtube .close {
  color: $color-white;
}

.alert-youtube .close:hover,
.alert-youtube .close:focus {
  color: $color-white;
}

.alert-youtube .alert-link {
  color: $color-white;
}

.alert.dark .alert-link {
  color: $color-white !important;
}

.alert.dark .alert-left-border {
  border: none;
}

.alert.dark.alert-dismissible.alert-alt .close {
  color: $color-white;
}

.alert.dark.alert-dismissible.alert-alt .close:hover,
.alert.dark.alert-dismissible.alert-alt .close:focus {
  color: $color-white;
}

.alert.dark.alert-primary {
  color: $color-white;
  background-color: $color-primary;
  border-color: $color-primary;
}

.alert.dark.alert-primary hr {
  border-top-color: #d81557;
}

.alert.dark.alert-primary .alert-link {
  color: #e6e6e6;
}

.alert.dark.alert-primary .close {
  color: $color-white;
}

.alert.dark.alert-primary .close:hover,
.alert.dark.alert-primary .close:focus {
  color: $color-white;
}

.alert-alt.alert.dark.alert-primary {
  border-color: #941242;
}

.alert-alt.alert.dark.alert-primary a,
.alert-alt.alert.dark.alert-primary .alert-link {
  color: $color-white;
}

.alert.dark.alert-success {
  color: $color-white;
  background-color: #1B998B;
  border-color: #1B998B;
}

.alert.dark.alert-success hr {
  border-top-color: #449d48;
}

.alert.dark.alert-success .alert-link {
  color: #e6e6e6;
}

.alert.dark.alert-success .close {
  color: $color-white;
}

.alert.dark.alert-success .close:hover,
.alert.dark.alert-success .close:focus {
  color: $color-white;
}

.alert-alt.alert.dark.alert-success {
  border-color: #2c6a2f;
}

.alert-alt.alert.dark.alert-success a,
.alert-alt.alert.dark.alert-success .alert-link {
  color: $color-white;
}

.alert.dark.alert-info {
  color: $color-white;
  background-color: #00bcd4;
  border-color: #00bcd4;
}

.alert.dark.alert-info hr {
  border-top-color: #00a5bb;
}

.alert.dark.alert-info .alert-link {
  color: #e6e6e6;
}

.alert.dark.alert-info .close {
  color: $color-white;
}

.alert.dark.alert-info .close:hover,
.alert.dark.alert-info .close:focus {
  color: $color-white;
}

.alert-alt.alert.dark.alert-info {
  border-color: #006875;
}

.alert-alt.alert.dark.alert-info a,
.alert-alt.alert.dark.alert-info .alert-link {
  color: $color-white;
}

.alert.dark.alert-warning {
  color: $color-white;
  background-color: #FFA630;
  border-color: #FFA630;
}

.alert.dark.alert-warning hr {
  border-top-color: #e68900;
}

.alert.dark.alert-warning .alert-link {
  color: #e6e6e6;
}

.alert.dark.alert-warning .close {
  color: $color-white;
}

.alert.dark.alert-warning .close:hover,
.alert.dark.alert-warning .close:focus {
  color: $color-white;
}

.alert-alt.alert.dark.alert-warning {
  border-color: #af6100;
}

.alert-alt.alert.dark.alert-warning a,
.alert-alt.alert.dark.alert-warning .alert-link {
  color: $color-white;
}

.alert.dark.alert-danger {
  color: $color-white;
  background-color: #E3170A;
  border-color: #E3170A;
}

.alert.dark.alert-danger hr {
  border-top-color: #f32c1e;
}

.alert.dark.alert-danger .alert-link {
  color: #e6e6e6;
}

.alert.dark.alert-danger .close {
  color: $color-white;
}

.alert.dark.alert-danger .close:hover,
.alert.dark.alert-danger .close:focus {
  color: $color-white;
}

.alert-alt.alert.dark.alert-danger {
  border-color: #b61b17;
}

.alert-alt.alert.dark.alert-danger a,
.alert-alt.alert.dark.alert-danger .alert-link {
  color: $color-white;
}

.progress-bar-indicating.active:before {
  background-color: $color-white;
}

.progress-skill .progress-bar > span {
  color: #616161;
}

.media .media {
  border-bottom: none;
}

.media-meta {
  color: #616161;
}

a.list-group-item.disabled,
a.list-group-item.disabled:hover,
a.list-group-item.disabled:focus {
  color: #bdbdbd;
  background-color: #eee;
}

a.list-group-item.active,
a.list-group-item.active:hover,
a.list-group-item.active:focus {
  color: $color-white;
  background-color: $color-primary;
}

.list-group.bg-inherit .list-group-item {
  background-color: transparent;
  border-bottom-color: rgba(0, 0, 0, .075);
}

.list-group.bg-inherit .list-group-item:last-child {
  border-bottom-color: transparent;
}

.list-group.bg-inherit .list-group-item:hover {
  background-color: rgba(0, 0, 0, .075);
  border-color: transparent;
}

.list-group-bordered {
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .1);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .1);
}

.list-group-bordered .list-group-item {
  border-color: #e0e0e0;
}

.list-group-bordered .list-group-item.active,
.list-group-bordered .list-group-item.active:hover,
.list-group-bordered .list-group-item.active:focus {
  color: $color-white;
  background-color: #c2185b;
  border-color: #c2185b;
}

.list-group-dividered .list-group-item {
  border-top-color: #e0e0e0;
}

.list-group-dividered .list-group-item.active:hover {
  border-top-color: #e0e0e0;
}

.list-group-dividered .list-group-item:last-child {
  border-bottom-color: #e0e0e0;
}

.list-group-dividered .list-group-item:first-child {
  border-top-color: transparent;
}

.list-group-dividered .list-group-item:first-child.active:hover {
  border-top-color: transparent;
}

.list-group-item-dark {
  color: $color-white;
  background-color: #616161;
}

a.list-group-item-dark,
button.list-group-item-dark {
  color: $color-white;
}

a.list-group-item-dark .list-group-item-heading,
button.list-group-item-dark .list-group-item-heading {
  color: inherit;
}

a.list-group-item-dark:hover,
button.list-group-item-dark:hover,
a.list-group-item-dark:focus,
button.list-group-item-dark:focus {
  color: $color-white;
  background-color: #545454;
}

a.list-group-item-dark.active,
button.list-group-item-dark.active,
a.list-group-item-dark.active:hover,
button.list-group-item-dark.active:hover,
a.list-group-item-dark.active:focus,
button.list-group-item-dark.active:focus {
  color: $color-white;
  background-color: $color-white;
  border-color: $color-white;
}

.panel > .nav-tabs-vertical .nav-tabs > li > a {
  border-left: none;
}

.panel > .nav-tabs-vertical .nav-tabs.nav-tabs-reverse {
  margin-right: -1px;
}

.panel > .nav-tabs-vertical .nav-tabs.nav-tabs-reverse > li > a {
  border-right: none;
}

.panel > .table > tbody:first-child > tr:first-child th,
.panel > .table > tbody:first-child > tr:first-child td {
  border-top: 1px solid #e0e0e0;
}

.panel-heading {
  border-bottom: 1px solid transparent;
}

.panel-heading > .nav-tabs {
  border-bottom: none;
}

.panel-body > .list-group-dividered:only-child > .list-group-item:last-child {
  border-bottom-color: transparent;
}

.panel-footer {
  border-top: 1px solid transparent;
}

.table + .panel-footer {
  border-color: #e0e0e0;
}

.panel-title {
  color: #424242;
}

.panel-title small {
  color: #757575;
}

.panel-desc {
  color: #757575;
}

.panel-actions a {
  color: inherit;
}

.panel-actions .panel-action {
  color: #9e9e9e;
  background-color: transparent;
}

.panel-actions .panel-action:hover {
  color: #616161;
}

.panel-actions .panel-action:active {
  color: #616161;
}

.panel-toolbar {
  background-color: transparent;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.panel-bordered .panel-toolbar {
  border-top-color: transparent;
}

.panel-toolbar .btn {
  color: #9e9e9e;
}

.panel-toolbar .btn:hover,
.panel-toolbar .btn:active,
.panel-toolbar .btn.active {
  color: #757575;
}

.panel-control {
  border: none;
}

.panel-bordered > .panel-heading {
  border-bottom: 1px solid #e0e0e0;
}

.panel-bordered > .panel-footer {
  border-top: 1px solid #e0e0e0;
}

.panel-bordered > .table > tbody:first-child > tr:first-child th,
.panel-bordered > .table > tbody:first-child > tr:first-child td {
  border-top: 0;
}

.panel.panel-transparent {
  background: transparent;
  border-color: transparent;
}

.panel.panel-transparent > .panel-heading,
.panel.panel-transparent > .panel-footer {
  border-color: transparent;
}

.panel-dark {
  border-color: #616161;
}

.panel-dark > .panel-heading {
  color: $color-white;
  background-color: #616161;
  border-color: #616161;
}

.panel-dark > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #616161;
}

.panel-dark > .panel-heading .badge {
  color: #616161;
  background-color: $color-white;
}

.panel-dark > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #616161;
}

.panel-primary,
.panel-info,
.panel-success,
.panel-warning,
.panel-danger,
.panel-dark {
  border: none;
}

.panel-primary .panel-heading,
.panel-info .panel-heading,
.panel-success .panel-heading,
.panel-warning .panel-heading,
.panel-danger .panel-heading,
.panel-dark .panel-heading {
  border: none;
}

.panel-primary .panel-title,
.panel-info .panel-title,
.panel-success .panel-title,
.panel-warning .panel-title,
.panel-danger .panel-title,
.panel-dark .panel-title {
  color: $color-white;
}

.panel-primary .panel-action,
.panel-info .panel-action,
.panel-success .panel-action,
.panel-warning .panel-action,
.panel-danger .panel-action,
.panel-dark .panel-action {
  color: $color-white;
}

.well-primary {
  color: $color-white;
  background-color: $color-primary;
}

.well-success {
  color: $color-white;
  background-color: #1B998B;
}

.well-info {
  color: $color-white;
  background-color: #00bcd4;
}

.well-warning {
  color: $color-white;
  background-color: #FFA630;
}

.well-danger {
  color: $color-white;
  background-color: #E3170A;
}

code {
  border: 1px solid #f8bbd0;
}

.modal-content {
  border: none;
}

.modal-header {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}

.modal-sidebar {
  background-color: $color-white;
}

.modal-sidebar .modal-content {
  background-color: transparent;
}

.modal-sidebar .modal-header {
  border-bottom: none;
}

.modal-sidebar .modal-footer {
  border-top: none;
}

.modal-fill-in {
  background-color: transparent;
}

.modal-fill-in.in {
  background-color: rgba(255, 255, 255, .95);
}

.modal-fill-in .modal-content {
  background-color: transparent;
}

.modal-fill-in .modal-header {
  border-bottom: none;
}

.modal-fill-in .modal-footer {
  border-top: none;
}

.modal-primary .modal-header {
  background-color: $color-primary;
}

.modal-primary .modal-header * {
  color: $color-white;
}

.modal-success .modal-header {
  background-color: #1B998B;
}

.modal-success .modal-header * {
  color: $color-white;
}

.modal-info .modal-header {
  background-color: #00bcd4;
}

.modal-info .modal-header * {
  color: $color-white;
}

.modal-warning .modal-header {
  background-color: #FFA630;
}

.modal-warning .modal-header * {
  color: $color-white;
}

.modal-danger .modal-header {
  background-color: #E3170A;
}

.modal-danger .modal-header * {
  color: $color-white;
}

.tooltip-primary + .tooltip .tooltip-inner {
  color: $color-white;
  background-color: $color-primary;
}

.tooltip-primary + .tooltip.top .tooltip-arrow {
  border-top-color: $color-primary;
}

.tooltip-primary + .tooltip.right .tooltip-arrow {
  border-right-color: $color-primary;
}

.tooltip-primary + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: $color-primary;
}

.tooltip-primary + .tooltip.left .tooltip-arrow {
  border-left-color: $color-primary;
}

.tooltip-success + .tooltip .tooltip-inner {
  color: $color-white;
  background-color: #1B998B;
}

.tooltip-success + .tooltip.top .tooltip-arrow {
  border-top-color: #1B998B;
}

.tooltip-success + .tooltip.right .tooltip-arrow {
  border-right-color: #1B998B;
}

.tooltip-success + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #1B998B;
}

.tooltip-success + .tooltip.left .tooltip-arrow {
  border-left-color: #1B998B;
}

.tooltip-info + .tooltip .tooltip-inner {
  color: $color-white;
  background-color: #00bcd4;
}

.tooltip-info + .tooltip.top .tooltip-arrow {
  border-top-color: #00bcd4;
}

.tooltip-info + .tooltip.right .tooltip-arrow {
  border-right-color: #00bcd4;
}

.tooltip-info + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #00bcd4;
}

.tooltip-info + .tooltip.left .tooltip-arrow {
  border-left-color: #00bcd4;
}

.tooltip-warning + .tooltip .tooltip-inner {
  color: $color-white;
  background-color: #FFA630;
}

.tooltip-warning + .tooltip.top .tooltip-arrow {
  border-top-color: #FFA630;
}

.tooltip-warning + .tooltip.right .tooltip-arrow {
  border-right-color: #FFA630;
}

.tooltip-warning + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #FFA630;
}

.tooltip-warning + .tooltip.left .tooltip-arrow {
  border-left-color: #FFA630;
}

.tooltip-danger + .tooltip .tooltip-inner {
  color: $color-white;
  background-color: #E3170A;
}

.tooltip-danger + .tooltip.top .tooltip-arrow {
  border-top-color: #E3170A;
}

.tooltip-danger + .tooltip.right .tooltip-arrow {
  border-right-color: #E3170A;
}

.tooltip-danger + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #E3170A;
}

.tooltip-danger + .tooltip.left .tooltip-arrow {
  border-left-color: #E3170A;
}

.popover.bottom > .arrow:after {
  border-bottom-color: #eee;
}

.popover-primary + .popover .popover-title {
  color: $color-white;
  background-color: $color-primary;
  border-color: $color-primary;
}

.popover-primary + .popover.bottom .arrow {
  border-bottom-color: $color-primary;
}

.popover-primary + .popover.bottom .arrow:after {
  border-bottom-color: $color-primary;
}

.popover-success + .popover .popover-title {
  color: $color-white;
  background-color: #1B998B;
  border-color: #1B998B;
}

.popover-success + .popover.bottom .arrow {
  border-bottom-color: #1B998B;
}

.popover-success + .popover.bottom .arrow:after {
  border-bottom-color: #1B998B;
}

.popover-info + .popover .popover-title {
  color: $color-white;
  background-color: #00bcd4;
  border-color: #00bcd4;
}

.popover-info + .popover.bottom .arrow {
  border-bottom-color: #00bcd4;
}

.popover-info + .popover.bottom .arrow:after {
  border-bottom-color: #00bcd4;
}

.popover-warning + .popover .popover-title {
  color: $color-white;
  background-color: #FFA630;
  border-color: #FFA630;
}

.popover-warning + .popover.bottom .arrow {
  border-bottom-color: #FFA630;
}

.popover-warning + .popover.bottom .arrow:after {
  border-bottom-color: #FFA630;
}

.popover-danger + .popover .popover-title {
  color: $color-white;
  background-color: #E3170A;
  border-color: #E3170A;
}

.popover-danger + .popover.bottom .arrow {
  border-bottom-color: #E3170A;
}

.popover-danger + .popover.bottom .arrow:after {
  border-bottom-color: #E3170A;
}

.carousel-indicators li {
  background-color: rgba(255, 255, 255, .3);
  border: none;
}

.carousel-indicators-scaleup li {
  border: none;
}

.carousel-indicators-fillin li {
  background-color: transparent;
}

.carousel-indicators-fall li:after {
  background-color: rgba(0, 0, 0, .3);
}

.carousel-indicators-fall .active {
  background-color: transparent;
}

.site-navbar {
  background-color: $color-primary;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.site-navbar .navbar-header {
  color: $color-white;
  background-color: transparent;
}

.site-navbar .navbar-header .navbar-toggle {
  color: $color-white;
}

.site-navbar .navbar-header .hamburger:before,
.site-navbar .navbar-header .hamburger:after,
.site-navbar .navbar-header .hamburger .hamburger-bar {
  background-color: $color-white;
}

.site-navbar .navbar-header .navbar-brand {
  color: $color-white;
}

.site-navbar .navbar-container {
  background-color: $color-white;
}

.site-navbar.navbar-inverse .navbar-container {
  background-color: transparent;
}

.site-menubar {
  color: rgba(117, 117, 117, .9);
  background: $color-white;
}

.site-menubar.site-menubar-dark {
  background: #212121;
}

.site-menubar.site-menubar-dark .scrollable-inverse.scrollable .scrollable-bar-handle {
  background: rgba(158, 158, 158, .6);
}

.site-menubar-section > h4,
.site-menubar-section > h5 {
  color: #757575;
}

.site-menubar-footer > a {
  color: #757575;
  background-color: #e0e0e0;
}

.site-menubar-footer > a:hover,
.site-menubar-footer > a:focus {
  background-color: #d3d3d3;
}

.site-menubar-dark .site-menubar-footer > a {
  background-color: #21292e;
}

.site-menubar-dark .site-menubar-footer > a:hover,
.site-menubar-dark .site-menubar-footer > a:focus {
  background-color: #1e2427;
}

.site-menu-item a {
  color: rgba(117, 117, 117, .9);
}

.site-menu > .site-menu-item.open {
  background: rgba(33, 33, 33, .05);
}

.site-menu > .site-menu-item.open > a {
  color: #424242;
  background: transparent;
}

.site-menu > .site-menu-item.open.hover > a {
  background: transparent;
}

.site-menu > .site-menu-item.hover > a,
.site-menu > .site-menu-item:hover > a {
  color: #424242;
  background-color: rgba(33, 33, 33, .05);
}

.site-menu > .site-menu-item.active {
  background: rgba(33, 33, 33, .05);
  /*border-top: 1px solid rgba(0, 0, 0, .04);*/
  /*border-bottom: 1px solid rgba(0, 0, 0, .04);*/
}

.site-menu > .site-menu-item.active > a {
  color: #424242;
  background: transparent;
}

.site-menu > .site-menu-item.active.hover > a {
  background: transparent;
}

.site-menu .site-menu-sub {
  background: transparent;
}

.site-menu .site-menu-sub .site-menu-item {
  color: rgba(117, 117, 117, .9);
  background: transparent;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.site-menu .site-menu-sub .site-menu-item.has-sub {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.site-menu .site-menu-sub .site-menu-item.open {
  background: rgba(33, 33, 33, .05);
}

.site-menu .site-menu-sub .site-menu-item.open > a {
  color: #424242;
}

.site-menu .site-menu-sub .site-menu-item.open.hover > a {
  background-color: transparent;
}

.site-menu .site-menu-sub .site-menu-item.hover > a,
.site-menu .site-menu-sub .site-menu-item:hover > a {
  color: #424242;
  background-color: rgba(255, 255, 255, .02);
}

.site-menu .site-menu-sub .site-menu-item.active {
  background: rgba(33, 33, 33, .05);
}

.site-menu .site-menu-sub .site-menu-item.active > a {
  color: #424242;
}

.site-menu .site-menu-sub .site-menu-item.active.hover > a {
  background-color: transparent;
}

.site-menubar-dark .site-menu-item a {
  color: rgba(158, 158, 158, .9);
}

.site-menubar-dark .site-menu-item.hover > a,
.site-menubar-dark .site-menu-item:hover > a {
  background: transparent;
}

.site-menubar-dark .site-menu > .site-menu-item.open {
  background: #1e1e1e;
}

.site-menubar-dark .site-menu > .site-menu-item.open > a {
  color: $color-white;
}

.site-menubar-dark .site-menu > .site-menu-item.hover,
.site-menubar-dark .site-menu > .site-menu-item:hover {
  background-color: rgba(255, 255, 255, .02);
}

.site-menubar-dark .site-menu > .site-menu-item.hover > a,
.site-menubar-dark .site-menu > .site-menu-item:hover > a {
  color: rgba(255, 255, 255, .8);
}

.site-menubar-dark .site-menu > .site-menu-item.active {
  background: #1e1e1e;
}

.site-menubar-dark .site-menu > .site-menu-item.active > a {
  color: $color-white;
}

.site-menubar-dark .site-menu .site-menu-sub .site-menu-item.open {
  background: rgba(0, 0, 0, .06);
}

.site-menubar-dark .site-menu .site-menu-sub .site-menu-item.open > a {
  color: $color-white;
}

.site-menubar-dark .site-menu .site-menu-sub .site-menu-item.hover,
.site-menubar-dark .site-menu .site-menu-sub .site-menu-item:hover {
  background-color: rgba(0, 0, 0, .06);
}

.site-menubar-dark .site-menu .site-menu-sub .site-menu-item.hover > a,
.site-menubar-dark .site-menu .site-menu-sub .site-menu-item:hover > a {
  color: rgba(255, 255, 255, .8);
}

.site-menubar-dark .site-menu .site-menu-sub .site-menu-item.hover.open,
.site-menubar-dark .site-menu .site-menu-sub .site-menu-item:hover.open {
  background-color: rgba(0, 0, 0, .06);
}

.site-menubar-dark .site-menu .site-menu-sub .site-menu-item.active {
  background: rgba(0, 0, 0, .06);
}

.site-menubar-dark .site-menu .site-menu-sub .site-menu-item.active > a {
  color: $color-white;
}

.site-gridmenu {
  background-color: #212121;
}

.site-gridmenu li > a {
  color: #9e9e9e;
}

.site-gridmenu li:hover > a {
  color: $color-white;
  background-color: rgba(255, 255, 255, .02);
}

.site-sidebar .conversation {
  background-color: $color-white;
}

.site-sidebar .conversation-return {
  color: rgba(66, 66, 66, .4);
}

.site-sidebar .conversation-return:hover,
.site-sidebar .conversation-return:focus {
  color: rgba(66, 66, 66, .6);
}

.site-sidebar .conversation-return:active {
  color: #424242;
}

.site-sidebar .conversation-title {
  color: #424242;
}

.site-sidebar .conversation-reply {
  border-top: 1px solid #e0e0e0;
}

.site-sidebar .conversation-reply .form-control {
  border: none;
  border-right: 1px solid #e0e0e0;
}

.page {
  background: #f3f4f5;
}

.page-dark.layout-full {
  color: $color-white;
}

.page-dark.layout-full:after {
  background-color: rgba(33, 33, 33, .6);
}

.page-dark.layout-full .brand-text {
  color: $color-white;
}

.page-content-table .table > tbody > tr:hover > td {
  background-color: #eee;
}

.page-content-table .table > tbody > tr:last-child td {
  border-bottom: 1px solid #e0e0e0;
}

.page-content-table .table.is-indent > thead > tr > th.pre-cell,
.page-content-table .table.is-indent > tbody > tr > th.pre-cell,
.page-content-table .table.is-indent > thead > tr > td.pre-cell,
.page-content-table .table.is-indent > tbody > tr > td.pre-cell,
.page-content-table .table.is-indent > thead > tr > th.suf-cell,
.page-content-table .table.is-indent > tbody > tr > th.suf-cell,
.page-content-table .table.is-indent > thead > tr > td.suf-cell,
.page-content-table .table.is-indent > tbody > tr > td.suf-cell {
  border-top: none;
  border-bottom: none;
}

.page-content-table .table.is-indent > tbody > tr:first-child td {
  border-top: none;
}

.page-content-table .table.is-indent > tbody > tr:last-child td.pre-cell,
.page-content-table .table.is-indent > tbody > tr:last-child td.suf-cell {
  border-bottom: none;
}

.page-content-table .table.is-indent > tfoot > tr > td {
  border-top: none;
}

.page-copyright {
  color: #424242;
}

.page-copyright-inverse {
  color: $color-white;
}

.page-copyright-inverse .social .icon {
  color: $color-white;
}

.page-copyright-inverse .social .icon:hover,
.page-copyright-inverse .social .icon:active {
  color: rgba(255, 255, 255, .8);
}

.page {
  background: #f3f4f5;
}

.page-dark.layout-full {
  color: $color-white;
}

.page-dark.layout-full:after {
  background-color: rgba(33, 33, 33, .6);
}

.page-dark.layout-full .brand-text {
  color: $color-white;
}

.page-content-table .table > tbody > tr:hover > td {
  background-color: #eee;
}

.page-content-table .table > tbody > tr:last-child td {
  border-bottom: 1px solid #e0e0e0;
}

.page-content-table .table.is-indent > thead > tr > th.pre-cell,
.page-content-table .table.is-indent > tbody > tr > th.pre-cell,
.page-content-table .table.is-indent > thead > tr > td.pre-cell,
.page-content-table .table.is-indent > tbody > tr > td.pre-cell,
.page-content-table .table.is-indent > thead > tr > th.suf-cell,
.page-content-table .table.is-indent > tbody > tr > th.suf-cell,
.page-content-table .table.is-indent > thead > tr > td.suf-cell,
.page-content-table .table.is-indent > tbody > tr > td.suf-cell {
  border-top: none;
  border-bottom: none;
}

.page-content-table .table.is-indent > tbody > tr:first-child td {
  border-top: none;
}

.page-content-table .table.is-indent > tbody > tr:last-child td.pre-cell,
.page-content-table .table.is-indent > tbody > tr:last-child td.suf-cell {
  border-bottom: none;
}

.page-content-table .table.is-indent > tfoot > tr > td {
  border-top: none;
}

.page-copyright {
  color: #424242;
}

.page-copyright-inverse {
  color: $color-white;
}

.page-copyright-inverse .social .icon {
  color: $color-white;
}

.page-copyright-inverse .social .icon:hover,
.page-copyright-inverse .social .icon:active {
  color: rgba(255, 255, 255, .8);
}

.page-description {
  color: #9e9e9e;
}

.page-header {
  background: transparent;
  border-bottom: none;
}

.page-header-bordered {
  background-color: $color-white;
  border-bottom: 1px solid transparent;
}

.page-header-tabs .nav-tabs-line {
  border-bottom-color: transparent;
}

.page-aside {
  background: $color-white;
  border-right: 1px solid #e0e0e0;
}

.page-aside-section:after {
  border-bottom: 1px solid #e0e0e0;
}

.page-aside-switch {
  background-color: white;
}

.page-aside-title {
  color: #616161;
}

.page-aside .list-group-item {
  border: none;
}

.page-aside .list-group-item .icon {
  color: #9e9e9e;
}

.page-aside .list-group-item:hover,
.page-aside .list-group-item:focus {
  color: $color-primary;
  background-color: #eee;
  border: none;
}

.page-aside .list-group-item:hover > .icon,
.page-aside .list-group-item:focus > .icon {
  color: $color-primary;
}

.page-aside .list-group-item.active {
  color: $color-primary;
  background-color: transparent;
}

.page-aside .list-group-item.active > .icon {
  color: $color-primary;
}

.page-aside .list-group-item.active:hover,
.page-aside .list-group-item.active:focus {
  color: $color-primary;
  background-color: #eee;
  border: none;
}

.page-aside .list-group-item.active:hover > .icon,
.page-aside .list-group-item.active:focus > .icon {
  color: $color-primary;
}

.page-aside .list-group.has-actions .list-group-item .list-editable .input-editable-close {
  background: 0 0;
  border: 0;
}

.page-aside .list-group.has-actions .list-group-item .item-actions .btn-icon {
  background-color: transparent;
}

.page-aside .list-group.has-actions .list-group-item .item-actions .btn-icon:hover .icon {
  color: $color-primary;
}

.page-aside .list-group.has-actions .list-group-item:hover .item-actions .icon {
  color: #757575;
}

@media (max-width: 767px) {
  .page-aside {
    border-color: transparent;
  }

  .page-aside .page-aside-inner {
    background-color: white;
    border-right: 1px solid #e0e0e0;
  }
}

.site-footer {
  background-color: rgba(0, 0, 0, .02);
  border-top: 1px solid #e0e0e0;
}

.site-footer .scroll-to-top {
  color: #757575;
}

.site-footer {
  background-color: rgba(0, 0, 0, .02);
  border-top: 1px solid #e0e0e0;
}

.site-footer .scroll-to-top {
  color: #757575;
}

.layout-full .page {
  background-color: transparent;
}

@media (min-width: 1200px) {
  .layout-boxed {
    background: #e0e0e0;
  }
}

.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item.hover > a,
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item:hover > a {
  background-color: transparent;
}

.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item.active,
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item.open,
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item.hover {
  background: #424242;
}

.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item > a .site-menu-title {
  background: #424242;
}

.site-menubar-fold.site-menubar-fold-alt .site-menubar-dark .site-menu > .site-menu-item.active,
.site-menubar-fold.site-menubar-fold-alt .site-menubar-dark .site-menu > .site-menu-item.open,
.site-menubar-fold.site-menubar-fold-alt .site-menubar-dark .site-menu > .site-menu-item.hover {
  background: rgba(66, 66, 66, .9);
}

.site-menubar-fold.site-menubar-fold-alt .site-menubar-dark .site-menu > .site-menu-item > a .site-menu-title {
  background: rgba(66, 66, 66, .9);
}

@media (max-width: 767px) {
  .site-gridmenu {
    background: rgba(33, 33, 33, .9);
  }
}

.checkbox-custom label::before {
  background-color: $color-white;
  border: 1px solid #e0e0e0;
}

.checkbox-custom label::after {
  color: #757575;
}

.checkbox-custom input[type="checkbox"]:checked + label::before,
.checkbox-custom input[type="radio"]:checked + label::before {
  border-color: #e0e0e0;
}

.checkbox-custom input[type="checkbox"]:disabled + label::before,
.checkbox-custom input[type="radio"]:disabled + label::before {
  background-color: #eee;
  border-color: #e0e0e0;
}

.checkbox-default input[type="checkbox"]:checked + label::before,
.checkbox-default input[type="radio"]:checked + label::before {
  background-color: $color-white;
  border-color: #e0e0e0;
}

.checkbox-default input[type="checkbox"]:checked + label::after,
.checkbox-default input[type="radio"]:checked + label::after {
  color: $color-primary;
}

.checkbox-primary input[type="checkbox"]:checked + label::before,
.checkbox-primary input[type="radio"]:checked + label::before {
  background-color: $color-primary;
  border-color: $color-primary;
}

.checkbox-primary input[type="checkbox"]:checked + label::after,
.checkbox-primary input[type="radio"]:checked + label::after {
  color: $color-white;
}

.checkbox-danger input[type="checkbox"]:checked + label::before,
.checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #E3170A;
  border-color: #E3170A;
}

.checkbox-danger input[type="checkbox"]:checked + label::after,
.checkbox-danger input[type="radio"]:checked + label::after {
  color: $color-white;
}

.checkbox-info input[type="checkbox"]:checked + label::before,
.checkbox-info input[type="radio"]:checked + label::before {
  background-color: #00bcd4;
  border-color: #00bcd4;
}

.checkbox-info input[type="checkbox"]:checked + label::after,
.checkbox-info input[type="radio"]:checked + label::after {
  color: $color-white;
}

.checkbox-warning input[type="checkbox"]:checked + label::before,
.checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #FFA630;
  border-color: #FFA630;
}

.checkbox-warning input[type="checkbox"]:checked + label::after,
.checkbox-warning input[type="radio"]:checked + label::after {
  color: $color-white;
}

.checkbox-success input[type="checkbox"]:checked + label::before,
.checkbox-success input[type="radio"]:checked + label::before {
  background-color: #1B998B;
  border-color: #1B998B;
}

.checkbox-success input[type="checkbox"]:checked + label::after,
.checkbox-success input[type="radio"]:checked + label::after {
  color: $color-white;
}

.radio-custom label::before {
  background-color: $color-white;
  border: 1px solid #e0e0e0;
}

.radio-custom label::after {
  background-color: transparent;
  border: 2px solid #757575;
}

.radio-custom input[type="radio"]:checked + label::before {
  border-color: #e0e0e0;
}

.radio-default input[type="radio"]:checked + label::before {
  background-color: $color-white;
  border-color: #e0e0e0;
}

.radio-default input[type="radio"]:checked + label::after {
  border-color: $color-primary;
}

.radio-primary input[type="radio"]:checked + label::before {
  border-color: $color-primary;
}

.radio-primary input[type="radio"]:checked + label::after {
  border-color: $color-white;
}

.radio-danger input[type="radio"]:checked + label::before {
  border-color: #E3170A;
}

.radio-danger input[type="radio"]:checked + label::after {
  border-color: $color-white;
}

.radio-info input[type="radio"]:checked + label::before {
  border-color: #00bcd4;
}

.radio-info input[type="radio"]:checked + label::after {
  border-color: $color-white;
}

.radio-warning input[type="radio"]:checked + label::before {
  border-color: #FFA630;
}

.radio-warning input[type="radio"]:checked + label::after {
  border-color: $color-white;
}

.radio-success input[type="radio"]:checked + label::before {
  border-color: #1B998B;
}

.radio-success input[type="radio"]:checked + label::after {
  border-color: $color-white;
}

.form-material .form-control {
  background-color: transparent;
  background-color: rgba(0, 0, 0, 0);
}

.form-material .form-control,
.form-material .form-control:focus,
.form-material .form-control.focus {
  background-image: -webkit-gradient(linear, left top, left bottom, from($color-primary), to($color-primary)), -webkit-gradient(linear, left top, left bottom, from(#e0e0e0), to(#e0e0e0));
  background-image: -webkit-linear-gradient($color-primary, $color-primary), -webkit-linear-gradient(#e0e0e0, #e0e0e0);
  background-image: -o-linear-gradient($color-primary, $color-primary), -o-linear-gradient(#e0e0e0, #e0e0e0);
  background-image: linear-gradient($color-primary, $color-primary), linear-gradient(#e0e0e0, #e0e0e0);
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.no-cssgradients .form-material .form-control {
  border-bottom: 2px solid #e0e0e0;
}

.form-material .form-control::-webkit-input-placeholder {
  color: #9e9e9e;
}

.form-material .form-control::-moz-placeholder {
  color: #9e9e9e;
}

.form-material .form-control:-ms-input-placeholder {
  color: #9e9e9e;
}

.form-material .form-control:disabled::-webkit-input-placeholder {
  color: #bdbdbd;
}

.form-material .form-control:disabled::-moz-placeholder {
  color: #bdbdbd;
}

.form-material .form-control:disabled:-ms-input-placeholder {
  color: #bdbdbd;
}

.form-material .form-control:focus,
.form-material .form-control.focus {
  -webkit-background-size: 100% 2px, 100% 1px;
  background-size: 100% 2px, 100% 1px;
}

.no-cssgradients .form-material .form-control:focus,
.no-cssgradients .form-material .form-control.focus {
  background: transparent;
  border-bottom: 2px solid $color-primary;
}

.form-material .form-control:disabled,
.form-material .form-control[disabled],
fieldset[disabled] .form-material .form-control {
  background: transparent;
  background: rgba(0, 0, 0, 0);
  border-bottom: 1px dashed #bdbdbd;
}

.form-material .form-control:disabled ~ .floating-label,
.form-material .form-control[disabled] ~ .floating-label,
fieldset[disabled] .form-material .form-control ~ .floating-label {
  color: #bdbdbd;
}

.form-material .floating-label {
  color: #757575;
}

.form-material .form-control:focus ~ .floating-label,
.form-material .form-control.focus ~ .floating-label {
  color: $color-primary;
}

.form-material select.form-control {
  border: 0;
}

.form-material .form-control:not(.empty):invalid ~ .floating-label,
.form-material .form-control.focus:invalid ~ .floating-label {
  color: #E3170A;
}

.form-material .form-control:invalid {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#E3170A), to(#E3170A)), -webkit-gradient(linear, left top, left bottom, from(#e0e0e0), to(#e0e0e0));
  background-image: -webkit-linear-gradient(#E3170A, #E3170A), -webkit-linear-gradient(#e0e0e0, #e0e0e0);
  background-image: -o-linear-gradient(#E3170A, #E3170A), -o-linear-gradient(#e0e0e0, #e0e0e0);
  background-image: linear-gradient(#E3170A, #E3170A), linear-gradient(#e0e0e0, #e0e0e0);
}

.form-material.form-group.has-warning .form-control:focus,
.form-material.form-group.has-warning .form-control.focus,
.form-material.form-group.has-warning .form-control:not(.empty) {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#FFA630), to(#FFA630)), -webkit-gradient(linear, left top, left bottom, from(#e0e0e0), to(#e0e0e0));
  background-image: -webkit-linear-gradient(#FFA630, #FFA630), -webkit-linear-gradient(#e0e0e0, #e0e0e0);
  background-image: -o-linear-gradient(#FFA630, #FFA630), -o-linear-gradient(#e0e0e0, #e0e0e0);
  background-image: linear-gradient(#FFA630, #FFA630), linear-gradient(#e0e0e0, #e0e0e0);
}

.no-cssgradients .form-material.form-group.has-warning .form-control:focus,
.no-cssgradients .form-material.form-group.has-warning .form-control.focus,
.no-cssgradients .form-material.form-group.has-warning .form-control:not(.empty) {
  background: transparent;
  border-bottom: 2px solid #FFA630;
}

.form-material.form-group.has-warning .form-control:-webkit-autofill {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#FFA630), to(#FFA630)), -webkit-gradient(linear, left top, left bottom, from(#e0e0e0), to(#e0e0e0));
  background-image: -webkit-linear-gradient(#FFA630, #FFA630), -webkit-linear-gradient(#e0e0e0, #e0e0e0);
  background-image: linear-gradient(#FFA630, #FFA630), linear-gradient(#e0e0e0, #e0e0e0);
}

.no-cssgradients .form-material.form-group.has-warning .form-control:-webkit-autofill {
  background: transparent;
  border-bottom: 2px solid #FFA630;
}

.form-material.form-group.has-warning .control-label {
  color: #FFA630;
}

.form-material.form-group.has-warning .form-control:focus ~ .floating-label,
.form-material.form-group.has-warning .form-control.focus ~ .floating-label,
.form-material.form-group.has-warning .form-control:not(.empty) ~ .floating-label {
  color: #FFA630;
}

.form-material.form-group.has-warning .form-control:-webkit-autofill ~ .floating-label {
  color: #FFA630;
}

.form-material.form-group.has-error .form-control:focus,
.form-material.form-group.has-error .form-control.focus,
.form-material.form-group.has-error .form-control:not(.empty) {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#E3170A), to(#E3170A)), -webkit-gradient(linear, left top, left bottom, from(#e0e0e0), to(#e0e0e0));
  background-image: -webkit-linear-gradient(#E3170A, #E3170A), -webkit-linear-gradient(#e0e0e0, #e0e0e0);
  background-image: -o-linear-gradient(#E3170A, #E3170A), -o-linear-gradient(#e0e0e0, #e0e0e0);
  background-image: linear-gradient(#E3170A, #E3170A), linear-gradient(#e0e0e0, #e0e0e0);
}

.no-cssgradients .form-material.form-group.has-error .form-control:focus,
.no-cssgradients .form-material.form-group.has-error .form-control.focus,
.no-cssgradients .form-material.form-group.has-error .form-control:not(.empty) {
  background: transparent;
  border-bottom: 2px solid #E3170A;
}

.form-material.form-group.has-error .form-control:-webkit-autofill {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#E3170A), to(#E3170A)), -webkit-gradient(linear, left top, left bottom, from(#e0e0e0), to(#e0e0e0));
  background-image: -webkit-linear-gradient(#E3170A, #E3170A), -webkit-linear-gradient(#e0e0e0, #e0e0e0);
  background-image: linear-gradient(#E3170A, #E3170A), linear-gradient(#e0e0e0, #e0e0e0);
}

.no-cssgradients .form-material.form-group.has-error .form-control:-webkit-autofill {
  background: transparent;
  border-bottom: 2px solid #E3170A;
}

.form-material.form-group.has-error .control-label {
  color: #E3170A;
}

.form-material.form-group.has-error .form-control:focus ~ .floating-label,
.form-material.form-group.has-error .form-control.focus ~ .floating-label,
.form-material.form-group.has-error .form-control:not(.empty) ~ .floating-label {
  color: #E3170A;
}

.form-material.form-group.has-error .form-control:-webkit-autofill ~ .floating-label {
  color: #E3170A;
}

.form-material.form-group.has-success .form-control:focus,
.form-material.form-group.has-success .form-control.focus,
.form-material.form-group.has-success .form-control:not(.empty) {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1B998B), to(#1B998B)), -webkit-gradient(linear, left top, left bottom, from(#e0e0e0), to(#e0e0e0));
  background-image: -webkit-linear-gradient(#1B998B, #1B998B), -webkit-linear-gradient(#e0e0e0, #e0e0e0);
  background-image: -o-linear-gradient(#1B998B, #1B998B), -o-linear-gradient(#e0e0e0, #e0e0e0);
  background-image: linear-gradient(#1B998B, #1B998B), linear-gradient(#e0e0e0, #e0e0e0);
}

.no-cssgradients .form-material.form-group.has-success .form-control:focus,
.no-cssgradients .form-material.form-group.has-success .form-control.focus,
.no-cssgradients .form-material.form-group.has-success .form-control:not(.empty) {
  background: transparent;
  border-bottom: 2px solid #1B998B;
}

.form-material.form-group.has-success .form-control:-webkit-autofill {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1B998B), to(#1B998B)), -webkit-gradient(linear, left top, left bottom, from(#e0e0e0), to(#e0e0e0));
  background-image: -webkit-linear-gradient(#1B998B, #1B998B), -webkit-linear-gradient(#e0e0e0, #e0e0e0);
  background-image: linear-gradient(#1B998B, #1B998B), linear-gradient(#e0e0e0, #e0e0e0);
}

.no-cssgradients .form-material.form-group.has-success .form-control:-webkit-autofill {
  background: transparent;
  border-bottom: 2px solid #1B998B;
}

.form-material.form-group.has-success .control-label {
  color: #1B998B;
}

.form-material.form-group.has-success .form-control:focus ~ .floating-label,
.form-material.form-group.has-success .form-control.focus ~ .floating-label,
.form-material.form-group.has-success .form-control:not(.empty) ~ .floating-label {
  color: #1B998B;
}

.form-material.form-group.has-success .form-control:-webkit-autofill ~ .floating-label {
  color: #1B998B;
}

.form-material.form-group.has-info .form-control:focus,
.form-material.form-group.has-info .form-control.focus,
.form-material.form-group.has-info .form-control:not(.empty) {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#00bcd4), to(#00bcd4)), -webkit-gradient(linear, left top, left bottom, from(#e0e0e0), to(#e0e0e0));
  background-image: -webkit-linear-gradient(#00bcd4, #00bcd4), -webkit-linear-gradient(#e0e0e0, #e0e0e0);
  background-image: -o-linear-gradient(#00bcd4, #00bcd4), -o-linear-gradient(#e0e0e0, #e0e0e0);
  background-image: linear-gradient(#00bcd4, #00bcd4), linear-gradient(#e0e0e0, #e0e0e0);
}

.no-cssgradients .form-material.form-group.has-info .form-control:focus,
.no-cssgradients .form-material.form-group.has-info .form-control.focus,
.no-cssgradients .form-material.form-group.has-info .form-control:not(.empty) {
  background: transparent;
  border-bottom: 2px solid #00bcd4;
}

.form-material.form-group.has-info .form-control:-webkit-autofill {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#00bcd4), to(#00bcd4)), -webkit-gradient(linear, left top, left bottom, from(#e0e0e0), to(#e0e0e0));
  background-image: -webkit-linear-gradient(#00bcd4, #00bcd4), -webkit-linear-gradient(#e0e0e0, #e0e0e0);
  background-image: linear-gradient(#00bcd4, #00bcd4), linear-gradient(#e0e0e0, #e0e0e0);
}

.no-cssgradients .form-material.form-group.has-info .form-control:-webkit-autofill {
  background: transparent;
  border-bottom: 2px solid #00bcd4;
}

.form-material.form-group.has-info .control-label {
  color: #00bcd4;
}

.form-material.form-group.has-info .form-control:focus ~ .floating-label,
.form-material.form-group.has-info .form-control.focus ~ .floating-label,
.form-material.form-group.has-info .form-control:not(.empty) ~ .floating-label {
  color: #00bcd4;
}

.form-material.form-group.has-info .form-control:-webkit-autofill ~ .floating-label {
  color: #00bcd4;
}

.form-material .input-group .input-group-addon {
  background: transparent;
  border: 0;
}

.loader-blue,
.loader-blue-only {
  border-color: #1e88e5;
}

.loader-red,
.loader-red-only {
  border-color: #e53935;
}

.loader-yellow,
.loader-yellow-only {
  border-color: #fdd835;
}

.loader-green,
.loader-green-only {
  border-color: #43a047;
}

.loader-circle-left .circle,
.loader-circle-right .circle {
  border-bottom-color: transparent !important;
}

.loader-circle-left .circle {
  border-right-color: transparent !important;
}

.loader-circle-right .circle {
  border-left-color: transparent !important;
}

.red-50 {
  color: #ffebee !important;
}

.red-100 {
  color: #ffcdd2 !important;
}

.red-200 {
  color: #ef9a9a !important;
}

.red-300 {
  color: #e57373 !important;
}

.red-400 {
  color: #ef5350 !important;
}

.red-500 {
  color: #E3170A !important;
}

.red-600 {
  color: #e53935 !important;
}

.red-700 {
  color: #d32f2f !important;
}

.red-800 {
  color: #c62828 !important;
}

.red-900 {
  color: #b71c1c !important;
}

.red-a100 {
  color: #ff8a80 !important;
}

.red-a200 {
  color: #ff5252 !important;
}

.red-a300 {
  color: #ff1744 !important;
}

.red-a400 {
  color: #d50000 !important;
}

.pink-50 {
  color: #fce4ec !important;
}

.pink-100 {
  color: #f8bbd0 !important;
}

.pink-200 {
  color: #f48fb1 !important;
}

.pink-300 {
  color: #f06292 !important;
}

.pink-400 {
  color: #ec407a !important;
}

.pink-500 {
  color: $color-primary !important;
}

.pink-600 {
  color: #e14365 !important;
}

.pink-700 {
  color: #c2185b !important;
}

.pink-800 {
  color: #ad1457 !important;
}

.pink-900 {
  color: #880e4f !important;
}

.pink-a100 {
  color: #ff80ab !important;
}

.pink-a200 {
  color: #ff4081 !important;
}

.pink-a300 {
  color: #f50057 !important;
}

.pink-a400 {
  color: #c51162 !important;
}

.purple-50 {
  color: #f3e5f5 !important;
}

.purple-100 {
  color: #e1bee7 !important;
}

.purple-200 {
  color: #ce93d8 !important;
}

.purple-300 {
  color: #ba68c8 !important;
}

.purple-400 {
  color: #ab47bc !important;
}

.purple-500 {
  color: #9c27b0 !important;
}

.purple-600 {
  color: #8e24aa !important;
}

.purple-700 {
  color: #7b1fa2 !important;
}

.purple-800 {
  color: #6a1b9a !important;
}

.purple-900 {
  color: #4a148c !important;
}

.purple-a100 {
  color: #ea80fc !important;
}

.purple-a200 {
  color: #e040fb !important;
}

.purple-a300 {
  color: #d500f9 !important;
}

.purple-a400 {
  color: #a0f !important;
}

.deep-purple-50 {
  color: #ede7f6 !important;
}

.deep-purple-100 {
  color: #d1c4e9 !important;
}

.deep-purple-200 {
  color: #b39ddb !important;
}

.deep-purple-300 {
  color: #9575cd !important;
}

.deep-purple-400 {
  color: #7e57c2 !important;
}

.deep-purple-500 {
  color: #673ab7 !important;
}

.deep-purple-600 {
  color: #5e35b1 !important;
}

.deep-purple-700 {
  color: #512da8 !important;
}

.deep-purple-800 {
  color: #4527a0 !important;
}

.deep-purple-900 {
  color: #311b92 !important;
}

.deep-purple-a100 {
  color: #b388ff !important;
}

.deep-purple-a200 {
  color: #7c4dff !important;
}

.deep-purple-a300 {
  color: #651fff !important;
}

.deep-purple-a400 {
  color: #6200ea !important;
}

.indigo-50 {
  color: #e8eaf6 !important;
}

.indigo-100 {
  color: #c5cae9 !important;
}

.indigo-200 {
  color: #9fa8da !important;
}

.indigo-300 {
  color: #7986cb !important;
}

.indigo-400 {
  color: #5c6bc0 !important;
}

.indigo-500 {
  color: #3f51b5 !important;
}

.indigo-600 {
  color: #3949ab !important;
}

.indigo-700 {
  color: #303f9f !important;
}

.indigo-800 {
  color: #283593 !important;
}

.indigo-900 {
  color: #1a237e !important;
}

.indigo-a100 {
  color: #8c9eff !important;
}

.indigo-a200 {
  color: #536dfe !important;
}

.indigo-a300 {
  color: #3d5afe !important;
}

.indigo-a400 {
  color: #304ffe !important;
}

.blue-50 {
  color: #e3f2fd !important;
}

.blue-100 {
  color: #bbdefb !important;
}

.blue-200 {
  color: #90caf9 !important;
}

.blue-300 {
  color: #64b5f6 !important;
}

.blue-400 {
  color: #42a5f5 !important;
}

.blue-500 {
  color: #2196f3 !important;
}

.blue-600 {
  color: #1e88e5 !important;
}

.blue-700 {
  color: #1976d2 !important;
}

.blue-800 {
  color: #1565c0 !important;
}

.blue-900 {
  color: #0d47a1 !important;
}

.blue-a100 {
  color: #82b1ff !important;
}

.blue-a200 {
  color: #448aff !important;
}

.blue-a300 {
  color: #2979ff !important;
}

.blue-a400 {
  color: #2962ff !important;
}

.light-blue-50 {
  color: #e1f5fe !important;
}

.light-blue-100 {
  color: #b3e5fc !important;
}

.light-blue-200 {
  color: #81d4fa !important;
}

.light-blue-300 {
  color: #4fc3f7 !important;
}

.light-blue-400 {
  color: #29b6f6 !important;
}

.light-blue-500 {
  color: #03a9f4 !important;
}

.light-blue-600 {
  color: #039be5 !important;
}

.light-blue-700 {
  color: #0288d1 !important;
}

.light-blue-800 {
  color: #0277bd !important;
}

.light-blue-900 {
  color: #01579b !important;
}

.light-blue-a100 {
  color: #80d8ff !important;
}

.light-blue-a200 {
  color: #40c4ff !important;
}

.light-blue-a300 {
  color: #00b0ff !important;
}

.light-blue-a400 {
  color: #0091ea !important;
}

.cyan-50 {
  color: #e0f7fa !important;
}

.cyan-100 {
  color: #b2ebf2 !important;
}

.cyan-200 {
  color: #80deea !important;
}

.cyan-300 {
  color: #4dd0e1 !important;
}

.cyan-400 {
  color: #26c6da !important;
}

.cyan-500 {
  color: #00bcd4 !important;
}

.cyan-600 {
  color: #00acc1 !important;
}

.cyan-700 {
  color: #0097a7 !important;
}

.cyan-800 {
  color: #00838f !important;
}

.cyan-900 {
  color: #006064 !important;
}

.cyan-a100 {
  color: #84ffff !important;
}

.cyan-a200 {
  color: #18ffff !important;
}

.cyan-a300 {
  color: #00e5ff !important;
}

.cyan-a400 {
  color: #00b8d4 !important;
}

.teal-50 {
  color: #e0f2f1 !important;
}

.teal-100 {
  color: #b2dfdb !important;
}

.teal-200 {
  color: #80cbc4 !important;
}

.teal-300 {
  color: #4db6ac !important;
}

.teal-400 {
  color: #26a69a !important;
}

.teal-500 {
  color: #009688 !important;
}

.teal-600 {
  color: #00897b !important;
}

.teal-700 {
  color: #00796b !important;
}

.teal-800 {
  color: #00695c !important;
}

.teal-900 {
  color: #004d40 !important;
}

.teal-a100 {
  color: #a7ffeb !important;
}

.teal-a200 {
  color: #64ffda !important;
}

.teal-a300 {
  color: #1de9b6 !important;
}

.teal-a400 {
  color: #00bfa5 !important;
}

.green-50 {
  color: #e8f5e9 !important;
}

.green-100 {
  color: #c8e6c9 !important;
}

.green-200 {
  color: #a5d6a7 !important;
}

.green-300 {
  color: #81c784 !important;
}

.green-400 {
  color: #66bb6a !important;
}

.green-500 {
  color: #1B998B !important;
}

.green-600 {
  color: #43a047 !important;
}

.green-700 {
  color: #388e3c !important;
}

.green-800 {
  color: #2e7d32 !important;
}

.green-900 {
  color: #1b5e20 !important;
}

.green-a100 {
  color: #b9f6ca !important;
}

.green-a200 {
  color: #69f0ae !important;
}

.green-a300 {
  color: #00e676 !important;
}

.green-a400 {
  color: #00c853 !important;
}

.light-green-50 {
  color: #f1f8e9 !important;
}

.light-green-100 {
  color: #dcedc8 !important;
}

.light-green-200 {
  color: #c5e1a5 !important;
}

.light-green-300 {
  color: #aed581 !important;
}

.light-green-400 {
  color: #9ccc65 !important;
}

.light-green-500 {
  color: #8bc34a !important;
}

.light-green-600 {
  color: #7cb342 !important;
}

.light-green-700 {
  color: #689f38 !important;
}

.light-green-800 {
  color: #558b2f !important;
}

.light-green-900 {
  color: #33691e !important;
}

.light-green-a100 {
  color: #ccff90 !important;
}

.light-green-a200 {
  color: #b2ff59 !important;
}

.light-green-a300 {
  color: #76ff03 !important;
}

.light-green-a400 {
  color: #64dd17 !important;
}

.lime-50 {
  color: #f9fbe7 !important;
}

.lime-100 {
  color: #f0f4c3 !important;
}

.lime-200 {
  color: #e6ee9c !important;
}

.lime-300 {
  color: #dce775 !important;
}

.lime-400 {
  color: #d4e157 !important;
}

.lime-500 {
  color: #cddc39 !important;
}

.lime-600 {
  color: #c0ca33 !important;
}

.lime-700 {
  color: #afb42b !important;
}

.lime-800 {
  color: #9e9d24 !important;
}

.lime-900 {
  color: #827717 !important;
}

.lime-a100 {
  color: #f4ff81 !important;
}

.lime-a200 {
  color: #eeff41 !important;
}

.lime-a300 {
  color: #c6ff00 !important;
}

.lime-a400 {
  color: #aeea00 !important;
}

.yellow-50 {
  color: #fffde7 !important;
}

.yellow-100 {
  color: #fff9c4 !important;
}

.yellow-200 {
  color: #fff59d !important;
}

.yellow-300 {
  color: #fff176 !important;
}

.yellow-400 {
  color: #ffee58 !important;
}

.yellow-500 {
  color: #ffeb3b !important;
}

.yellow-600 {
  color: #fdd835 !important;
}

.yellow-700 {
  color: #fbc02d !important;
}

.yellow-800 {
  color: #f9a825 !important;
}

.yellow-900 {
  color: #f57f17 !important;
}

.yellow-a100 {
  color: #ffff8d !important;
}

.yellow-a200 {
  color: #ff0 !important;
}

.yellow-a300 {
  color: #ffea00 !important;
}

.yellow-a400 {
  color: #ffd600 !important;
}

.amber-50 {
  color: #fff8e1 !important;
}

.amber-100 {
  color: #ffecb3 !important;
}

.amber-200 {
  color: #ffe082 !important;
}

.amber-300 {
  color: #ffd54f !important;
}

.amber-400 {
  color: #ffca28 !important;
}

.amber-500 {
  color: #ffc107 !important;
}

.amber-600 {
  color: #ffb300 !important;
}

.amber-700 {
  color: #ffa000 !important;
}

.amber-800 {
  color: #ff8f00 !important;
}

.amber-900 {
  color: #ff6f00 !important;
}

.amber-a100 {
  color: #ffe57f !important;
}

.amber-a200 {
  color: #ffd740 !important;
}

.amber-a300 {
  color: #ffc400 !important;
}

.amber-a400 {
  color: #ffab00 !important;
}

.orange-50 {
  color: #fff3e0 !important;
}

.orange-100 {
  color: #ffe0b2 !important;
}

.orange-200 {
  color: #ffcc80 !important;
}

.orange-300 {
  color: #ffb74d !important;
}

.orange-400 {
  color: #ffa726 !important;
}

.orange-500 {
  color: #FFA630 !important;
}

.orange-600 {
  color: #fb8c00 !important;
}

.orange-700 {
  color: #f57c00 !important;
}

.orange-800 {
  color: #ef6c00 !important;
}

.orange-900 {
  color: #e65100 !important;
}

.orange-a100 {
  color: #ffd180 !important;
}

.orange-a200 {
  color: #ffab40 !important;
}

.orange-a300 {
  color: #ff9100 !important;
}

.orange-a400 {
  color: #ff6d00 !important;
}

.deep-orange-50 {
  color: #fbe9e7 !important;
}

.deep-orange-100 {
  color: #ffccbc !important;
}

.deep-orange-200 {
  color: #ffab91 !important;
}

.deep-orange-300 {
  color: #ff8a65 !important;
}

.deep-orange-400 {
  color: #ff7043 !important;
}

.deep-orange-500 {
  color: #ff5722 !important;
}

.deep-orange-600 {
  color: #f4511e !important;
}

.deep-orange-700 {
  color: #e64a19 !important;
}

.deep-orange-800 {
  color: #d84315 !important;
}

.deep-orange-900 {
  color: #bf360c !important;
}

.deep-orange-a100 {
  color: #ff9e80 !important;
}

.deep-orange-a200 {
  color: #ff6e40 !important;
}

.deep-orange-a300 {
  color: #ff3d00 !important;
}

.deep-orange-a400 {
  color: #dd2c00 !important;
}

.primary-50 {
  color: #fce4ec !important;
}

.primary-100 {
  color: #f8bbd0 !important;
}

.primary-200 {
  color: #f48fb1 !important;
}

.primary-300 {
  color: #f06292 !important;
}

.primary-400 {
  color: #ec407a !important;
}

.primary-500 {
  color: $color-primary !important;
}

.primary-600 {
  color: #e14365 !important;
}

.primary-700 {
  color: #c2185b !important;
}

.primary-800 {
  color: #ad1457 !important;
}

.primary-900 {
  color: #880e4f !important;
}

.brown-50 {
  color: #efebe9 !important;
}

.brown-100 {
  color: #d7ccc8 !important;
}

.brown-200 {
  color: #bcaaa4 !important;
}

.brown-300 {
  color: #a1887f !important;
}

.brown-400 {
  color: #8d6e63 !important;
}

.brown-500 {
  color: #795548 !important;
}

.brown-600 {
  color: #6d4c41 !important;
}

.brown-700 {
  color: #5d4037 !important;
}

.brown-800 {
  color: #4e342e !important;
}

.brown-900 {
  color: #3e2723 !important;
}

.blue-grey-50 {
  color: #eceff1 !important;
}

.blue-grey-100 {
  color: #cfd8dc !important;
}

.blue-grey-200 {
  color: #b0bec5 !important;
}

.blue-grey-300 {
  color: #90a4ae !important;
}

.blue-grey-400 {
  color: #78909c !important;
}

.blue-grey-500 {
  color: #607d8b !important;
}

.blue-grey-600 {
  color: #546e7a !important;
}

.blue-grey-700 {
  color: #455a64 !important;
}

.blue-grey-800 {
  color: #37474f !important;
}

.blue-grey-900 {
  color: #263238 !important;
}

.grey-50 {
  color: #fafafa !important;
}

.grey-100 {
  color: #f5f5f5 !important;
}

.grey-200 {
  color: #eee !important;
}

.grey-300 {
  color: #e0e0e0 !important;
}

.grey-400 {
  color: #bdbdbd !important;
}

.grey-500 {
  color: #9e9e9e !important;
}

.grey-600 {
  color: #757575 !important;
}

.grey-700 {
  color: #616161 !important;
}

.grey-800 {
  color: #424242 !important;
}

.grey-900 {
  color: #212121 !important;
}

.bg-red-50 {
  background-color: #ffebee !important;
}

.bg-red-100 {
  background-color: #ffcdd2 !important;
}

.bg-red-200 {
  background-color: #ef9a9a !important;
}

.bg-red-300 {
  background-color: #e57373 !important;
}

.bg-red-400 {
  background-color: #ef5350 !important;
}

.bg-red-500 {
  background-color: #E3170A !important;
}

.bg-red-600 {
  background-color: #e53935 !important;
}

.bg-red-700 {
  background-color: #d32f2f !important;
}

.bg-red-800 {
  background-color: #c62828 !important;
}

.bg-red-900 {
  background-color: #b71c1c !important;
}

.bg-red-a100 {
  background-color: #ff8a80 !important;
}

.bg-red-a200 {
  background-color: #ff5252 !important;
}

.bg-red-a300 {
  background-color: #ff1744 !important;
}

.bg-red-a400 {
  background-color: #d50000 !important;
}

.bg-pink-50 {
  background-color: #fce4ec !important;
}

.bg-pink-100 {
  background-color: #f8bbd0 !important;
}

.bg-pink-200 {
  background-color: #f48fb1 !important;
}

.bg-pink-300 {
  background-color: #f06292 !important;
}

.bg-pink-400 {
  background-color: #ec407a !important;
}

.bg-pink-500 {
  background-color: $color-primary !important;
}

.bg-pink-600 {
  background-color: #e14365 !important;
}

.bg-pink-700 {
  background-color: #c2185b !important;
}

.bg-pink-800 {
  background-color: #ad1457 !important;
}

.bg-pink-900 {
  background-color: #880e4f !important;
}

.bg-pink-a100 {
  background-color: #ff80ab !important;
}

.bg-pink-a200 {
  background-color: #ff4081 !important;
}

.bg-pink-a300 {
  background-color: #f50057 !important;
}

.bg-pink-a400 {
  background-color: #c51162 !important;
}

.bg-purple-50 {
  background-color: #f3e5f5 !important;
}

.bg-purple-100 {
  background-color: #e1bee7 !important;
}

.bg-purple-200 {
  background-color: #ce93d8 !important;
}

.bg-purple-300 {
  background-color: #ba68c8 !important;
}

.bg-purple-400 {
  background-color: #ab47bc !important;
}

.bg-purple-500 {
  background-color: #9c27b0 !important;
}

.bg-purple-600 {
  background-color: #8e24aa !important;
}

.bg-purple-700 {
  background-color: #7b1fa2 !important;
}

.bg-purple-800 {
  background-color: #6a1b9a !important;
}

.bg-purple-900 {
  background-color: #4a148c !important;
}

.bg-purple-a100 {
  background-color: #ea80fc !important;
}

.bg-purple-a200 {
  background-color: #e040fb !important;
}

.bg-purple-a300 {
  background-color: #d500f9 !important;
}

.bg-purple-a400 {
  background-color: #a0f !important;
}

.bg-deep-purple-50 {
  background-color: #ede7f6 !important;
}

.bg-deep-purple-100 {
  background-color: #d1c4e9 !important;
}

.bg-deep-purple-200 {
  background-color: #b39ddb !important;
}

.bg-deep-purple-300 {
  background-color: #9575cd !important;
}

.bg-deep-purple-400 {
  background-color: #7e57c2 !important;
}

.bg-deep-purple-500 {
  background-color: #673ab7 !important;
}

.bg-deep-purple-600 {
  background-color: #5e35b1 !important;
}

.bg-deep-purple-700 {
  background-color: #512da8 !important;
}

.bg-deep-purple-800 {
  background-color: #4527a0 !important;
}

.bg-deep-purple-900 {
  background-color: #311b92 !important;
}

.bg-deep-purple-a100 {
  background-color: #b388ff !important;
}

.bg-deep-purple-a200 {
  background-color: #7c4dff !important;
}

.bg-deep-purple-a300 {
  background-color: #651fff !important;
}

.bg-deep-purple-a400 {
  background-color: #6200ea !important;
}

.bg-indigo-50 {
  background-color: #e8eaf6 !important;
}

.bg-indigo-100 {
  background-color: #c5cae9 !important;
}

.bg-indigo-200 {
  background-color: #9fa8da !important;
}

.bg-indigo-300 {
  background-color: #7986cb !important;
}

.bg-indigo-400 {
  background-color: #5c6bc0 !important;
}

.bg-indigo-500 {
  background-color: #3f51b5 !important;
}

.bg-indigo-600 {
  background-color: #3949ab !important;
}

.bg-indigo-700 {
  background-color: #303f9f !important;
}

.bg-indigo-800 {
  background-color: #283593 !important;
}

.bg-indigo-900 {
  background-color: #1a237e !important;
}

.bg-indigo-a100 {
  background-color: #8c9eff !important;
}

.bg-indigo-a200 {
  background-color: #536dfe !important;
}

.bg-indigo-a300 {
  background-color: #3d5afe !important;
}

.bg-indigo-a400 {
  background-color: #304ffe !important;
}

.bg-blue-50 {
  background-color: #e3f2fd !important;
}

.bg-blue-100 {
  background-color: #bbdefb !important;
}

.bg-blue-200 {
  background-color: #90caf9 !important;
}

.bg-blue-300 {
  background-color: #64b5f6 !important;
}

.bg-blue-400 {
  background-color: #42a5f5 !important;
}

.bg-blue-500 {
  background-color: #2196f3 !important;
}

.bg-blue-600 {
  background-color: #1e88e5 !important;
}

.bg-blue-700 {
  background-color: #1976d2 !important;
}

.bg-blue-800 {
  background-color: #1565c0 !important;
}

.bg-blue-900 {
  background-color: #0d47a1 !important;
}

.bg-blue-a100 {
  background-color: #82b1ff !important;
}

.bg-blue-a200 {
  background-color: #448aff !important;
}

.bg-blue-a300 {
  background-color: #2979ff !important;
}

.bg-blue-a400 {
  background-color: #2962ff !important;
}

.bg-light-blue-50 {
  background-color: #e1f5fe !important;
}

.bg-light-blue-100 {
  background-color: #b3e5fc !important;
}

.bg-light-blue-200 {
  background-color: #81d4fa !important;
}

.bg-light-blue-300 {
  background-color: #4fc3f7 !important;
}

.bg-light-blue-400 {
  background-color: #29b6f6 !important;
}

.bg-light-blue-500 {
  background-color: #03a9f4 !important;
}

.bg-light-blue-600 {
  background-color: #039be5 !important;
}

.bg-light-blue-700 {
  background-color: #0288d1 !important;
}

.bg-light-blue-800 {
  background-color: #0277bd !important;
}

.bg-light-blue-900 {
  background-color: #01579b !important;
}

.bg-light-blue-a100 {
  background-color: #80d8ff !important;
}

.bg-light-blue-a200 {
  background-color: #40c4ff !important;
}

.bg-light-blue-a300 {
  background-color: #00b0ff !important;
}

.bg-light-blue-a400 {
  background-color: #0091ea !important;
}

.bg-cyan-50 {
  background-color: #e0f7fa !important;
}

.bg-cyan-100 {
  background-color: #b2ebf2 !important;
}

.bg-cyan-200 {
  background-color: #80deea !important;
}

.bg-cyan-300 {
  background-color: #4dd0e1 !important;
}

.bg-cyan-400 {
  background-color: #26c6da !important;
}

.bg-cyan-500 {
  background-color: #00bcd4 !important;
}

.bg-cyan-600 {
  background-color: #00acc1 !important;
}

.bg-cyan-700 {
  background-color: #0097a7 !important;
}

.bg-cyan-800 {
  background-color: #00838f !important;
}

.bg-cyan-900 {
  background-color: #006064 !important;
}

.bg-cyan-a100 {
  background-color: #84ffff !important;
}

.bg-cyan-a200 {
  background-color: #18ffff !important;
}

.bg-cyan-a300 {
  background-color: #00e5ff !important;
}

.bg-cyan-a400 {
  background-color: #00b8d4 !important;
}

.bg-teal-50 {
  background-color: #e0f2f1 !important;
}

.bg-teal-100 {
  background-color: #b2dfdb !important;
}

.bg-teal-200 {
  background-color: #80cbc4 !important;
}

.bg-teal-300 {
  background-color: #4db6ac !important;
}

.bg-teal-400 {
  background-color: #26a69a !important;
}

.bg-teal-500 {
  background-color: #009688 !important;
}

.bg-teal-600 {
  background-color: #00897b !important;
}

.bg-teal-700 {
  background-color: #00796b !important;
}

.bg-teal-800 {
  background-color: #00695c !important;
}

.bg-teal-900 {
  background-color: #004d40 !important;
}

.bg-teal-a100 {
  background-color: #a7ffeb !important;
}

.bg-teal-a200 {
  background-color: #64ffda !important;
}

.bg-teal-a300 {
  background-color: #1de9b6 !important;
}

.bg-teal-a400 {
  background-color: #00bfa5 !important;
}

.bg-green-50 {
  background-color: #e8f5e9 !important;
}

.bg-green-100 {
  background-color: #c8e6c9 !important;
}

.bg-green-200 {
  background-color: #a5d6a7 !important;
}

.bg-green-300 {
  background-color: #81c784 !important;
}

.bg-green-400 {
  background-color: #66bb6a !important;
}

.bg-green-500 {
  background-color: #1B998B !important;
}

.bg-green-600 {
  background-color: #43a047 !important;
}

.bg-green-700 {
  background-color: #388e3c !important;
}

.bg-green-800 {
  background-color: #2e7d32 !important;
}

.bg-green-900 {
  background-color: #1b5e20 !important;
}

.bg-green-a100 {
  background-color: #b9f6ca !important;
}

.bg-green-a200 {
  background-color: #69f0ae !important;
}

.bg-green-a300 {
  background-color: #00e676 !important;
}

.bg-green-a400 {
  background-color: #00c853 !important;
}

.bg-light-green-50 {
  background-color: #f1f8e9 !important;
}

.bg-light-green-100 {
  background-color: #dcedc8 !important;
}

.bg-light-green-200 {
  background-color: #c5e1a5 !important;
}

.bg-light-green-300 {
  background-color: #aed581 !important;
}

.bg-light-green-400 {
  background-color: #9ccc65 !important;
}

.bg-light-green-500 {
  background-color: #8bc34a !important;
}

.bg-light-green-600 {
  background-color: #7cb342 !important;
}

.bg-light-green-700 {
  background-color: #689f38 !important;
}

.bg-light-green-800 {
  background-color: #558b2f !important;
}

.bg-light-green-900 {
  background-color: #33691e !important;
}

.bg-light-green-a100 {
  background-color: #ccff90 !important;
}

.bg-light-green-a200 {
  background-color: #b2ff59 !important;
}

.bg-light-green-a300 {
  background-color: #76ff03 !important;
}

.bg-light-green-a400 {
  background-color: #64dd17 !important;
}

.bg-lime-50 {
  background-color: #f9fbe7 !important;
}

.bg-lime-100 {
  background-color: #f0f4c3 !important;
}

.bg-lime-200 {
  background-color: #e6ee9c !important;
}

.bg-lime-300 {
  background-color: #dce775 !important;
}

.bg-lime-400 {
  background-color: #d4e157 !important;
}

.bg-lime-500 {
  background-color: #cddc39 !important;
}

.bg-lime-600 {
  background-color: #c0ca33 !important;
}

.bg-lime-700 {
  background-color: #afb42b !important;
}

.bg-lime-800 {
  background-color: #9e9d24 !important;
}

.bg-lime-900 {
  background-color: #827717 !important;
}

.bg-lime-a100 {
  background-color: #f4ff81 !important;
}

.bg-lime-a200 {
  background-color: #eeff41 !important;
}

.bg-lime-a300 {
  background-color: #c6ff00 !important;
}

.bg-lime-a400 {
  background-color: #aeea00 !important;
}

.bg-yellow-50 {
  background-color: #fffde7 !important;
}

.bg-yellow-100 {
  background-color: #fff9c4 !important;
}

.bg-yellow-200 {
  background-color: #fff59d !important;
}

.bg-yellow-300 {
  background-color: #fff176 !important;
}

.bg-yellow-400 {
  background-color: #ffee58 !important;
}

.bg-yellow-500 {
  background-color: #ffeb3b !important;
}

.bg-yellow-600 {
  background-color: #fdd835 !important;
}

.bg-yellow-700 {
  background-color: #fbc02d !important;
}

.bg-yellow-800 {
  background-color: #f9a825 !important;
}

.bg-yellow-900 {
  background-color: #f57f17 !important;
}

.bg-yellow-a100 {
  background-color: #ffff8d !important;
}

.bg-yellow-a200 {
  background-color: #ff0 !important;
}

.bg-yellow-a300 {
  background-color: #ffea00 !important;
}

.bg-yellow-a400 {
  background-color: #ffd600 !important;
}

.bg-amber-50 {
  background-color: #fff8e1 !important;
}

.bg-amber-100 {
  background-color: #ffecb3 !important;
}

.bg-amber-200 {
  background-color: #ffe082 !important;
}

.bg-amber-300 {
  background-color: #ffd54f !important;
}

.bg-amber-400 {
  background-color: #ffca28 !important;
}

.bg-amber-500 {
  background-color: #ffc107 !important;
}

.bg-amber-600 {
  background-color: #ffb300 !important;
}

.bg-amber-700 {
  background-color: #ffa000 !important;
}

.bg-amber-800 {
  background-color: #ff8f00 !important;
}

.bg-amber-900 {
  background-color: #ff6f00 !important;
}

.bg-amber-a100 {
  background-color: #ffe57f !important;
}

.bg-amber-a200 {
  background-color: #ffd740 !important;
}

.bg-amber-a300 {
  background-color: #ffc400 !important;
}

.bg-amber-a400 {
  background-color: #ffab00 !important;
}

.bg-orange-50 {
  background-color: #fff3e0 !important;
}

.bg-orange-100 {
  background-color: #ffe0b2 !important;
}

.bg-orange-200 {
  background-color: #ffcc80 !important;
}

.bg-orange-300 {
  background-color: #ffb74d !important;
}

.bg-orange-400 {
  background-color: #ffa726 !important;
}

.bg-orange-500 {
  background-color: #FFA630 !important;
}

.bg-orange-600 {
  background-color: #fb8c00 !important;
}

.bg-orange-700 {
  background-color: #f57c00 !important;
}

.bg-orange-800 {
  background-color: #ef6c00 !important;
}

.bg-orange-900 {
  background-color: #e65100 !important;
}

.bg-orange-a100 {
  background-color: #ffd180 !important;
}

.bg-orange-a200 {
  background-color: #ffab40 !important;
}

.bg-orange-a300 {
  background-color: #ff9100 !important;
}

.bg-orange-a400 {
  background-color: #ff6d00 !important;
}

.bg-deep-orange-50 {
  background-color: #fbe9e7 !important;
}

.bg-deep-orange-100 {
  background-color: #ffccbc !important;
}

.bg-deep-orange-200 {
  background-color: #ffab91 !important;
}

.bg-deep-orange-300 {
  background-color: #ff8a65 !important;
}

.bg-deep-orange-400 {
  background-color: #ff7043 !important;
}

.bg-deep-orange-500 {
  background-color: #ff5722 !important;
}

.bg-deep-orange-600 {
  background-color: #f4511e !important;
}

.bg-deep-orange-700 {
  background-color: #e64a19 !important;
}

.bg-deep-orange-800 {
  background-color: #d84315 !important;
}

.bg-deep-orange-900 {
  background-color: #bf360c !important;
}

.bg-deep-orange-a100 {
  background-color: #ff9e80 !important;
}

.bg-deep-orange-a200 {
  background-color: #ff6e40 !important;
}

.bg-deep-orange-a300 {
  background-color: #ff3d00 !important;
}

.bg-deep-orange-a400 {
  background-color: #dd2c00 !important;
}

.bg-primary-50 {
  background-color: #fce4ec !important;
}

.bg-primary-100 {
  background-color: #f8bbd0 !important;
}

.bg-primary-200 {
  background-color: #f48fb1 !important;
}

.bg-primary-300 {
  background-color: #f06292 !important;
}

.bg-primary-400 {
  background-color: #ec407a !important;
}

.bg-primary-500 {
  background-color: $color-primary !important;
}

.bg-primary-600 {
  background-color: #e14365 !important;
}

.bg-primary-700 {
  background-color: #c2185b !important;
}

.bg-primary-800 {
  background-color: #ad1457 !important;
}

.bg-primary-900 {
  background-color: #880e4f !important;
}

.bg-brown-50 {
  background-color: #efebe9 !important;
}

.bg-brown-100 {
  background-color: #d7ccc8 !important;
}

.bg-brown-200 {
  background-color: #bcaaa4 !important;
}

.bg-brown-300 {
  background-color: #a1887f !important;
}

.bg-brown-400 {
  background-color: #8d6e63 !important;
}

.bg-brown-500 {
  background-color: #795548 !important;
}

.bg-brown-600 {
  background-color: #6d4c41 !important;
}

.bg-brown-700 {
  background-color: #5d4037 !important;
}

.bg-brown-800 {
  background-color: #4e342e !important;
}

.bg-brown-900 {
  background-color: #3e2723 !important;
}

.bg-blue-grey-50 {
  background-color: #eceff1 !important;
}

.bg-blue-grey-100 {
  background-color: #cfd8dc !important;
}

.bg-blue-grey-200 {
  background-color: #b0bec5 !important;
}

.bg-blue-grey-300 {
  background-color: #90a4ae !important;
}

.bg-blue-grey-400 {
  background-color: #78909c !important;
}

.bg-blue-grey-500 {
  background-color: #607d8b !important;
}

.bg-blue-grey-600 {
  background-color: #546e7a !important;
}

.bg-blue-grey-700 {
  background-color: #455a64 !important;
}

.bg-blue-grey-800 {
  background-color: #37474f !important;
}

.bg-blue-grey-900 {
  background-color: #263238 !important;
}

.bg-grey-50 {
  background-color: #fafafa !important;
}

.bg-grey-100 {
  background-color: #f5f5f5 !important;
}

.bg-grey-200 {
  background-color: #eee !important;
}

.bg-grey-300 {
  background-color: #e0e0e0 !important;
}

.bg-grey-400 {
  background-color: #bdbdbd !important;
}

.bg-grey-500 {
  background-color: #9e9e9e !important;
}

.bg-grey-600 {
  background-color: #757575 !important;
}

.bg-grey-700 {
  background-color: #616161 !important;
}

.bg-grey-800 {
  background-color: #424242 !important;
}

.bg-grey-900 {
  background-color: #212121 !important;
}

.black {
  color: #000 !important;
}

.white {
  color: $color-white !important;
}

.bg-white {
  color: #757575;
  background-color: $color-white;
}

.bg-primary {
  color: $color-white;
  background-color: $color-primary;
}

.bg-primary:hover {
  background-color: #ee4c83;
}

.bg-primary a,
a.bg-primary {
  color: $color-white;
}

.bg-primary a:hover,
a.bg-primary:hover {
  color: $color-white;
}

.bg-success {
  color: $color-white;
  background-color: #1B998B;
}

.bg-success:hover {
  background-color: #6ec071;
}

.bg-success a,
a.bg-success {
  color: $color-white;
}

.bg-success a:hover,
a.bg-success:hover {
  color: $color-white;
}

.bg-info {
  color: $color-white;
  background-color: #00bcd4;
}

.bg-info:hover {
  background-color: #08e3ff;
}

.bg-info a,
a.bg-info {
  color: $color-white;
}

.bg-info a:hover,
a.bg-info:hover {
  color: $color-white;
}

.bg-warning {
  color: $color-white;
  background-color: #FFA630;
}

.bg-warning:hover {
  background-color: #ffad33;
}

.bg-warning a,
a.bg-warning {
  color: $color-white;
}

.bg-warning a:hover,
a.bg-warning:hover {
  color: $color-white;
}

.bg-danger {
  color: $color-white;
  background-color: #E3170A;
}

.bg-danger:hover {
  background-color: #f77066;
}

.bg-danger a,
a.bg-danger {
  color: $color-white;
}

.bg-danger a:hover,
a.bg-danger:hover {
  color: $color-white;
}

.bg-dark {
  color: $color-white;
  background-color: #616161;
}

.bg-dark:hover {
  background-color: #7a7a7a;
}

.bg-dark a,
a.bg-dark {
  color: $color-white;
}

.bg-dark a:hover,
a.bg-dark:hover {
  color: $color-white;
}

.social-facebook {
  color: $color-white;
  background-color: #3b5998 !important;
}

.social-facebook:hover,
.social-facebook:focus {
  color: $color-white;
  background-color: #4c70ba !important;
}

.social-facebook:active,
.social-facebook.active {
  color: $color-white;
  background-color: #2d4373 !important;
}

.bg-facebook {
  background-color: #3b5998;
}

.social-twitter {
  color: $color-white;
  background-color: #55acee !important;
}

.social-twitter:hover,
.social-twitter:focus {
  color: $color-white;
  background-color: #83c3f3 !important;
}

.social-twitter:active,
.social-twitter.active {
  color: $color-white;
  background-color: #2795e9 !important;
}

.bg-twitter {
  background-color: #55acee;
}

.social-google-plus {
  color: $color-white;
  background-color: #dd4b39 !important;
}

.social-google-plus:hover,
.social-google-plus:focus {
  color: $color-white;
  background-color: #e47365 !important;
}

.social-google-plus:active,
.social-google-plus.active {
  color: $color-white;
  background-color: #c23321 !important;
}

.bg-google-plus {
  background-color: #dd4b39;
}

.social-linkedin {
  color: $color-white;
  background-color: #0976b4 !important;
}

.social-linkedin:hover,
.social-linkedin:focus {
  color: $color-white;
  background-color: #0b96e5 !important;
}

.social-linkedin:active,
.social-linkedin.active {
  color: $color-white;
  background-color: #075683 !important;
}

.bg-linkedin {
  background-color: #0976b4;
}

.social-flickr {
  color: $color-white;
  background-color: #ff0084 !important;
}

.social-flickr:hover,
.social-flickr:focus {
  color: $color-white;
  background-color: #ff339d !important;
}

.social-flickr:active,
.social-flickr.active {
  color: $color-white;
  background-color: #cc006a !important;
}

.bg-flickr {
  background-color: #ff0084;
}

.social-tumblr {
  color: $color-white;
  background-color: #35465c !important;
}

.social-tumblr:hover,
.social-tumblr:focus {
  color: $color-white;
  background-color: #485f7c !important;
}

.social-tumblr:active,
.social-tumblr.active {
  color: $color-white;
  background-color: #222d3c !important;
}

.bg-tumblr {
  background-color: #35465c;
}

.social-xing {
  color: $color-white;
  background-color: #024b4d !important;
}

.social-xing:hover,
.social-xing:focus {
  color: $color-white;
  background-color: #037b7f !important;
}

.social-xing:active,
.social-xing.active {
  color: $color-white;
  background-color: #011b1b !important;
}

.bg-xing {
  background-color: #024b4d;
}

.social-github {
  color: $color-white;
  background-color: #4183c4 !important;
}

.social-github:hover,
.social-github:focus {
  color: $color-white;
  background-color: #689cd0 !important;
}

.social-github:active,
.social-github.active {
  color: $color-white;
  background-color: #3269a0 !important;
}

.bg-github {
  background-color: #4183c4;
}

.social-html5 {
  color: $color-white;
  background-color: #e44f26 !important;
}

.social-html5:hover,
.social-html5:focus {
  color: $color-white;
  background-color: #ea7453 !important;
}

.social-html5:active,
.social-html5.active {
  color: $color-white;
  background-color: #bf3c18 !important;
}

.bg-html5 {
  background-color: #e44f26;
}

.social-openid {
  color: $color-white;
  background-color: #f67d28 !important;
}

.social-openid:hover,
.social-openid:focus {
  color: $color-white;
  background-color: #f89b59 !important;
}

.social-openid:active,
.social-openid.active {
  color: $color-white;
  background-color: #e26309 !important;
}

.bg-openid {
  background-color: #f67d28;
}

.social-stack-overflow {
  color: $color-white;
  background-color: #f86c01 !important;
}

.social-stack-overflow:hover,
.social-stack-overflow:focus {
  color: $color-white;
  background-color: #fe882e !important;
}

.social-stack-overflow:active,
.social-stack-overflow.active {
  color: $color-white;
  background-color: #c55601 !important;
}

.bg-stack-overflow {
  background-color: #f86c01;
}

.social-css3 {
  color: $color-white;
  background-color: #1572b6 !important;
}

.social-css3:hover,
.social-css3:focus {
  color: $color-white;
  background-color: #1a8fe4 !important;
}

.social-css3:active,
.social-css3.active {
  color: $color-white;
  background-color: #105588 !important;
}

.bg-css3 {
  background-color: #1572b6;
}

.social-youtube {
  color: $color-white;
  background-color: #b31217 !important;
}

.social-youtube:hover,
.social-youtube:focus {
  color: $color-white;
  background-color: #e1171d !important;
}

.social-youtube:active,
.social-youtube.active {
  color: $color-white;
  background-color: #850d11 !important;
}

.bg-youtube {
  background-color: #b31217;
}

.social-dribbble {
  color: $color-white;
  background-color: #c32361 !important;
}

.social-dribbble:hover,
.social-dribbble:focus {
  color: $color-white;
  background-color: #dc3d7b !important;
}

.social-dribbble:active,
.social-dribbble.active {
  color: $color-white;
  background-color: #981b4b !important;
}

.bg-dribbble {
  background-color: #c32361;
}

.social-instagram {
  color: $color-white;
  background-color: #3f729b !important;
}

.social-instagram:hover,
.social-instagram:focus {
  color: $color-white;
  background-color: #548cb9 !important;
}

.social-instagram:active,
.social-instagram.active {
  color: $color-white;
  background-color: #305777 !important;
}

.bg-instagram {
  background-color: #3f729b;
}

.social-pinterest {
  color: $color-white;
  background-color: #cc2127 !important;
}

.social-pinterest:hover,
.social-pinterest:focus {
  color: $color-white;
  background-color: #e04046 !important;
}

.social-pinterest:active,
.social-pinterest.active {
  color: $color-white;
  background-color: #a01a1f !important;
}

.bg-pinterest {
  background-color: #cc2127;
}

.social-vk {
  color: $color-white;
  background-color: #3d5a7d !important;
}

.social-vk:hover,
.social-vk:focus {
  color: $color-white;
  background-color: #4e739f !important;
}

.social-vk:active,
.social-vk.active {
  color: $color-white;
  background-color: #2c415b !important;
}

.bg-vk {
  background-color: #3d5a7d;
}

.social-yahoo {
  color: $color-white;
  background-color: #350178 !important;
}

.social-yahoo:hover,
.social-yahoo:focus {
  color: $color-white;
  background-color: #4b01ab !important;
}

.social-yahoo:active,
.social-yahoo.active {
  color: $color-white;
  background-color: #1f0145 !important;
}

.bg-yahoo {
  background-color: #350178;
}

.social-behance {
  color: $color-white;
  background-color: #1769ff !important;
}

.social-behance:hover,
.social-behance:focus {
  color: $color-white;
  background-color: #4a8aff !important;
}

.social-behance:active,
.social-behance.active {
  color: $color-white;
  background-color: #0050e3 !important;
}

.bg-behance {
  background-color: #024b4d;
}

.social-dropbox {
  color: $color-white;
  background-color: #007ee5 !important;
}

.social-dropbox:hover,
.social-dropbox:focus {
  color: $color-white;
  background-color: #1998ff !important;
}

.social-dropbox:active,
.social-dropbox.active {
  color: $color-white;
  background-color: #0062b2 !important;
}

.bg-dropbox {
  background-color: #007ee5;
}

.social-reddit {
  color: $color-white;
  background-color: #ff4500 !important;
}

.social-reddit:hover,
.social-reddit:focus {
  color: $color-white;
  background-color: #ff6a33 !important;
}

.social-reddit:active,
.social-reddit.active {
  color: $color-white;
  background-color: #cc3700 !important;
}

.bg-reddit {
  background-color: #ff4500;
}

.social-spotify {
  color: $color-white;
  background-color: #7ab800 !important;
}

.social-spotify:hover,
.social-spotify:focus {
  color: $color-white;
  background-color: #9ceb00 !important;
}

.social-spotify:active,
.social-spotify.active {
  color: $color-white;
  background-color: #588500 !important;
}

.bg-spotify {
  background-color: #7ab800;
}

.social-vine {
  color: $color-white;
  background-color: #00b488 !important;
}

.social-vine:hover,
.social-vine:focus {
  color: $color-white;
  background-color: #00e7af !important;
}

.social-vine:active,
.social-vine.active {
  color: $color-white;
  background-color: #008161 !important;
}

.bg-vine {
  background-color: #00b488;
}

.social-foursquare {
  color: $color-white;
  background-color: #0cbadf !important;
}

.social-foursquare:hover,
.social-foursquare:focus {
  color: $color-white;
  background-color: #2ad0f4 !important;
}

.social-foursquare:active,
.social-foursquare.active {
  color: $color-white;
  background-color: #0992af !important;
}

.bg-foursquare {
  background-color: #0cbadf;
}

.social-vimeo {
  color: $color-white;
  background-color: #1ab7ea !important;
}

.social-vimeo:hover,
.social-vimeo:focus {
  color: $color-white;
  background-color: #49c6ee !important;
}

.social-vimeo:active,
.social-vimeo.active {
  color: $color-white;
  background-color: #1295bf !important;
}

.bg-vimeo {
  background-color: #1ab7ea;
}

.social-skype {
  color: $color-white;
  background-color: #77bcfd !important;
}

.social-skype:hover,
.social-skype:focus {
  color: $color-white;
  background-color: #a9d5fe !important;
}

.social-skype:active,
.social-skype.active {
  color: $color-white;
  background-color: #45a3fc !important;
}

.bg-skype {
  background-color: #77bcfd;
}

.social-evernote {
  color: $color-white;
  background-color: #46bf8c !important;
}

.social-evernote:hover,
.social-evernote:focus {
  color: $color-white;
  background-color: #6ccca4 !important;
}

.social-evernote:active,
.social-evernote.active {
  color: $color-white;
  background-color: #369c71 !important;
}

.bg-evernote {
  background-color: #46bf8c;
}

.avatar i {
  border: 2px solid $color-white;
}

.avatar img {
  border: 0 none;
}

.avatar-online i {
  background-color: #1B998B;
}

.avatar-off i {
  background-color: #616161;
}

.avatar-busy i {
  background-color: #FFA630;
}

.avatar-away i {
  background-color: #E3170A;
}

.status {
  border: 2px solid $color-white;
}

.status-online {
  background-color: #1B998B;
}

.status-off {
  background-color: #616161;
}

.status-busy {
  background-color: #FFA630;
}

.status-away {
  background-color: #E3170A;
}

.icon.icon-circle:after {
  background-color: inherit;
}

.icon.icon-border {
  border: solid .08em #e0e0e0;
}

.icon.icon-stack-inverse {
  color: $color-white;
}

.icon-color {
  color: rgba(66, 66, 66, .4);
}

.icon-color:hover,
.icon-color:focus {
  color: rgba(66, 66, 66, .6);
}

.icon-color.active,
.icon-color:active {
  color: #424242;
}

.icon-color-alt {
  color: rgba(66, 66, 66, .6);
}

.icon-color-alt:hover,
.icon-color-alt:focus {
  color: rgba(66, 66, 66, .8);
}

.icon-color-alt.active,
.icon-color-alt:active {
  color: #424242;
}

.hamburger:before,
.hamburger .hamburger-bar,
.hamburger:after {
  background: #757575;
}

.navbar-default .hamburger:before,
.navbar-default .hamburger .hamburger-bar,
.navbar-default .hamburger:after {
  background: #757575;
}

.navbar-inverse .hamburger:before,
.navbar-inverse .hamburger .hamburger-bar,
.navbar-inverse .hamburger:after {
  background: $color-white;
}

.counter > .counter-number,
.counter .counter-number-group {
  color: #424242;
}

.counter-inverse {
  color: $color-white;
}

.counter-inverse > .counter-number,
.counter-inverse .counter-number-group {
  color: $color-white;
}

.counter-inverse .counter-icon {
  color: $color-white;
}

.widget {
  background-color: $color-white;
}

.widget-title {
  color: #424242;
}

.overlay-panel .widget-title {
  color: $color-white;
}

.widget-metas {
  color: #9e9e9e;
}

.widget-metas.type-link > a {
  color: #9e9e9e;
}

.widget-metas.type-link > a:hover {
  color: #bdbdbd;
}

.widget-metas.type-link > a + a:before {
  background-color: #9e9e9e;
}

.overlay-background .widget-time {
  color: $color-white;
}

.widget-actions a {
  color: #9e9e9e;
}

.widget-actions a.active,
.widget-actions a:hover,
.widget-actions a:focus {
  color: #bdbdbd;
}

.widget-actions-sidebar a {
  border-right: 1px solid #e0e0e0;
}

.widget-actions-sidebar a + a {
  border-top: 1px solid #e0e0e0;
}

.widget-watermark.darker {
  color: black;
}

.widget-watermark.lighter {
  color: white;
}

.widget-divider:after {
  background-color: $color-white;
}

.panel-group .panel-title:hover,
.panel-group .panel-title:focus {
  color: #757575;
}

.panel-group .panel-heading + .panel-collapse .panel-body {
  border-top-color: transparent;
}

.panel-group-continuous .panel + .panel {
  border-top: 1px solid #e0e0e0;
}

.panel-group-simple .panel {
  background: transparent;
}

.cover-iframe {
  border: 0 none;
}

/* Sub-object `overlay-panel`
 ========================================================================== */
.overlay-panel {
  color: $color-white;
}

.overlay-panel a:not([class]) {
  color: inherit;
}

.overlay-panel h1,
.overlay-panel h2,
.overlay-panel h3,
.overlay-panel h4,
.overlay-panel h5,
.overlay-panel h6 {
  color: inherit;
}

/* Modifier `overlay-background`
 ========================================================================== */
.overlay-background {
  background: rgba(0, 0, 0, .5);
}

/* Modifier `overlay-shade`
 ========================================================================== */
.overlay-shade {
  background: rgba(0, 0, 0, 0) -webkit-gradient(linear, left top, left bottom, color-stop(50%, rgba(255, 255, 255, 0)), color-stop(90%, rgba(255, 255, 255, .87)), to(#fff)) repeat scroll 0 0;
  background: rgba(0, 0, 0, 0) -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, .87) 90%, $color-white 100%) repeat scroll 0 0;
  background: rgba(0, 0, 0, 0) -o-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, .87) 90%, $color-white 100%) repeat scroll 0 0;
  background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, .87) 90%, $color-white 100%) repeat scroll 0 0;
}

/* Sub-object `overlay-icon`
 ========================================================================== */
.overlay-icon .icon {
  color: $color-white;
}

.comments .comment {
  border: none;
  border-bottom: 1px solid #e0e0e0;
}

.comments .comment .comment:first-child {
  border-top: 1px solid #e0e0e0;
}

.comments .comment .comment:last-child {
  border-bottom: none;
}

.comment-author,
.comment-author:hover,
.comment-author:focus {
  color: #424242;
}

.comment-meta {
  color: #9e9e9e;
}

.chat-box {
  background-color: $color-white;
}

.chat-content {
  color: $color-white;
  background-color: $color-primary;
}

.chat-content:before {
  border: 10px solid transparent;
  border-top-color: $color-primary;
}

.chat-right .chat-content {
  color: #757575;
  background-color: #dfdfdf;
}

.chat-right .chat-content:before {
  border-top-color: #dfdfdf;
}

.step {
  color: #9e9e9e;
  background-color: #eee;
}

.step-number {
  color: $color-white;
  background: #e0e0e0;
}

.step-title {
  color: #616161;
}

.step.current {
  color: $color-white;
  background-color: $color-primary;
}

.step.current .step-title {
  color: $color-white;
}

.step.current .step-number {
  color: $color-primary;
  background-color: $color-white;
}

.step.disabled {
  color: #bdbdbd;
}

.step.disabled .step-title {
  color: #bdbdbd;
}

.step.disabled .step-number {
  background-color: #bdbdbd;
}

.step.error {
  color: $color-white;
  background-color: #E3170A;
}

.step.error .step-title {
  color: $color-white;
}

.step.error .step-number {
  color: #E3170A;
  background-color: $color-white;
}

.step.done {
  color: $color-white;
  background-color: #1B998B;
}

.step.done .step-title {
  color: $color-white;
}

.step.done .step-number {
  color: #1B998B;
  background-color: $color-white;
}

.pearl:before,
.pearl:after {
  background-color: #eee;
}

.pearl-number,
.pearl-icon {
  color: $color-white;
  background: #bdbdbd;
  border: 2px solid #bdbdbd;
}

.pearl-title {
  color: #616161;
}

.pearl.current:before,
.pearl.current:after {
  background-color: $color-primary;
}

.pearl.current .pearl-number,
.pearl.current .pearl-icon {
  color: $color-primary;
  background-color: $color-white;
  border-color: $color-primary;
}

.pearl.disabled:before,
.pearl.disabled:after {
  background-color: #eee;
}

.pearl.disabled .pearl-number,
.pearl.disabled .pearl-icon {
  color: $color-white;
  background-color: #bdbdbd;
  border-color: #bdbdbd;
}

.pearl.error:before {
  background-color: $color-primary;
}

.pearl.error:after {
  background-color: #eee;
}

.pearl.error .pearl-number,
.pearl.error .pearl-icon {
  color: #E3170A;
  background-color: $color-white;
  border-color: #E3170A;
}

.pearl.done:before,
.pearl.done:after {
  background-color: $color-primary;
}

.pearl.done .pearl-number,
.pearl.done .pearl-icon {
  color: $color-white;
  background-color: $color-primary;
  border-color: $color-primary;
}

.timeline {
  background: transparent;
}

.timeline:before {
  background-color: #e0e0e0;
}

.timeline > li.timeline-period {
  background: #f3f4f5;
}

.timeline-dot {
  color: $color-white;
  background-color: $color-primary;
}

.timeline-info {
  background: #e0e0e0;
  border: 1px solid #e0e0e0;
}

.testimonial-content {
  background-color: #eee;
}

.testimonial-content:before {
  background-color: #eee;
}

.testimonial-control a {
  color: #bdbdbd;
}

.testimonial-control a:hover {
  color: $color-primary;
}

.pricing-list [class^="bg-"],
.pricing-list [class*="bg-"],
.pricing-list [class^="bg-"] *,
.pricing-list [class*="bg-"] * {
  color: $color-white;
}

.pricing-list .pricing-header {
  border-bottom: 1px solid #e0e0e0;
}

.pricing-list .pricing-price {
  color: #424242;
}

.pricing-list .pricing-features li {
  border-top: 1px dashed #e0e0e0;
}

.pricing-list .pricing-features li:first-child {
  border-top: none;
}

.pricing-table:before,
.pricing-table:after {
  display: table;
  content: " ";
}

.pricing-table:after {
  clear: both;
}

.pricing-table [class*="pricing-column"] {
  background-color: #eee;
  border: 1px solid #e0e0e0;
  border-right: none;
}

.pricing-table [class*="pricing-column"]:last-child {
  border-right: 1px solid #e0e0e0;
}

.pricing-table [class*="pricing-column"].featured {
  background-color: $color-white;
  border-right: 1px solid #e0e0e0;
}

.pricing-table .pricing-header {
  border-bottom: 1px solid #e0e0e0;
}

.rating .icon {
  color: #bdbdbd;
}

.rating .icon.active {
  color: #fb8c00 !important;
}

.rating .icon.active.hover {
  color: #fb8c00 !important;
}

.rating .icon.hover {
  color: #fb8c00 !important;
}

.ribbon {
  background-color: transparent;
}

.ribbon-inner {
  color: $color-white;
  background-color: #616161;
}

.ribbon-bookmark .ribbon-inner:before {
  border: 15px solid #616161;
  border-right: 10px solid transparent;
}

.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  border-right: 15px solid #616161;
  border-bottom: 10px solid transparent;
}

.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  border-right: 15px solid #616161;
  border-left: 10px solid transparent;
}

.ribbon-bookmark.ribbon-reverse.ribbon-vertical .ribbon-inner:before {
  border-right-color: #616161;
  border-bottom-color: transparent;
  border-left: 15px solid #616161;
}

.ribbon-corner .ribbon-inner {
  background-color: transparent;
}

.ribbon-corner .ribbon-inner:before {
  border: 30px solid transparent;
  border-top-color: #616161;
  border-left-color: #616161;
}

.ribbon-corner.ribbon-reverse .ribbon-inner:before {
  border-right-color: #616161;
  border-left-color: transparent;
}

.ribbon-corner.ribbon-bottom .ribbon-inner:before {
  border-top-color: transparent;
  border-bottom-color: #616161;
}

.ribbon-clip .ribbon-inner:after {
  border: 7px solid transparent;
  border-top-color: #424242;
  border-right-color: #424242;
}

.ribbon-clip.ribbon-reverse .ribbon-inner:after {
  border-right-color: transparent;
  border-left-color: #424242;
}

.ribbon-clip.ribbon-bottom .ribbon-inner:after {
  border-top-color: transparent;
  border-bottom-color: #424242;
}

.ribbon-primary .ribbon-inner {
  background-color: $color-primary;
}

.ribbon-primary.ribbon-bookmark .ribbon-inner:before {
  border-color: $color-primary;
  border-right-color: transparent;
}

.ribbon-primary.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  border-right-color: $color-primary;
  border-left-color: transparent;
}

.ribbon-primary.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  border-right-color: $color-primary;
  border-bottom-color: transparent;
}

.ribbon-primary.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
  border-right-color: $color-primary;
  border-bottom-color: transparent;
  border-left-color: $color-primary;
}

.ribbon-primary.ribbon-corner .ribbon-inner {
  background-color: transparent;
}

.ribbon-primary.ribbon-corner .ribbon-inner:before {
  border-top-color: $color-primary;
  border-left-color: $color-primary;
}

.ribbon-primary.ribbon-corner.ribbon-reverse .ribbon-inner:before {
  border-right-color: $color-primary;
  border-left-color: transparent;
}

.ribbon-primary.ribbon-corner.ribbon-bottom .ribbon-inner:before {
  border-top-color: transparent;
  border-bottom-color: $color-primary;
}

.ribbon-primary .ribbon-inner:after {
  border-top-color: #e14365;
  border-right-color: #e14365;
}

.ribbon-primary.ribbon-reverse .ribbon-inner:after {
  border-right-color: transparent;
  border-left-color: #e14365;
}

.ribbon-primary.ribbon-bottom .ribbon-inner:after {
  border-top-color: transparent;
  border-bottom-color: #e14365;
}

.ribbon-success .ribbon-inner {
  background-color: #1B998B;
}

.ribbon-success.ribbon-bookmark .ribbon-inner:before {
  border-color: #1B998B;
  border-right-color: transparent;
}

.ribbon-success.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  border-right-color: #1B998B;
  border-left-color: transparent;
}

.ribbon-success.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  border-right-color: #1B998B;
  border-bottom-color: transparent;
}

.ribbon-success.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
  border-right-color: #1B998B;
  border-bottom-color: transparent;
  border-left-color: #1B998B;
}

.ribbon-success.ribbon-corner .ribbon-inner {
  background-color: transparent;
}

.ribbon-success.ribbon-corner .ribbon-inner:before {
  border-top-color: #1B998B;
  border-left-color: #1B998B;
}

.ribbon-success.ribbon-corner.ribbon-reverse .ribbon-inner:before {
  border-right-color: #1B998B;
  border-left-color: transparent;
}

.ribbon-success.ribbon-corner.ribbon-bottom .ribbon-inner:before {
  border-top-color: transparent;
  border-bottom-color: #1B998B;
}

.ribbon-success .ribbon-inner:after {
  border-top-color: #43a047;
  border-right-color: #43a047;
}

.ribbon-success.ribbon-reverse .ribbon-inner:after {
  border-right-color: transparent;
  border-left-color: #43a047;
}

.ribbon-success.ribbon-bottom .ribbon-inner:after {
  border-top-color: transparent;
  border-bottom-color: #43a047;
}

.ribbon-info .ribbon-inner {
  background-color: #00bcd4;
}

.ribbon-info.ribbon-bookmark .ribbon-inner:before {
  border-color: #00bcd4;
  border-right-color: transparent;
}

.ribbon-info.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  border-right-color: #00bcd4;
  border-left-color: transparent;
}

.ribbon-info.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  border-right-color: #00bcd4;
  border-bottom-color: transparent;
}

.ribbon-info.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
  border-right-color: #00bcd4;
  border-bottom-color: transparent;
  border-left-color: #00bcd4;
}

.ribbon-info.ribbon-corner .ribbon-inner {
  background-color: transparent;
}

.ribbon-info.ribbon-corner .ribbon-inner:before {
  border-top-color: #00bcd4;
  border-left-color: #00bcd4;
}

.ribbon-info.ribbon-corner.ribbon-reverse .ribbon-inner:before {
  border-right-color: #00bcd4;
  border-left-color: transparent;
}

.ribbon-info.ribbon-corner.ribbon-bottom .ribbon-inner:before {
  border-top-color: transparent;
  border-bottom-color: #00bcd4;
}

.ribbon-info .ribbon-inner:after {
  border-top-color: #00acc1;
  border-right-color: #00acc1;
}

.ribbon-info.ribbon-reverse .ribbon-inner:after {
  border-right-color: transparent;
  border-left-color: #00acc1;
}

.ribbon-info.ribbon-bottom .ribbon-inner:after {
  border-top-color: transparent;
  border-bottom-color: #00acc1;
}

.ribbon-warning .ribbon-inner {
  background-color: #FFA630;
}

.ribbon-warning.ribbon-bookmark .ribbon-inner:before {
  border-color: #FFA630;
  border-right-color: transparent;
}

.ribbon-warning.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  border-right-color: #FFA630;
  border-left-color: transparent;
}

.ribbon-warning.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  border-right-color: #FFA630;
  border-bottom-color: transparent;
}

.ribbon-warning.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
  border-right-color: #FFA630;
  border-bottom-color: transparent;
  border-left-color: #FFA630;
}

.ribbon-warning.ribbon-corner .ribbon-inner {
  background-color: transparent;
}

.ribbon-warning.ribbon-corner .ribbon-inner:before {
  border-top-color: #FFA630;
  border-left-color: #FFA630;
}

.ribbon-warning.ribbon-corner.ribbon-reverse .ribbon-inner:before {
  border-right-color: #FFA630;
  border-left-color: transparent;
}

.ribbon-warning.ribbon-corner.ribbon-bottom .ribbon-inner:before {
  border-top-color: transparent;
  border-bottom-color: #FFA630;
}

.ribbon-warning .ribbon-inner:after {
  border-top-color: #fb8c00;
  border-right-color: #fb8c00;
}

.ribbon-warning.ribbon-reverse .ribbon-inner:after {
  border-right-color: transparent;
  border-left-color: #fb8c00;
}

.ribbon-warning.ribbon-bottom .ribbon-inner:after {
  border-top-color: transparent;
  border-bottom-color: #fb8c00;
}

.ribbon-danger .ribbon-inner {
  background-color: #E3170A;
}

.ribbon-danger.ribbon-bookmark .ribbon-inner:before {
  border-color: #E3170A;
  border-right-color: transparent;
}

.ribbon-danger.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  border-right-color: #E3170A;
  border-left-color: transparent;
}

.ribbon-danger.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  border-right-color: #E3170A;
  border-bottom-color: transparent;
}

.ribbon-danger.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
  border-right-color: #E3170A;
  border-bottom-color: transparent;
  border-left-color: #E3170A;
}

.ribbon-danger.ribbon-corner .ribbon-inner {
  background-color: transparent;
}

.ribbon-danger.ribbon-corner .ribbon-inner:before {
  border-top-color: #E3170A;
  border-left-color: #E3170A;
}

.ribbon-danger.ribbon-corner.ribbon-reverse .ribbon-inner:before {
  border-right-color: #E3170A;
  border-left-color: transparent;
}

.ribbon-danger.ribbon-corner.ribbon-bottom .ribbon-inner:before {
  border-top-color: transparent;
  border-bottom-color: #E3170A;
}

.ribbon-danger .ribbon-inner:after {
  border-top-color: #e53935;
  border-right-color: #e53935;
}

.ribbon-danger.ribbon-reverse .ribbon-inner:after {
  border-right-color: transparent;
  border-left-color: #e53935;
}

.ribbon-danger.ribbon-bottom .ribbon-inner:after {
  border-top-color: transparent;
  border-bottom-color: #e53935;
}

.dotnav > li > a {
  background-color: rgba(255, 255, 255, .3);
}

.dotnav > li.active > a,
.dotnav > li:hover > a,
.dotnav > li:focus > a {
  background-color: $color-white;
}

.dotnav-stroke > li > a {
  border: 2px solid transparent;
}

.dotnav-stroke > li.active > a {
  background-color: transparent;
  border-color: $color-white;
}

.dotnav-fillin > li > a {
  background-color: transparent;
}

.dotnav-fillin > li:hover > a,
.dotnav-fillin > li:focus > a {
  background-color: transparent;
}

.dotnav-dotstroke > li.active > a {
  background-color: rgba(255, 255, 255, .3);
}

.dotnav-fall > li:after {
  background-color: rgba(0, 0, 0, .3);
}

.color-selector > li {
  background-color: $color-primary;
}

.color-selector > li:before {
  background: inherit;
  border: 1px solid rgba(0, 0, 0, .1);
}

.color-selector > li input[type="radio"]:checked + label:after {
  color: $color-white;
}

.color-selector > li.color-selector-disabled {
  background-color: #bdbdbd !important;
}

.example-well {
  background-color: #eceff1;
}

.example-grid .example-col,
.example-blocks .example-col {
  background-color: #eceff1;
}

.example-grid .example-col .example-col {
  background-color: #dde3e6;
}

.example-box {
  border: 1px solid #e0e0e0;
}

.example-box:after {
  color: #959595;
}

.example-typography .heading-note,
.example-typography .text-note {
  color: #aab2bd;
}
