$color-gray: rgb(98, 98, 98);
$color-silver: rgb(161, 161, 161);
$color-mercury: rgb(225, 225, 225);
$color-snow: rgb(250, 250, 250);

$color-white: #fff;
$color-f9-silver: #f9f9f9;

$color-primary: #fe4365;
$color-primary-3f: #d6253f;
$color-primary-dark: #e14365;
$color-base: rgb(34, 34, 34);
$color-base-text: #424242;
$color-base-small: rgb(98, 98, 98);

$color-success: rgb(27, 153, 139);
$color-info: rgb(62, 120, 178);
$color-warning: rgb(255, 166, 48);
$color-danger: rgb(227, 23, 10);

$color-87-black: rgba(0, 0, 0, .87);
$color-54-black: rgba(0, 0, 0, .54);
