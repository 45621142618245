@import "colors";

.tariff-banner {
  border: 2px solid #00ace6;
  padding: 8px 0 0 0;
  border-radius: 16px;
  margin-bottom: 10px;
  margin-top: -5px;

  &__header {
    text-align: center;
    margin-bottom: 5px;
    font-weight: bold;
  }

  &__content {
    text-align: justify;
    font-weight: bold;
    padding: 0 20px;
  }
}